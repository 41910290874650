import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import moduleAuth from './auth/moduleAuth.js'
import moduleProducts from './products/moduleProducts'
import moduleSuppliers from './suppliers/moduleSuppliers'
import moduleInventory from './inventory/moduleInventory'
import moduleCustomer from './customer/moduleCustomer'
import modulePad from './pad/modulePad'
import moduleUser from './user/moduleUser'
import moduleCashCards from './cashcards/moduleCashCards'
import moduleDynamicProducts from './dynamic_products/moduleDynamicProducts'
import moduleDirectRecharge from './directrecharge/moduleDirectRecharge'
import moduleGiftCards from './giftcards/moduleGiftCards'
import moduleAdminGiftCards from './admin_giftcards/moduleAdminGiftCards'
import moduleInternationalTopUp from './internationaltopup/moduleInternationalTopup'
import moduleReports from './reports/moduleReports'
import moduleFlixBus from './flixbus/moduleFlixBus'
import moduleDevices from './devices/moduleDevices'
import moduleAutoCredit from './auto_credit/moduleAutoCredit'
import moduleLoginRequests from './loginrequests/moduleLoginRequests'
import moduleReclamation from './reclaim/moduleReclamation'
import moduleInvoices from './invoices/moduleInvoices'
import moduleProductDiscountList from './product_discount_list/moduleProcutDiscountList'
import moduleInventorySearch from './reports/moduleInventorySearch'
import moduleCallingCards from './calling_cards/moduleCallingCards'
import moduleSellerCallingCards from "@/store/calling_cards/moduleSellerCallingCards";
import moduleCustomerRegistration from "@/store/customer_registration/moduleCustomerRegistration";
import moduleEPayTerminals from "@/store/customer/moduleEPayTerminals";
import moduleReports2 from "@/store/reports2/moduleReports2";
import moduleSalesSummaryReport from "@/store/salesSummary/moduleSalesSummaryReport";
import bankingModule from "@/store/banking/bankingModule";
import moduleHardware from "@/store/hardware/moduleHardware";
import moduleHardwareSeller from "@/store/hardware/moduleHardwareSeller";

Vue.use(Vuex)


export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    products: moduleProducts,
    suppliers: moduleSuppliers,
    inventory: moduleInventory,
    customer: moduleCustomer,
    pad: modulePad,
    user: moduleUser,
    cashCards: moduleCashCards,
    giftCards: moduleGiftCards,
    adminGiftCards: moduleAdminGiftCards,
    dynamicProducts: moduleDynamicProducts,
    directRecharge: moduleDirectRecharge,
    internationalTopUp: moduleInternationalTopUp,
    reports: moduleReports,
    flixBus: moduleFlixBus,
    devices: moduleDevices,
    autoCredit: moduleAutoCredit,
    loginRequests: moduleLoginRequests,
    reclamation: moduleReclamation,
    invoice: moduleInvoices,
    productDiscountList: moduleProductDiscountList,
    inventorySearch: moduleInventorySearch,
    callingCards: moduleCallingCards,
    sellerCallingCards: moduleSellerCallingCards,
    customerRegistration: moduleCustomerRegistration,
    ePayTerminals: moduleEPayTerminals,
    salesReport2: moduleReports2,
    salesSummaryReport: moduleSalesSummaryReport,
    banking: bankingModule,
    hardware: moduleHardware,
    hardwareSeller: moduleHardwareSeller,
  },
  strict: process.env.NODE_ENV !== 'production',
})
