import axios from '@/axios'
import Vue from 'vue';

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    customers: [],
    salesData: [],
    customer: null,
    customersRaw: null,
    vPage: 0,
    vLimit: 0,
    vTotalCount: 0,
    vTotalPages: 0,
    customerFilters: [],
    salesFilters: {}
  },
  mutations: {
    SET_REPORT_CUSTOMERS(state, customers) {
      state.customers = customers
    },
    SET_REPORT_CUSTOMERS_RAW(state, raw) {
      state.customersRaw = raw
      state.vTotalCount = Number(raw.filters.total_count)
      state.vPage = Number(raw.filters.page)
      state.vTotalPages = Math.ceil(Number(raw.filters.total_count) / Number(raw.filters.limit))
      state.salesFilters = raw.type_of_filters
    },
    RESET_CUSTOMER_SALES_DATA(state) {
      let dCustomers = state.customers;
      state.customers = [];
      for(let i = 0; i < dCustomers.length; i++) {
        dCustomers[i] = {
          ...dCustomers[i],
          total_sale_amount: null,
          total_buying_amount: null,
          total_sale_amount_2: null,
          total_buying_amount_2: null,
          difference: null,
          difference_percentage: null
        }
      }
      Vue.set(state, 'customers', [...dCustomers]);
      console.log(state.customers);
    },
    UPDATE_CUSTOMER_SALES_DATA(state, data) {
      let dCustomers = state.customers;
      state.customers = [];
      for(let i = 0; i < dCustomers.length; i++) {
          if(dCustomers[i].customer_id == data.customer_id) {
            dCustomers[i] = {
              ...dCustomers[i],
              total_sale_amount: data.total_sale_amount,
              total_buying_amount: data.total_buying_amount
            }
            console.log(dCustomers[i])
          }
      }
      Vue.set(state, 'customers', [...dCustomers]);
    },
    UPDATE_CUSTOMER_SALES_DATA_2(state, data) {
      let dCustomers = state.customers;
      state.customers = [];
      for(let i = 0; i < dCustomers.length; i++) {
        // for(let j = 0; j < data.length; j++) {
          if(dCustomers[i].customer_id == data.customer_id) {
            let difference = parseFloat(dCustomers[i].total_sale_amount) - parseFloat(data.total_sale_amount);
            let sum = parseFloat(dCustomers[i].total_sale_amount) + parseFloat(data.total_sale_amount);
            let diffPercentage = 0;
            if(difference != 0) {
              diffPercentage = (100 * (difference / sum)).toFixed(2);
            }
            dCustomers[i] = {
              ...dCustomers[i],
              total_sale_amount_2: data.total_sale_amount,
              total_buying_amount_2: data.total_buying_amount,
              difference: difference,
              difference_percentage: diffPercentage || 0
            }
            console.log(dCustomers[i])
          }
      }
      Vue.set(state, 'customers', [...dCustomers]);
    }
  },
  actions: {
    resetCustomersSalesData({commit}) {
      commit('RESET_CUSTOMER_SALES_DATA')
    },
    fetchReportCustomers({ commit, state }, { filters, page = '0', search = '', limit = '10' }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()

        Object.keys(filters).forEach((key) => {
          data.append(key, filters[key])
        })
        data.append('page', page)
        data.append('limit', limit)
        if (search != null && search != '') data.append('search_text', search.toLowerCase())

        axios.post('/view_customers_list_with_less_data/', data)
          .then((response) => {
            commit('SET_REPORT_CUSTOMERS', response.data.content)
            commit('SET_REPORT_CUSTOMERS_RAW', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    getSalesReport2DataWithCustomers({ commit, state }, { filters, cancelToken, type }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()

        Object.keys(filters).forEach((key) => {
          data.append(key, filters[key])
        })

        axios.post('/get_sales_report_2_with_customer_id/', data, { cancelToken: cancelToken})
          .then((response) => {
            if(type == 2) {

              commit('UPDATE_CUSTOMER_SALES_DATA_2', response.data)
            } else {

              commit('UPDATE_CUSTOMER_SALES_DATA', response.data)
            }
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
  },
  getters: {
    customers(state) {
      return state.customers;
    },
  }
}

