import state from './moduleAdminGiftCardsState'
import mutations from './moduleAdminGiftCardsMutations'
import actions from './moduleAdminGiftCardsActions'
import getters from './moduleAdminGiftCardsGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

