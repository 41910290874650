/*= ========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

export default {
  users: [],
  vPage: 0,
  itemCount: 20,
  totalCount: 0,
  vTotalPages: 0,
  usersRaw: null,
}

// 0 - employee
// 1 - branch
// 2 - organisation admin
// 3 - we
