import axios from '@/axios.js'

export default {
  fetchGiftCards({ commit, state }, { payload, page }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      if (payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }

      data.append('page', page)
      data.append('limit', '20')
      axios.post('/view_gift_cards/', data)
        .then((response) => {
          try {
            commit('SET_GIFT_CARDS', response.data.final_list)
            commit('SET_GIFT_CARDS_RAW', response.data)
          } catch (e) {

          }
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchGiftCardDetails({ commit, state }, { payload, page }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      if (payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }

      data.append('page', page)
      data.append('limit', '20')
      axios.post('/gift_card_details/', data)
        .then((response) => {
          try {
            commit('SET_GIFT_CARD_DETAILS', response.data.gift_cards)
            commit('SET_GIFT_CARD_DETAILS_RAW', response.data)
          } catch (e) {

          }
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  searchGiftCardBatch({ commit, state }, {batch}) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append("batch_no", batch)

      axios.post('/gift_card_batch_search/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addGiftCard({ commit, state }, { payload }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      if (payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }

      axios.post('/add_gift_cards/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateGiftCard({ commit, state }, { payload }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      if (payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }

      axios.post('/update_gift_cards/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
