
import Vue from 'vue'
import Vuesax from 'vuesax'

export const colors = {
  primary: '#00a1f1',
  secondary: '#00a1f1',
  // primary: '#00a1f1',
  success: '#7cbb00',
  danger: '#f65314',
  warning: '#ffbb00',
  dark: '#1E1E1E',
  white: '#FFFFFF',
  black: '#000000',
  purple: '#9b59b6'
}

// CONFIGS
const themeConfig = {
  disableCustomizer: true, // options[Boolean] : true, false(default)
  disableThemeTour: true, // options[Boolean] : true, false(default)
  footerType: 'sticky', // options[String]  : static(default) / sticky / hidden
  hideScrollToTop: false, // options[Boolean] : true, false(default)
  mainLayoutType: 'horizontal', // options[String]  : vertical(default) / horizontal
  navbarColor: '#fff', // options[String]  : HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
  navbarType: 'sticky', // options[String]  : floating(default) / static / sticky / hidden
  routerTransition: 'zoom-fade', // options[String]  : zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
  rtl: false, // options[Boolean] : true, false(default)
  sidebarCollapsed: false, // options[Boolean] : true, false(default)
  theme: 'light', // options[String]  : "light"(default), "dark", "semi-dark"

  // Not required yet - WIP
  userInfoLocalStorageKey: 'userInfo',

  // NOTE: themeTour will be disabled in screens < 1200. Please refer docs for more info.
}
Vue.use(Vuesax, { theme: { colors }, rtl: themeConfig.rtl })

export default themeConfig
