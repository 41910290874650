import axios from '@/axios'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    registrations: [],
    registrationsRaw: {},
  },
  mutations: {
    SET_REGISTRATIONS(state, data) {
      state.registrations = data
    },
    SET_REGISTRATIONS_RAW(state, data) {
      state.registrationsRaw = data
    },
  },
  actions: {
    fetchRegistrations({state, commit}, {payload}) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/view_registration/', data)
          .then((response) => {
            commit('SET_REGISTRATIONS', response.data.registrations)
            commit('SET_REGISTRATIONS_RAW', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateRegistration({state, commit}, {payload}) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/update_registration/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    sendRegisterOTP({state, commit}, {payload}) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/set_password_step_one/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    verifyRegisterOTP({state, commit}, {payload}) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/set_password_step_two/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    setRegisterPassword({state, commit}, {payload}) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/set_password_step_three/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  }
}

