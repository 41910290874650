import state from './moduleInventoryState'
import mutations from './moduleInventoryMutations'
import actions from './moduleInventoryActions'
import getters from './moduleInventoryGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

