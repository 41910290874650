export default {
  "en": {
    "Products": "Products",
    "Product": "Product",
    "ProductValue": "Product Value",
    "Quantity": "Quantity",
    "Denomination": "Denomination",
    "ViewProducts": "View Products",
    "ViewProduct": "View Product",
    "AddProducts": "Add Products",
    "AddProduct": "Add Product",
    "UpdateProduct": "Update Product",
    "AddNewProduct": "Add New Product",
    "Instructions": "Instructions",
    "CustomerCareNumber": "Customer care number",
    "ProductType": "Product Type",
    "TotalDenominations": "Denominations",
    "Details": "Details",
    "PinLength": "Pin Length",
    "Pin": "Pin",
    "SoldBy": "Sold By",
    "SoldById": "Sold by ID",
    "SoldTime": "Sold Time",
    "ProductName": "Product Name",
    "SaveAddDenominations": "Save and Add Denominations",
    "AddProductImage": "Add Product Image",
    "ProductImage": "Product Image",
    "AddDenomination": "Add Denomination",
    "Done": "Done",
    "Add": "Add",
    "Delete": "Delete",
    "Update": "Update",
    "Cancel": "Cancel",
    "ClearCart": "Clear Cart",
    "Amount": "Amount",
    "OldBalance": "Old Balance",
    "NewBalance": "New Balance",
    "Paid": "Paid",
    "SellerProfit": "Seller Profit",
    "ProductID": "Product ID",
    "ID": "ID",
    "Image": "Image",
    "Company": "Company",
    "NoData": "Data not available",
    "NoServicesAvailable": "No Services Available",
    "Contact": "Contact",
    "AccountStatus": "Account Status",
    "ErrorData": "Error getting data",
    "Edit": "Edit",
    "Activate": "Activate",
    "Deactivate": "Deactivate",
    "Suppliers": "Suppliers",
    "Supplier": "Supplier",
    "SupplierCreateTag": "Create Supplier to purchase Products",
    "AddNewSupplier": "Add a new supplier",
    "ViewSuppliers": "View Suppliers",
    "ViewSupplier": "View Supplier",
    "AddSupplier": "Add Supplier",
    "SupplierCompany": "Supplier Company",
    "SupplierName": "Supplier Name",
    "FullName": "Full Name",
    "FullAddress": "Full Address",
    "EmailId": "Email ID",
    "Mobile": "Mobile",
    "StNr": "Tax Number",
    "Telephone": "Telephone",
    "UstIdnr": "Vendor Tax Number",
    "City": "City",
    "Country": "Country",
    "PostalCode": "Postal Code",
    "Remarks": "Remarks",
    "SaveAndPublish": "Save And Publish",
    "TotalPins": "Total Pins",
    "CreatedDate": "Created Date",
    "BuyingDiscount": "Buying discount",
    "PinSeq": "Pin Sequence",
    "BatchSeq": "Batch Sequence",
    "Delimiter": "Delimiter",
    "Discount": "Discount",
    "Inventory": "Inventory",
    "InventoryDetails": "Inventory Details",
    "AddInventory": "Add Inventory",
    "ViewInventory": "View Inventory",
    "UploadCSVFileOnly": "Only upload file with extension .csv",
    "AddGiftCardBatch": "Add Gift card batch",
    "DivisionDiscount": "Division Discount",
    "AssignmentsAndDiscount": "Assignments and discount",
    "AddCustomer": "Add Customer",
    "EditCustomer": "Edit Customer",
    "AddNewCustomer": "Add New Customer",
    "Customer": "Customer",
    "BalanceTopUp": "Balance TopUp",
    "BalanceTrigger": "Balance Trigger",
    "CreditLimit": "Credit Limit",
    "CustomerName": "Customer Name",
    "CreditBalance": "Credit / Balance",
    "CustomerID": "Customer ID",
    "OrganisationType": "Organisation Type",
    "CustomerDetails": "Customer Details",
    "Customers": "Customers",
    "ViewCustomer": "View Customer",
    "ViewCustomers": "View Customers",
    "FirstName": "First Name",
    "LastName": "Last Name",
    "HouseNo": "House No",
    "Address": "Address",
    "Landline": "Landline",
    "Username": "Username",
    "Template": "Template",
    "UsernameNotAvailable": "Username not Available",
    "Password": "Password",
    "ConfirmPassword": "Confirm Password",
    "SubDomain": "Sub Domain",
    "CustomerType": "Customer Type",
    "Name": "Name",
    "Reference": "Reference",
    "ActivatedOn": "Activated On",
    "Credit": "Credit",
    "NewCredit": "New Credit",
    "OldCredit": "Old Credit",
    "TopUp": "Top up",
    "Balance": "Balance",
    "SoftPins": "SoftPins",
    "EPay": "EPay",
    "Sales": "Sales",
    "SalesCount": "Sales Count",
    "Postal": "Postal",
    "DiscountSlab": "Discount Slab",
    "DiscountSlabError": "Please Select Discount Slab",
    "LastPurchase": "Last purchase",
    "PurchasedDate": "Purchased Date",
    "BeforeBalance": "Before Balance",
    "Value": "Value",
    "Sold": "Sold",
    "UnSold": "UnSold",
    "DateTime": "Date Time",
    "AfterBalance": "After Balance",
    "BeforeCredit": "Before Credit",
    "AfterCredit": "After Credit",
    "LastPaidAmount": "Last Paid Amount",
    "Filter": "Filter",
    "ClearAllFilters": "Clear All Filters",
    "PAD": "Product Assignment Discounts",
    "AddCredit": "Add Credit",
    "AutoCredit": "Auto Credit",
    "CreditBalanceTransaction": "Credit / Balance Transaction",
    "Date": "Date",
    "GrandTotal": "Grand Total",
    "Cash": "Cash",
    "PaymentType": "Payment Type",
    "TransactionType": "Transaction Type",
    "TransactionMode": "Transaction Mode",
    "PaymentMode": "Payment Mode",
    "StandingCredit": "Standing Credit",
    "StandingBalance": "Standing Balance",
    "ForecastAmountneed": "Forecast Amount needed",
    "CreditRemark": "Credit Remark",
    "Users": "Users",
    "ViewUsers": "View Users",
    "User": "User",
    "UserName": "Username",
    "UserId": "User Id",
    "AddUser": "Add User",
    "AddNewUser": "Add New User",
    "JobRole": "Job Role",
    "Privileges": "Privileges",
    "SaveUser": "Save User",
    "UpdateUser": "Update User",
    "AddNewStaff": "Add a new staff",
    "AddNewStaffTag": "Create new staff with limited access to modules",
    "CashCards": "Cash Cards",
    "TotalBill": "Total Bill",
    "Profit": "Profit",
    "Print": "Print",
    "Close": "Close",
    "PlanId": "Plan Id",
    "PlanName": "Plan Name",
    "DiscountSlabs": "Discount Slabs",
    "ProductsDiscount": "Products Discount",
    "ErrorDiscountSlabs": "Error fetching discount slabs",
    "DiscountPlanName": "Discount Plan name",
    "AddDiscountSlab": "Add Discount Slab",
    "NoDiscountSlabs": "No Discount slabs",
    "ViewDiscountSlabs": "View Discount Slab",
    "DenominationAmount": "Denomination Amount",
    "BatchNumber": "Batch Number",
    "DiscountAmountError": "Discount value should be non negative",
    "AmountError": "Please fill amount",
    "DenominationAmountError": "Please fill denomination amount / amount should be greater than 0",
    "DynamicProducts": "Dynamic Products",
    "DynamicProductsDiscounts": "Dynamic Products Discounts",
    "SoftApiCard": "SoftApi Card",
    "EPayApiId": "EPay Api Id",
    "EPayApiEAN": "EPay EAN",
    "EPayApiName": "EPay Api Name",
    "ContentCardPinAPIID": "CC Card PIN API ID",
    "ContentCardPinAPIDiscount": "CC Card PIN API Discount",
    "Submit": "Submit",
    "Priority": "Priority",
    "GiftCards": "Gift Cards",
    "EnterCardNoLabel": "Please Enter Card No",
    "EnterEANLabel": "Please Enter EAN",
    "EnterBachNo": "Please Enter Batch No",
    "DirectRecharge": "Direct Recharge",
    "DirectRechargeHistory": "Direct Recharge History",
    "EnterMobileNo": "Please Enter Mobile number",
    "EnterPanNumber": "Please Enter Pan Number",
    "TransactionID": "Transaction ID",
    "Provider": "Provider",
    "Status": "Status",
    "TransactionSuccessful": "Transaction Successful",
    "TransactionFailed": "Transaction Failed",
    "TransactionPending": "Transaction Pending",
    "Buy": "Buy",
    "Recharge": "Recharge",
    "InternationalTopUp": "International TopUp",
    "GetProviders": "Get Providers",
    "DirectRechargeDiscount": "Direct Recharge Discount",
    "InternationalTopUpDiscount": "International TopUp Discount",
    "FlixBusDiscount": "FlixBus Discount",
    "DynamicDiscounts": "Dynamic Discounts",
    "Save": "Save",
    "RechargeSuccessful": "Recharge Successful",
    "SalesReports": "Sales Reports",
    "PaymentLedger": "Payment Ledger",
    "RechargeSummaryReport": "Recharge Summary Report",
    "DynamicProductsLog": "Dynamic Products Log",
    "Download": "Download",
    "SalesReport": "Sales Report",
    "StockReport": "Stock Report",
    "StockReportDetails": "Stock Report Details",
    "InventoryLogReport": "Inventory Log Report",
    "BatchSummaryReport": "Batch Summary Report",
    "Reports": "Reports",
    "Organisation": "Organisation",
    "CustomerId": "Customer Id",
    "InventoryType": "Inventory Type",
    "DynamicType": "Dynamic Type",
    "ConfigAmount": "Config Amount",
    "StockCount": "Stock Count",
    "AvelStock": "Avel Stock",
    "SoldCount": "Sold Count",
    "UnSoldCount": "UnSold Count",
    "UnPublishedCount": "UnPublished Count",
    "SoldPercentage": "Sold Percentage",
    "UnSoldPercentage": "Unsold Percentage",
    "UnPublishedPercentage": "Unpublished Percentage",
    "PinsCount": "Pins Count",
    "SoldPinsCount": "Sold Pins Count",
    "UnSoldPinsCount": "Unsold Pins Count",
    "UnPublishedPinsCount": "Unpublished Pins Count",
    "Batch": "Batch",
    "LotNo": "Lot No",
    "SoldPinsPercentage": "Sold Pins Percentage",
    "UnSoldPinsPercentage": "Unsold Pins Percentage",
    "SoldUnSoldPinsPercentage": "Sold/Unsold Pins Percentage",
    "UnPublishedPinsPercentage": "Unpublished Pins Percentage",
    "Actions": "Actions",
    "Invoices": "Invoices",
    "SNo": "S No",
    "FlixBus": "FlixBus",
    "OneWay": "One way",
    "RoundTrip": "Round trip",
    "Adult": "Adult",
    "For15Years": "for 15 years",
    "Children": "Children",
    "ZeroTo14Years": "0 to 14 years",
    "Bikes": "Bikes",
    "BikeHelp": "E-bikes not allowed.",
    "LoginRequests": "Login requests",
    "AuthStatus": "Auth Status",
    "Approve": "Approve",
    "CallAdmin": "Call Admin",
    "Terminal": "Terminal",
    "ActivateTerminal": "Activate Terminal",
    "SendTerminalDetails": "Send Terminal Details",
    "Domain": "Domain",
    "SearchCustomer": "Search Customer",
    "EditSupplier": "Edit supplier",
    "SupplierEditTag": "Edit Supplier to purchase Products",
    "PinNumber": "Pin Number",
    "Hotline": "Hotline",
    "DateAndTime": "Date and time",
    "CellCredit": "Cell Credit",
    "CellCurrency": "Cell Currency",
    "Serial": "Serial",
    "CardActivatedSuccessfully": "Card was activated successfully",
    "SellingPrice": "Selling Price",
    "BookingNumber": "Booking number",
    "TicketPrice": "Ticket price",
    "Departure": "Departure",
    "Arrival": "Arrival",
    "Adults": "Adults",
    "Bicycle": "Bicycle",
    "PassengerInformation": "Passenger information",
    "Description": "Description",
    "LineDirection": "Line Direction",
    "InfoBlocks": "Info Blocks",
    "Devices": "Devices",
    "SNO": "S.No",
    "DeviceTag": "Device Tag",
    "DeviceSerialNo": "Serial No",
    "DeviceStatus": "Status",
    "Assigned": "Assigned",
    "AddDevice": "Add Device",
    "UpdateDevice": "Update Device",
    "DeviceLastUpdated": "Last Updated",
    "Staff": "Staff",
    "Invoice": "Invoice",
    "ChangePassword": "Change Password",
    "Flag": "Flag",
    "OldCreditLimit": "Old Credit Limit",
    "OldBalanceTrigger": "Old Balance Trigger",
    "OldBalanceTopUp": "Old Balance TopUp",
    "AlternativeMobiles": "Alternative Mobiles",
    "AlternativeMobile1": "Alternative Mobile 1",
    "AlternativeMobile2": "Alternative Mobile 2",
    "TotalCredit": "Total Credit",
    "TotalCash": "Total Cash",
    "TotalSales": "Total Sales",
    "TotalBalance": "Total Balance",
    "BuyingPrice": "Buying Price",
    "MobileNo": "Mobile No",
    "Hello": "Hello",
    "BackToHome": "Back to Home",
    "WelcomeBackLoginText": "Welcome back",
    "Login": "Login",
    "OTP": "OTP",
    "DidNotGetOTP": "Didn’t get a OTP?",
    "ClickForAdminApproval": "Click here for Admin approval.",
    "Verify": "Verify",
    "ReferenceCode": "Reference Code",
    "CheckApprovalStatus": "Check approval status",
    "Search": "Search",
    "FromCity": "From city",
    "ToCity": "To city",
    "Return": "Return",
    "Remove": "Remove",
    "Seat": "Seat",
    "Reserve": "Reserve",
    "PassengerDetails": "Passenger Details",
    "DOB": "Date of Birth",
    "YourOrder": "Your Order",
    "Total": "Total",
    "inclVAT": "(incl. VAT)",
    "EnterVoucher": "Enter voucher",
    "Voucher": "Voucher",
    "ProceedToPayment": "Proceed to payment",
    "SelectQuantity": "Select Quantity",
    "SelectDenomination": "Select Denomination",
    "Region": "Region",
    "AmountReceive": "Amount Receive",
    "Trip": "Trip",
    "ContactDetails": "Contact Details",
    "FromDate": "From Date",
    "ToDate": "To Date",
    "SelectUser": "Select User",
    "TotalSaleAmount": "Total Sale Amount",
    "TotalSaleProfit": "Total Sale Profit",
    "TotalSalePins": "Total Sale Pins",
    "Transactions": "Transactions",
    "Action": "Action",
    "Notes": "Notes",
    "YES": "YES",
    "NO": "NO",
    "CreditHistory": "Credit History",
    "CreditLedger": "Credit Ledger",
    "Referer": "Referer",
    "BalanceType": "Balance Type",
    "EnableWebSupport": "Enable Web Support",
    "OK": "OK",
    "AddedCustomerSuccessfully": "Added Customer successfully",
    "Previous": "Previous",
    "Next": "Next",
    "SelectCustomer": "Select Customer",
    "Preview": "Preview",
    "All": "All",
    "Changes": "Changes",
    "LastActive": "Last Active",
    "UnAssignedDevices": "UnAssigned Devices",
    "Assign": "Assign",
    "UnAssign": "UnAssign",
    "AssignedDevices": "Assigned Devices",
    "Active": "Active",
    "SelectDiscountSlab": "Select Discount Slab",
    "UpdateDiscounts": "Update Discounts",
    "SearchProduct": "Search Product",
    "AddBatch": "Add Batch",
    "Invalid": "Invalid",
    "pins": "pins",
    "Valid": "Valid",
    "NoPinsToImported": "No pins to be imported",
    "LoadMore": "Load More",
    "SoldPins": "Sold Pins",
    "Published": "Published",
    "Unpublished": "Unpublished",
    "CurrentBalance": "Current Balance",
    "Credits": "Credits",
    "OpeningBalance": "Opening Balance",
    "ClosingBalance": "Closing Balance",
    "ProductConfig": "Product Config",
    "UppercaseLetter": "Uppercase Letter",
    "LowercaseLetter": "Lowercase Letter",
    "Number": "Number",
    "OneSymbol": "1 Symbol (!, #, $, %, &, @, *, ?)",
    "EightCharacters": "8 Characters",
    "AssignProducts": "Assign Products",
    "Discounts": "Discounts",
    "Error": "Error",
    "Retry": "Retry",
    "Logout": "Logout",
    "PageNotFound": "Page Not Found",
    "InternalServerError": "Internal Server Error",
    "NotAuthorized": "You are not authorized",
    "Settings": "Settings",
    "ShowPrintPopUp": "Show Print PopUp",
    "MergeReceipts": "Merge Receipts",
    "Support": "Support",
    "MobileNumber": "Mobile Number",
    "EditUser": "Edit User",
    "EditStaff": "Edit Staff",
    "Manager": "Manager",
    "AssignStaff": "Assign Staff",
    "StaffHistory": "Staff History",
    "Operation": "Operation",
    "Favourite": "Favourite",
    "SalePrice": "Sale Price",
    "Reclaim": "Reclaim",
    "Reclamation": "Reclamation",
    "Reclamations": "Reclamations",
    "ReclaimRequests": "Reclaim Requests",
    "ReclamationID": "Reclamation ID",
    "LastUpdated": "Last Updated",
    "Accept": "Accept",
    "Reject": "Reject",
    "Rejected": "Rejected",
    "IssueCreditNote": "Issue Credit Note",
    "InProgress": "In Progress",
    "InReview": "In Review",
    "New": "New",
    "ReclamationStatus": "Reclamation Status",
    "ReclaimMessage": "Reclaim Message",
    "TransactionDate": "Transaction Date",
    "Created": "Created",
    "RejectReason": "Reject Reason",
    "Reason": "Reason",
    "StatusColor": "Status Color",
    "RequestedUser": "Requested User",
    "ReclaimedAfter": "Reclaimed After",
    "Favourites": "Favourites",
    "Clear": "Clear",
    "CashCard": "Cash Card",
    "GiftCard": "Gift card",
    "Approved": "Approved",
    "Self": "Self",
    "OverdueDays": "Overdue Days",
    "YesterdaySales": "Yesterday Sales",
    "LastCashPaid": "Last Cash Paid",
    "EditFavourites": "Edit favourites",
    "OverdueAmount": "Overdue Amount",
    "Overdue": "Overdue",
    "OverdueProjection": "Overdue projection",
    "Remark": "Remark",
    "UsedIn": "Used in",
    "Days": "days",
    "AmountUsedInProjection": "Amount used in projection",
    "GenerateInvoice": "Generate Invoice",
    "GenerateCreditInvoice": "Generate Credit Invoice",
    "GenerateNetInvoice": "Generate Net Invoice",
    "Tax": "Tax",
    "Generate": "Generate",
    "InvoiceDate": "Invoice Date",
    "Net": "Net",
    "TotalBuyingAmount": "Total Buying Amount",
    "UpdateProductImage": "Update Product Image",
    "SoldDate": "Sold Date",
    "Service": "Service",
    "GenerateInvoices": "Generate invoices",
    "GrossInvoiceId": "Gross Invoice ID",
    "NetInvoiceId": "Net Invoice ID",
    "Gross": "Gross",
    "MyInvoices": "My invoices",
    "GeneratedInvoices": "Generated invoices",
    "InvoiceNo": "Invoice No",
    "CreditNoteNo": "Credit note No",
    "InvoiceValue": "Invoice value",
    "CreditNoteValue": "Credit Note Value",
    "CreditNote": "Credit Note",
    "Response": "Response",
    "CreditNoteId": "Credit note ID",
    "GrossDiscount": "Gross discount",
    "NoCustomerForDates": "No Customers for selected dates",
    "SelectTopUpPaymentMode": "Select Top Up payment modes to be given",
    "ResellerName": "Reseller Name",
    "APIName": "API Name",
    "LastInvoiceDate": "Last Invoice date",
    "LastInvoiceFromDate": "Last invoice from date",
    "LastInvoiceToDate": "Last invoice to date",
    "LastInvoiceValue": "Last invoice value",
    "LastGrossValue": "Last gross value",
    "LastCreditNote": "Last credit note",
    "ApprovedBy": "Approved by",
    "ApprovedAt": "Approved at",
    "RequestedAt": "Requested at",
    "BatchNo": "Batch No",
    "AssignedTo": "Assigned to",
    "AssignedAt": "Assigned at",
    "BatchSearch": "Batch Search",
    "UnAssignedStaff": "Unassigned Staff",
    "AssignedStaff": "Assigned Staff",
    "DevicesAssignedToYou": "Devices assigned to you",
    "AssignDevices": "Assign Devices",
    "View": "View",
    "ResellerProfit": "Reseller Profit",
    "SellingPermission": "Selling permission",
    "ClearAll": "Clear All",
    "HRBNr": "HRB Nr",
    "IBan": "IBAN",
    "BIC": "BIC",
    "Director": "Director",
    "EditGiftCard": "Edit GiftCard",
    "TopUpHistory": "TopUp Report",
    "AddTopUp": "Add TopUp",
    "TopUpReport": "TopUp Report",
    "SelectProduct": "Select Product",
    "SelectPins": "Select Pins",
    "EnableSales": "Enable sales",
    "TerminalStatus": "Terminal Status",
    "TerminalActivated": "Terminal activated",
    "TerminalInActive": "Terminal inactive",
    "BuyingDiscounts": "Buying Discounts",
    "InvoiceHeaderImage": "Invoice Header Image",
    "InvoiceFooterImage": "Invoice Footer Image",
    "SelectImage": "Select Image",
    "ProductDiscountsList": "Product discounts list",
    "InventorySearch": "Inventory search",
    "BuyingDate": "Buying date",
    "SellingDate": "Selling date",
    "SubOrganisation": "Sub organisation",
    "OR": "Or",
    "SelectCustomers": "Select customers",
    "Selected": "Selected",
    "EpayAPIDiscount": "Epay API discount",
    "APIBuyingDiscount": "API buying discount",
    "PinAndBatchSearch": "Pin and batch search",
    "CustomerReadOnly": "Customer read only",
    "CustomerFullPermission": "Customer full permission",
    "AutoCreditHistory": "Auto credit history",
    "NoCustomers": "No customers",
    "LoginLogoImage": "Login logo image",
    "InvoiceLogo": "Invoice logo",
    "SMSSenderID": "SMS sender ID",
    "MultipleDiscounts": "Multiple Discounts",
    "ConfirmMobileNumber": "Confirm mobile number",
    "MobileNotMatched": "Mobile not matched",
    "FillMobileError": "Fill mobile error",
    "PoolName": "Pool name",
    "NoOfProducts": "No of products",
    "NoOfPins": "No of Pins",
    "UnSoldPins": "Unsold Pins",
    "AddPool": "Add pool",
    "AddNewPool": "Add new pool",
    "CallingCardsPoolUpdate": "Calling cards pool update",
    "AddPoolInventory": "Add pool inventory",
    "CallingCardsPool": "CallingCardsPool",
    "CallingCards": "Calling cards",
    "CallingCard": "Calling cards",
    "PinDownload": "Pin download",
    "DownloadCSV": "Download CSV",
    "DownloadTXT": "Download TXT",
    "ShowDetails": "Show details",
    "HideDetails": "Hide details",
    "UserStatus": "User status",
    "InActive": "Inactive",
    "Registrations": "Registrations",
    "Accepted": "Accepted",
    "ShopType": "Shop type",
    "IP": "IP",
    "UpdatedBy": "Updated by",
    "SetUsername": "Set username",
    "VerifyOTP": "Verify OTP",
    "SetPassword": "Set password",
    "TerminalLimit": "Terminal Limit",
    "Limit": "Limit",
    "UpdateShopTimings": "Update Shop Timings",
    "ShopTimings": "Shop Timings",
    "StartTime": "Start Time",
    "CloseTime": "Close Time",
    "RequestedCustomerId": "Requested Customer Id",
    "Reseller": "Reseller",
    "ResellerCustomerId": "Reseller Customer Id",
    "FirstPurchasedCustomer": "First Purchased Customer",
    "FirstPurchasedCustomerId": "First Purchased Customer Id",
    "FirstPurchasedCustomerTime": "First Purchased Customer Time",
    "SupplierDiscount": "Supplier Discount",
    "Holidays": "Holidays",
    "BankName": "Bank Name",
    "Lunch": "Lunch",
    "ResoldToCustomer": "Resold to Customer",
    "ResoldToCustomerId": "Resold to CustomerId",
    "ResoldAt": "Resold at",
    "Kredit": "Kredit",
    "Welcome": "Welcome",
    "Info": "Info",
    "DownloadTeamViewer": "Download TeamViewer",
    "GenerateReclamationInvoice": "Generate reclamation invoice",
    "GeneratedReclamationInvoices": "Generated reclamation invoices",
    "ReclamationInvoices": "Reclamation invoices",
    "MyReclamationInvoices": "My Reclamation invoices",
    "MultiTopUp": "Multi TopUp",
    "EPayTerminals": "EPay Terminals",
    "TerminalId": "Terminal ID",
    "ShopName": "Shop Name",
    "CheckStatus": "Check Status",
    "Check": "Check",
    "POSDeviceAPP": "Download Printer Drivers",
    "enableLoginOTP": "Enable Login OTP",
    "disableLoginOTP": "Disable Login OTP",
    "disabledLoginOTP": "Disabled OTP Login",
    "otp": "OTP",
    "DirectCreditNote": "DirectCreditNote",
    "transactionCheck": "Transaction Check",
    "ZReport": "Z Report",
    "CardName": "Card Name",
    "PrintDate": "Print Date",
    "Employee": "Employee",
    "DateOfSale": "Date of sale",
    "Price": "Price",
    "confirmRejection": "Are you sure you want to reject",
    "tripFullyBooked": "This trip is fully booked.",
    "AmountCreditLimitError": "Credit limit reached",
    "IPAddress": "IP Address",
    "DownloadExcel": "Download Excel",
    "Resend": "Resend",
    "ResendEmail": "Resend Email",
    "ResendEmailConfirm": "Are you sure you want to send email again ?",
    "PinBatchSearch": "Pin / Batch Search",
    "ViewAll": "View All",
    "ShowLess": "Show Less",
    "Sort": "Sort",
    "SelectPlanType": "Select Plan Type",
    "PreviousReclaim": "Previous Reclaim",
    "SameBatchPinReclaimed": "Same Batch/Pin is Reclaimed",
    "UpdateRemark": "Update Remark",
    "MaskDiscount": "Mask Discount",
    "ShowDiscount": "Show Discount",
    "MaxPinQuantityError": "You can only purchase 20 at a time",
    'MaxPinQuantityError2': 'Maximum of 1500 quantity',
    "ZeroPinQuantityError": "Please select atleast 1 quantity",
    "CustomerPaymentLedger": "Customer Payment Ledger",
    "TerminalPool": "Terminal Pool",
    "Services": "Services",
    "FileGenerating": "Please wait while we generate file",
    "GetSalesReportToEmail": "Get Sales Report on Email",
    "GetCustomersToEmail": "Get Customers List on Email",
    "loginRequestApproveModal": "Are you sure you want to approve login request ?",
    "Yes": "Yes",
    "No": "No",
    "BrowserPrintDetails": "Browser Print details",
    "SessionCreated": "Session Created",
    "ApprovedStatus": "Approved Status",
    "API": "API",
    "SessionDetails": "Session Details",
    "WithoutOTP": "Without OTP",
    "ShowShopDetailsOnPrint": "Show Shop Details on Print",
    "AccessType": "Access Type",
    "LoginRequestAt": "Login Request At",
    "FlixbusTicket": "Flixbus Ticket",
    "SalesReportsNew": "Sales Reports New",
    "CurrentMonthSales": "Current Month Sales",
    "SalesComparison": "Sales Comparison",
    "Difference": "Difference",
    "DifferencePercentage": "Difference Percentage",
    "Column1": "Column 1",
    "Column2": "Column 2",
    "EnableAPI": "Enable API",
    "DisableAPI": "Disable API",
    "Copy": "Copy",
    "ResetAPIKey": "Reset API Key",
    "SalesSummary": "Sales Summary",
    "Banking": "Banking",
    "BankRecord": "Bank Records",
    "UploadBankRecords": "Upload Bank Records",
    "MappedBankCustomers": "Mapped Customers",
    "Records": "Records",
    "BankRecords": "Bank Records",
    "DeletedRecords": "Deleted Records",
    "NoRecordsToImport": "No Records to Import",
    "RecipientSender": "Recipient Sender",
    "MapCustomer": "Map Customer",
    "Unmap": "Unmap",
    "ImportCSV": "Import CSV",
    "FaceValues": "Face Values",
    "EnableFaceValues": "Enable Face Values",
    "DisableFaceValues": "Disable Face Values",
    "AddCategory": "Add Category",
    "AddNewCategory": "Add New Category",
    "CategoryName": "Category Name",
    "PleaseEnterCategoryName": "Please enter category name",
    "ProductDescription": "Product Description",
    "PleaseEnterProductName": "Please enter product name",
    "PleaseEnterValidPrice": "Please enter valid price",
    "Cart": "Cart",
    "Items": "Items",
    "Hardware": "Hardware",
    "HardwareSalesReport": "Hardware Sales Report",
    "TotalPrice": "Total Price",
    "TotalSum": "Total Sum",
    "OrderId": "Order Id",
    "UpdateCategory": "Update Category",
    "AutoCashId": "AutoCash ID",
    "AutoCash": "AutoCash",
    "AutoCashDiscount": "AutoCash Discount",
    "SelectAll": "Select All",
    "ValidPins": "Valid Pins",
    "InvalidPins": "Invalid Pins",
    "ForecastProfit": "Forecast Profit",
    "Purchase": "Purchage",
  },
  "tr": {
    "Products": "Ürünler",
    "Product": "Ürün",
    "ProductValue": "Ürün Değeri",
    "Quantity": "Adet",
    "Denomination": "Fiyat",
    "ViewProducts": "Ürünleri Göster",
    "ViewProduct": "Ürünü Göster",
    "AddProducts": "Ürünleri ekle",
    "AddProduct": "Ürün ekle",
    "AddNewProduct": "Yeni ürün ekle",
    "Instructions": "Talimatlar",
    "CustomerCareNumber": "Çağrı Merkezi Numarası",
    "ProductType": "Ürün tipi",
    "TotalDenominations": "Fiyatlar",
    "Details": "Detaylar",
    "PinLength": "Pin Uzunluğu",
    "Pin": "Pin",
    "SoldBy": "Tarafından satıldı",
    "SoldById": "Tarafından satılan ID",
    "SoldTime": "Satıldığı zaman",
    "ProductName": "Ürün adı",
    "SaveAddDenominations": "Fiyat kaydetme ve ekleme",
    "AddProductImage": "Ürün Resmi Ekleyin",
    "ProductImage": "Ürün Resmi",
    "AddDenomination": "Fiyat Ekleme",
    "Done": "Tamamlandı",
    "Add": "Ekle",
    "Delete": "Sil",
    "Update": "Güncelle",
    "Cancel": "İptal Et",
    "Amount": "Tutar",
    "OldBalance": "Eski Bakiye",
    "NewBalance": "Yeni Bakiye",
    "Paid": "Ödeme Yapılmış",
    "SellerProfit": "Satış karı",
    "ProductID": "Ürün ID",
    "ID": "ID",
    "Image": "Resim",
    "Company": "Şirket",
    "NoData": "Veri Yok",
    "NoServicesAvailable": "Hizmet Yok",
    "Contact": "İletişim",
    "AccountStatus": "Hesap Durumu",
    "ErrorData": "Veriler Alınırken Hata Oluştu",
    "Edit": "Düzenle",
    "Activate": "Aktif",
    "Deactivate": "Aktif Değil",
    "Suppliers": "Satıcılar",
    "Supplier": "Satıcı",
    "SupplierCreateTag": "Satınalmak İçin Satıcı Oluştur",
    "AddNewSupplier": "Yeni Bir Satıcı Ekle",
    "ViewSuppliers": "Satıcıları Göster",
    "ViewSupplier": "Satıcı Göster",
    "AddSupplier": "Satıcı Ekle",
    "SupplierCompany": "Satıcı Firma",
    "SupplierName": "Satıcı Adı",
    "FullName": "Adı Soyadı",
    "FullAddress": "Adres",
    "EmailId": "E-Posta Adresi",
    "Mobile": "Cep numarasi",
    "StNr": "Vergi Numarası",
    "Telephone": "Telefon",
    "UstIdnr": "Satıcı Vergi Numarası",
    "City": "Şehir",
    "Country": "Ülke",
    "PostalCode": "Posta Kodu",
    "Remarks": "Açıklama",
    "SaveAndPublish": "Kaydet ve Yayınla",
    "TotalPins": "Toplam Kontörler",
    "CreatedDate": "Oluşturma Tarihi",
    "BuyingDiscount": "Satın alma indirimi",
    "PinSeq": "Kontör Siparişi",
    "BatchSeq": "Seri Numara Siparişi",
    "Delimiter": "Limit Sınırı",
    "Discount": "İndirim",
    "Inventory": "Stok",
    "InventoryDetails": "Stok Detayları",
    "AddInventory": "Stok Ekle",
    "ViewInventory": "Stok Görüntüle",
    "UploadCSVFileOnly": "Yalnız \"csv\" Uzantılı Dosyası Yükleyiniz",
    "AddGiftCardBatch": "Hediye Kartına Seri Numarası Ekle",
    "DivisionDiscount": "İndirimler",
    "AssignmentsAndDiscount": "Siparişler ve İndirim",
    "AddCustomer": "Müşteri ekleyin",
    "EditCustomer": "Müşterti Bilgilerini Düzenle",
    "AddNewCustomer": "Yeni Müşteri Ekleyin",
    "Customer": "Müşteri",
    "BalanceTopUp": "Bakiye Yüklemek",
    "BalanceTrigger": "Bakiye Uyarıcı",
    "CreditLimit": "Kredi Limiti",
    "CustomerName": "Müşteri Adı",
    "CreditBalance": "Hesap Bakiyesi",
    "CustomerID": "Müşteri ID",
    "OrganisationType": "Firma Tipi",
    "CustomerDetails": "Müşteri Detayları",
    "Customers": "Müşteriler",
    "ViewCustomer": "Müşteriyi Göster",
    "ViewCustomers": "Müşterileri Göster",
    "FirstName": "Adınız",
    "LastName": "Soyadınız",
    "HouseNo": "Kapı Numarası",
    "Address": "Adres",
    "Landline": "Ev Telefonu",
    "Username": "Kullanıcı Adı",
    "Template": "Şablon",
    "UsernameNotAvailable": "Kullanıcı adı geçerli değil",
    "Password": "Şifre",
    "ConfirmPassword": "Şifrenizi Tekrar Girin",
    "SubDomain": "Alt Domain",
    "CustomerType": "Müşteri tipi",
    "Name": "İsim",
    "Reference": "Referans",
    "ActivatedOn": "Etkinleştir",
    "Credit": "Kredi",
    "NewCredit": "Yeni kredi",
    "OldCredit": "Eski kredi",
    "TopUp": "Rezevasyon",
    "Balance": "Bakiye",
    "SoftPins": "SoftPins",
    "EPay": "EPay",
    "Sales": "Satış",
    "SalesCount": "Satış Adedi",
    "Postal": "Posta",
    "DiscountSlab": "İndirim Oranı",
    "DiscountSlabError": "Lütfen İndirim Oranını Seçiniz",
    "LastPurchase": "Son Satın Alma",
    "PurchasedDate": "Satın Alma Tarihi",
    "BeforeBalance": "Bakiye Öncesi",
    "Value": "Değer",
    "Sold": "Satılmış",
    "UnSold": "Satılmamış",
    "DateTime": "Tarih / Saat",
    "AfterBalance": "Bakiye Sonrası",
    "BeforeCredit": "Kredi önce",
    "AfterCredit": "Kredi sonra",
    "LastPaidAmount": "Son Ödenen Tutar",
    "Filter": "Filtre",
    "ClearAllFilters": "Tüm Filtreleri Temizle",
    "PAD": "Ürün İndirimi",
    "AddCredit": "Kredi Ekle",
    "AutoCredit": "Otomatik Kredi",
    "CreditBalanceTransaction": "Kredi / Bakiye İşlemi",
    "Date": "Tarih",
    "GrandTotal": "Genel Toplam",
    "Cash": "Nakit",
    "PaymentType": "Ödeme şekli",
    "TransactionType": "İşlem tipi",
    "TransactionMode": "İşlem modu",
    "PaymentMode": "Ödeme Şekli",
    "StandingCredit": "Kredili Mevduat Hesabı",
    "StandingBalance": "Kalan Bakiye",
    "ForecastAmountneed": "Tahmini Tutar",
    "CreditRemark": "Kredi Notu",
    "Users": "Kullanıcılar",
    "ViewUsers": "Kullanıcıları görüntüle",
    "User": "Kullanıcı",
    "UserName": "Kullanıcı adı",
    "UserId": "Kullanıcı kimliği",
    "AddUser": "Kullanıcı ekle",
    "AddNewUser": "Yeni kullanıcı ekle",
    "JobRole": "İş rolü",
    "Privileges": "Ayrıcalıklar",
    "SaveUser": "Kullanıcıyı kaydet",
    "UpdateUser": "Kullanıcıyı güncelle",
    "AddNewStaff": "Yeni personel ekle",
    "AddNewStaffTag": "Sınırlı İşleme Sahip Yeni Personel Oluştur",
    "CashCards": "Nakit Kartlar",
    "TotalBill": "Toplam Fatura",
    "Profit": "Kar",
    "Print": "Yazdır",
    "Close": "Kapat",
    "PlanId": "Plan kimliği",
    "PlanName": "Plan adı",
    "DiscountSlabs": "Fiyat İndirim Oranları",
    "ProductsDiscount": "İndirimli Ürünler",
    "ErrorDiscountSlabs": "İndirimli Fiyat Alınırken Hata Oluştu",
    "DiscountPlanName": "İndirim Adı",
    "AddDiscountSlab": "İndirimli Fiyat Ekleme",
    "NoDiscountSlabs": "İndirim Yok",
    "ViewDiscountSlabs": "İndirimli Fiyat Görüntüleme",
    "DenominationAmount": "Tek Tutar",
    "BatchNumber": "Parti numarası",
    "DiscountAmountError": "İndirim değeri negatif olmamalıdır",
    "AmountError": "Lütfen Tutarı Yazın (Tutar 0'dan büyük olmalıdır)",
    "DenominationAmountError": "Lütfen Değer Girin (Değer 0'dan büyük olmalıdır)",
    "DynamicProducts": "Dinamik Ürünler",
    "DynamicProductsDiscounts": "Dinamik Ürünler İndirimleri",
    "SoftApiCard": "SoftApi Kartı",
    "EPayApiId": "EPay Api Kimliği",
    "EPayApiEAN": "EPay Barkod",
    "EPayApiName": "EPay Api Adı",
    "Submit": "Teslim etmek",
    "Priority": "Öncelik",
    "GiftCards": "Hediye kartları",
    "EnterCardNoLabel": "Lütfen kart numarasını giriniz",
    "EnterEANLabel": "Lütfen barkod girin",
    "EnterBachNo": "Lütfen parti numarasını girin",
    "DirectRecharge": "Doğrudan yeniden ücretlendirme",
    "DirectRechargeHistory": "Doğrudan yeniden ücretlendirme geçmişi",
    "EnterMobileNo": "Lütfen telefon numaranızı giriniz",
    "EnterPanNumber": "Lütfen hesap numarasını girin",
    "TransactionID": "İşlem kimliği",
    "Provider": "Sağlayıcı",
    "Status": "Durum",
    "TransactionSuccessful": "İşlem başarılı",
    "TransactionFailed": "İşlem başarısız",
    "TransactionPending": "İşlem bekleniyor",
    "Buy": "Satın al",
    "Recharge": "Tekrar Yüklemek (Kontör Yüklemek)",
    "InternationalTopUp": "Uluslararası Yeniden Doldurma (Uluslararası Kontör Yüklemek)",
    "GetProviders": "Satıcıyı Seç",
    "DirectRechargeDiscount": "Doğrudan yeniden ücretlendirme indirimi",
    "InternationalTopUpDiscount": "Uluslararası Kontör İndirimi",
    "FlixBusDiscount": "FlixBus indirimi",
    "DynamicDiscounts": "Aktif İndirimler",
    "Save": "Kaydet",
    "RechargeSuccessful": "Yeniden Yükleme Başarılı",
    "SalesReports": "Satış raporları",
    "PaymentLedger": "Ödeme defteri",
    "RechargeSummaryReport": "Yeniden ücretlendirme özet raporu",
    "DynamicProductsLog": "Aktif Ürünler Kaydı",
    "Download": "İndir",
    "SalesReport": "Satış raporu",
    "StockReport": "Stok raporu",
    "StockReportDetails": "Ayrıntılı Stok Raporu",
    "InventoryLogReport": "Envanter günlüğü raporu",
    "BatchSummaryReport": "Seri Numarası Özet Raporu",
    "Reports": "Raporlar",
    "Organisation": "Organizasyon",
    "CustomerId": "Müşteri kimliği",
    "InventoryType": "Envanter Türü",
    "DynamicType": "Dinamik tip",
    "ConfigAmount": "Yapılandırma Tutarı",
    "StockCount": "Stok sayısı",
    "AvelStock": "Avel Stok",
    "SoldCount": "Satılan Numara",
    "UnSoldCount": "Satılmayan Numara",
    "UnPublishedCount": "Yayınlanmamış Numara",
    "SoldPercentage": "Satış Yüzdesi",
    "UnSoldPercentage": "Satış olmayan Yüzdesi",
    "UnPublishedPercentage": "Yayınlanmamış Yüzdesi",
    "PinsCount": "Kontör Adedi",
    "SoldPinsCount": "Satılan Kontör Adedi",
    "UnSoldPinsCount": "Satılmayan Kontör Adedi",
    "UnPublishedPinsCount": "Yayınlanmamış Kontör Adedi",
    "Batch": "Seri Numarası",
    "LotNo": "Parti Numarası",
    "SoldPinsPercentage": "Satılan Kontör Yüzdesi",
    "UnSoldPinsPercentage": "Satılmayan Kontör Yüzdesi",
    "SoldUnSoldPinsPercentage": "Satılan / Satılmayan Kontör Yüzdelik Oranı",
    "UnPublishedPinsPercentage": "Yayınlanmamış Kontör Yüzdesi",
    "Actions": "Hareketler",
    "Invoices": "Faturalar",
    "SNo": "Seri numarası",
    "FlixBus": "FlixBus",
    "OneWay": "Tek yön",
    "RoundTrip": "Gidiş Dönüş Bileti",
    "Adult": "Yetişkin",
    "For15Years": "15 yaş için",
    "Children": "Çocuk",
    "ZeroTo14Years": "0 ile 14 yaş",
    "Bikes": "Bisikletler",
    "BikeHelp": "E-bisikletlere izin verilmez.",
    "LoginRequests": "Oturum Açma İsteği",
    "AuthStatus": "Yetkilendirme durumu",
    "Approve": "Onayla",
    "CallAdmin": "Yöneticiyi Arayın",
    "Terminal": "Terminal",
    "ActivateTerminal": "Terminali etkinleştir",
    "SendTerminalDetails": "Terminal ayrıntılarını Gönder",
    "Domain": "Domain",
    "SearchCustomer": "Müşteri bul",
    "EditSupplier": "Tedarikçiyi düzenle",
    "SupplierEditTag": "Ürünleri satın almak için tedarikçiyi düzenleyin",
    "PinNumber": "Kontör numarası",
    "Hotline": "Yardım Hattı",
    "DateAndTime": "Tarih ve saat",
    "CellCredit": "Cep Telefonu Kredisi",
    "CellCurrency": "Cell Currency",
    "Serial": "Seri numarası",
    "CardActivatedSuccessfully": "Kart Başarıyla Etkinleştirildi",
    "SellingPrice": "Satış fiyatı",
    "BookingNumber": "Rezervasyon numarası",
    "TicketPrice": "Bilet fiyatı",
    "Departure": "Kalkış",
    "Arrival": "Varış",
    "Adults": "Yetişkinler",
    "Bicycle": "Bisiklet",
    "PassengerInformation": "Yolcu bilgileri",
    "Description": "Açıklama",
    "LineDirection": "Hat yönü",
    "InfoBlocks": "Bilgi Blokları",
    "Devices": "Cihazlar",
    "SNO": "Numara",
    "DeviceTag": "Cihaz etiketi",
    "DeviceSerialNo": "Seri numarası",
    "DeviceStatus": "Durum",
    "Assigned": "Atanan",
    "AddDevice": "Cihaz ekle",
    "UpdateDevice": "Cihazı güncelle",
    "DeviceLastUpdated": "Son güncelleme",
    "Staff": "Personel",
    "Invoice": "Fatura",
    "ChangePassword": "Şifre değiştir",
    "Flag": "Bayrak",
    "OldCreditLimit": "Eski kredi limiti",
    "OldBalanceTrigger": "Eski kredi Uyarıcı",
    "OldBalanceTopUp": "Eski Kredili Kontör",
    "AlternativeMobiles": "Alternatif Cep Telefonları",
    "AlternativeMobile1": "Alternatif Cep Telefonu 1",
    "AlternativeMobile2": "Alternatif Cep Telefonu 2",
    "TotalCredit": "Toplam kredi",
    "TotalCash": "Toplam nakit",
    "TotalSales": "Toplam satış",
    "TotalBalance": "Toplam bakiye",
    "BuyingPrice": "Saatın Alma Fiyatı",
    "MobileNo": "CepTelefon Numarası",
    "Hello": "Merhaba",
    "BackToHome": "Ana Sayfaya Dön",
    "WelcomeBackLoginText": "Tekrar hoşgeldiniz",
    "Login": "Oturum aç",
    "OTP": "Erişim kodu",
    "DidNotGetOTP": "Erişim kodu almadınız mı?",
    "ClickForAdminApproval": "Yönetici onayı için burayı tıklayın",
    "Verify": "Doğrulayın",
    "ReferenceCode": "Referans Kodu",
    "CheckApprovalStatus": "Onay durumunu kontrol edin",
    "Search": "Arama",
    "FromCity": "Nereden",
    "ToCity": "Nereye",
    "Return": "Dönüş",
    "Remove": "Kaldır",
    "Seat": "Koltuk",
    "Reserve": "Rezervasyonlar",
    "PassengerDetails": "Yolcu Detayları",
    "DOB": "Doğum tarihi",
    "YourOrder": "Siparişiniz",
    "Total": "Toplam",
    "inclVAT": "(KDV dahil)",
    "EnterVoucher": "Fişi Girin",
    "Voucher": "Fiş",
    "ProceedToPayment": "Ödemeye devam et",
    "SelectQuantity": "Miktarı seçiniz",
    "SelectDenomination": "Tutarı Seçiniz",
    "Region": "Bölge",
    "AmountReceive": "Alınacak miktar",
    "Trip": "Gezi",
    "ContactDetails": "İletişim detayları",
    "FromDate": "Başlangıç Tarihi",
    "ToDate": "Bitiş Tarihi",
    "SelectUser": "Kullanıcı seç",
    "TotalSaleAmount": "Toplam satış miktarı",
    "TotalSaleProfit": "Toplam satış karı",
    "TotalSalePins": "Toplam satış Kontörler",
    "Transactions": "İşlemler",
    "Action": "Hareketler",
    "Notes": "Notlar",
    "YES": "Evet",
    "NO": "Hayır",
    "CreditHistory": "Kredi geçmişi",
    "CreditLedger": "Kredi kayıtları",
    "Referer": "Referans",
    "BalanceType": "Bakiye tipi",
    "EnableWebSupport": "Web desteğini etkinleştir",
    "OK": "Tamam",
    "AddedCustomerSuccessfully": "Müşteri başarıyla eklendi",
    "Previous": "Önceki",
    "Next": "Sonraki",
    "SelectCustomer": "Müşteri seçin",
    "Preview": "Ön izleme",
    "All": "Hepsi",
    "Changes": "Değişiklikler",
    "LastActive": "Son aktif",
    "UnAssignedDevices": "Atanmamış cihazlar",
    "Assign": "Atamak",
    "UnAssign": "Atanmamış",
    "AssignedDevices": "Atanan cihazlar",
    "Active": "Aktif",
    "SelectDiscountSlab": "İndirim listesi seçin",
    "UpdateDiscounts": "İndirimleri güncelle",
    "SearchProduct": "Ürün ara",
    "AddBatch": "Toplu ekle",
    "Invalid": "Geçersiz",
    "pins": "Kontörler",
    "Valid": "Geçerli",
    "NoPinsToImported": "İçe aktarılacak Kontör yok",
    "LoadMore": "Daha fazla yükle",
    "SoldPins": "Satılan Kontörler",
    "Published": "Yayınlanan",
    "Unpublished": "Yayınlanmamış",
    "CurrentBalance": "Cari Bakiye",
    "Credits": "Krediler",
    "OpeningBalance": "Açılış tutarı",
    "ClosingBalance": "Kapanış tutarı",
    "ProductConfig": "Ürün Yapılandırması",
    "UppercaseLetter": "Büyük harf",
    "LowercaseLetter": "Küçük harf",
    "Number": "Numara",
    "OneSymbol": "1 Sembol (!, #, $, %, &, @, *, ?)",
    "EightCharacters": "8 Karakter",
    "AssignProducts": "Ürünleri Ata",
    "Discounts": "İndirimler",
    "Error": "Hata",
    "Retry": "Tekrar Deneyin",
    "Logout": "Çıkış yap",
    "PageNotFound": "Sayfa bulunamadı",
    "InternalServerError": "Sunucu Hatası",
    "NotAuthorized": "Yetkili değilsiniz",
    "Settings": "Ayarlar",
    "ShowPrintPopUp": "Baskı önizlemesini göster",
    "MergeReceipts": "Faturaları Birleştir",
    "Support": "Destek",
    "MobileNumber": "Cep numarası",
    "EditUser": "Kullanıcıyı düzenle",
    "EditStaff": "Personeli düzenle",
    "Manager": "Müdür",
    "AssignStaff": "Personel Ata",
    "StaffHistory": "Personel Geçmişi",
    "Operation": "Operasyon",
    "Favourite": "Favori",
    "SalePrice": "Satış fiyatı",
    "Reclaim": "İptal",
    "Reclamation": "İade",
    "Reclamations": "İadeler",
    "ReclaimRequests": "İade Talepleri",
    "ReclamationID": "İade Numarası",
    "LastUpdated": "Son güncelleme",
    "Accept": "Kabul et",
    "Reject": "Reddet",
    "Rejected": "Reddedildi",
    "IssueCreditNote": "Kredi Notu Ver",
    "InProgress": "İşlem Devam Ediyor",
    "InReview": "İnceleniyor",
    "New": "Yeni",
    "ReclamationStatus": "İade durumu",
    "ReclaimMessage": "Mesajı Geri Al",
    "TransactionDate": "İşlem tarihi",
    "Created": "Oluştur",
    "RejectReason": "Reddedilme nedeni",
    "Reason": "Gerekçe",
    "StatusColor": "Durum rengi",
    "RequestedUser": "Talep Edilen Kullanıcı",
    "ReclaimedAfter": "Kredi Talebi Sonrası",
    "Favourites": "Favoriler",
    "Clear": "Sil",
    "CashCard": "Banka Kartı",
    "GiftCard": "Hediye kartı",
    "Approved": "Onaylandı",
    "Self": "Kendisi",
    "OverdueDays": "Gecikmiş günler",
    "YesterdaySales": "Dünkü satışlar",
    "LastCashPaid": "Ödenen Son Nakit",
    "EditFavourites": "Favorileri düzenleyin",
    "OverdueAmount": "Geciken tutar",
    "Overdue": "Gecikmiş",
    "OverdueProjection": "Ödemesi Geçmiş",
    "Remark": "Açıklama",
    "UsedIn": "Kullanılmış",
    "Days": "Günler",
    "AmountUsedInProjection": "Kullanılan miktar",
    "GenerateInvoice": "Fatura oluştur",
    "GenerateCreditInvoice": "Kredi faturası oluştur",
    "GenerateNetInvoice": "Fatura Oluştur",
    "Tax": "Vergi",
    "Generate": "Oluştur",
    "InvoiceDate": "Fatura tarihi",
    "Net": "Ağ",
    "TotalBuyingAmount": "Toplam alış miktarı",
    "UpdateProductImage": "Ürün resmini güncelle",
    "SoldDate": "Satış tarihi",
    "Service": "Hizmet",
    "GenerateInvoices": "Faturaları oluştur",
    "GrossInvoiceId": "Brüt fatura kimliği",
    "NetInvoiceId": "Fatura kimliği",
    "Gross": "Brüt",
    "MyInvoices": "Faturalarım",
    "GeneratedInvoices": "Oluşturulan faturalar",
    "InvoiceNo": "Fatura Numarası",
    "CreditNoteNo": "Kredi numarası",
    "InvoiceValue": "Fatura değeri",
    "CreditNoteValue": "Kredi değeri",
    "CreditNote": "Kredi notu",
    "Response": "Yanıt",
    "CreditNoteId": "Kredi notu kimliği",
    "GrossDiscount": "Brüt indirim",
    "NoCustomerForDates": "Seçilen Tarihlerde Müşteri Bulunamadı",
    "SelectTopUpPaymentMode": "Kontör Ödeme Yöntemini Seçin",
    "ResellerName": "Bayi Adı",
    "APIName": "API Adı",
    "LastInvoiceDate": "Son fatura tarihi",
    "LastInvoiceFromDate": "Tarihten itibaren son fatura",
    "LastInvoiceToDate": "Tarihe kadarki son fatura",
    "LastInvoiceValue": "Son fatura değeri",
    "LastGrossValue": "Son brüt değer",
    "LastCreditNote": "Son kredi notu",
    "ApprovedBy": "Tarafından onaylandı",
    "ApprovedAt": "Onaylandı",
    "RequestedAt": "Talep edildi",
    "BatchNo": "Seri numarası",
    "AssignedTo": "Atanan",
    "AssignedAt": "Atanma tarihi",
    "BatchSearch": "Seri numarası ara",
    "UnAssignedStaff": "Atanmamış personel",
    "AssignedStaff": "Atanan personel",
    "DevicesAssignedToYou": "Tarafınıza verilen cihazlar",
    "AssignDevices": "Atanan Cihazlar",
    "View": "Görüntüle",
    "ResellerProfit": "Bayi Karı",
    "SellingPermission": "Satış izni",
    "ClearAll": "Temizle",
    "HRBNr": "HRB numarası",
    "IBan": "IBAN",
    "BIC": "BIC",
    "Director": "Yönetici",
    "EditGiftCard": "Hediye kartı düzenle",
    "TopUpHistory": "Masraf raporu",
    "AddTopUp": "Yükleme",
    "TopUpReport": "Masraf raporu",
    "SelectProduct": "Ürün seç",
    "SelectPins": "Kontör Seçin",
    "EnableSales": "Satışları Etkinleştir",
    "TerminalStatus": "Bağlantı durumu",
    "TerminalActivated": "Bağlantı etkinleştirildi",
    "TerminalInActive": "Bağlantı devre dışı",
    "BuyingDiscounts": "Satın alma indirimi",
    "InvoiceHeaderImage": "Fatura başlık resmi",
    "InvoiceFooterImage": "Fatura altbilgi esmi",
    "SelectImage": "Fotoğraf seç",
    "ProductDiscountsList": "İndirimli Ürün Listesi",
    "InventorySearch": "Envanter Arama",
    "BuyingDate": "Satın alma tarihi",
    "SellingDate": "Satış tarihi",
    "SubOrganisation": "Alt organizasyon",
    "OR": "Veya",
    "SelectCustomers": "Müşteri Seçin",
    "Selected": "Seçildi",
    "EpayAPIDiscount": "Epay API indirimi",
    "APIBuyingDiscount": "API satın alma indirimi",
    "PinAndBatchSearch": "Toplu Kontör Arama",
    "CustomerReadOnly": "Sadece müşteri okur",
    "CustomerFullPermission": "Müşteriden tam izin",
    "AutoCreditHistory": "Otomatik kredi geçmişi",
    "NoCustomers": "Müşteri yok",
    "LoginLogoImage": "Giriş Ekranı Resmi",
    "InvoiceLogo": "Fatura logosu",
    "SMSSenderID": "SMS gönderen kimliği",
    "MultipleDiscounts": "Çoklu İndirim",
    "ConfirmMobileNumber": "Cep Telefonunu Onaylayın",
    "MobileNotMatched": "Cep Telefonu Eşleşmedi",
    "FillMobileError": "Cep Telefon Hatası Ekleyin",
    "PoolName": "Havuz adı",
    "NoOfProducts": "Ürün sayısı",
    "NoOfPins": "Kontör Sayısı",
    "UnSoldPins": "Satılmayan Kontörler",
    "AddPool": "Havuz ekle",
    "AddNewPool": "Yeni havuz ekle",
    "CallingCardsPoolUpdate": "Ön ödemeli kart havuzunun güncellenmesi",
    "AddPoolInventory": "Havuz envanteri ekleyin",
    "CallingCardsPool": "Kontör Havuzu",
    "CallingCards": "Kontör Arama",
    "CallingCard": "Kontör Arama",
    "PinDownload": "Kontör Yükle",
    "DownloadCSV": "CSV indirin",
    "DownloadTXT": "TXT indirin",
    "ShowDetails": "Detayları göster",
    "HideDetails": "Detayları gizle",
    "UserStatus": "Kullanıcı durumu",
    "InActive": "Aktif değil",
    "Registrations": "Kayıtlar",
    "Accepted": "Kabul edildi",
    "ShopType": "Dükkan tipi",
    "IP": "IP adresi",
    "UpdatedBy": "Tarafından güncellendi",
    "SetUsername": "Kullanıcı adı belirle",
    "VerifyOTP": "OTP'yi kontrol edin",
    "SetPassword": "Şifre belirle",
    "TerminalLimit": "Terminal Limiti",
    "Limit": "Limit",
    "UpdateShopTimings": "Alışveriş Saatlerini Güncelle",
    "ShopTimings": "Alışveriş Saatleri",
    "StartTime": "Açılış ​​saati",
    "CloseTime": "Kapanış saati",
    "RequestedCustomerId": "İstenilen Bayi Numarası",
    "Reseller": "Bayi",
    "ResellerCustomerId": "Bayi Numarası",
    "FirstPurchasedCustomer": "İlk Satın Alan Müşteri",
    "FirstPurchasedCustomerId": "İlk Satın Alan Müşteri Kimliği",
    "FirstPurchasedCustomerTime": "İlk Satın Alan Müşterinin Tarihi",
    "SupplierDiscount": "Tedarikçi indirimi",
    "Holidays": "Tatil",
    "BankName": "Banka Adı",
    "Lunch": "Öğle yemeği",
    "ResoldToCustomer": "Müşteriye yeniden satıldı",
    "ResoldToCustomerId": "Müşteri Kimliğine Yeniden Satıldı",
    "ResoldAt": "yeniden satıldı",
    "Kredit": "Kredi",
    "Welcome": "Hoşgeldiniz",
    "Info": "Bilgi",
    "DownloadTeamViewer": "TeamViewer indirin",
    "GenerateReclamationInvoice": "Şikayet faturası oluşturun",
    "GeneratedReclamationInvoices": "Oluşturulan şikayet faturaları",
    "ReclamationInvoices": "Şikayet faturaları",
    "MyReclamationInvoices": "Şikayet faturalarım",
    "MultiTopUp": "Çoklu yükleme",
    "EPayTerminals": "EPay terminalleri",
    "TerminalId": "Terminal Kimliği",
    "ShopName": "Mağaza ismi",
    "CheckStatus": "Doğrulama",
    "Check": "Kontrol etmek",
    "POSDeviceAPP": "Yazıcı sürücüsünü indirin",
    "enableLoginOTP": "Oturum açma OTP'sini etkinleştir",
    "disableLoginOTP": "Oturum açma OTP'sini devre dışı bırak",
    "disabledLoginOTP": "OTP girişi devre dışı bırakıldı",
    "otp": "OTP",
    "DirectCreditNote": "Direkt Kredi Notu",
    "transactionCheck": "İşlem doğrulama",
    "ZReport": "Z raporu",
    "CardName": "Kart adı",
    "PrintDate": "Basım tarihi",
    "Employee": "Çalışan",
    "DateOfSale": "Satış tarihi",
    "Price": "fiyat",
    "confirmRejection": "Reddetmek istediğine emin misin",
    "tripFullyBooked": "Bu gezi rezerve edildi",
    "AmountCreditLimitError": "Kredi limitine ulaşıldı",
    "IPAddress": "IP adresi",
    "DownloadExcel": "Excel indirin",
    "Resend": "Tekrar gönderin",
    "ResendEmail": "E-postayı gönder",
    "ResendEmailConfirm": "Tekrar e-posta göndermek istediğinizden emin misiniz?",
    "PinBatchSearch": "Sabitleme / toplu arama",
    "ViewAll": "Hepsini gör",
    "ShowLess": "Daha az göster",
    "Sort": "Sıralandır",
    "SelectPlanType": "Tarifenizi Seçin",
    "PreviousReclaim": "Geri Alma",
    "SameBatchPinReclaimed": "Aynı ücret / pin geri alınacaktır",
    "UpdateRemark": "Ek açıklamayı güncelle",
    "MaskDiscount": "Maske indirimi",
    "ShowDiscount": "İndirimi göster",
    "ForecastProfit": "Tahmini kar",
    "Purchase": "Satın Almak",
  },
  "de": {
    "Products": "Produkte",
    "Product": "Produkt",
    "ProductValue": "Produktwert",
    "Quantity": "Menge",
    "Denomination": "Wert",
    "ViewProducts": "Produkte anzeigen",
    "ViewProduct": "Produkt anzeigen",
    "AddProducts": "Produkte hinzufügen",
    "AddProduct": "Produkt hinzufügen",
    "AddNewProduct": "Neues Produkt hinzufügen",
    "Instructions": "Anleitung",
    "CustomerCareNumber": "Kundenhotline",
    "ProductType": "Produkt Typ",
    "TotalDenominations": "Gesamtwert",
    "Details": "Detail",
    "PinLength": "Pinlänge",
    "Pin": "Pin",
    "MaxPinQuantityError2": "Max erlaubt ist nur 1500stk",
    "SoldBy": "verkauft durch",
    "SoldById": "verkauft durch ID",
    "SoldTime": "verkaufte Zeit",
    "ProductName": "Produktname",
    "SaveAddDenominations": "speichern und hinzufügen der Wertes",
    "AddProductImage": "Produktbild hinzufügen",
    "ProductImage": "Produktbild",
    "AddDenomination": "Wert hinzufügen",
    "Done": "erledigt",
    "Add": "hinzufügen",
    "Delete": "löschen",
    "Update": "aktualisieren",
    "Cancel": "stornieren",
    "Amount": "Betrag",
    "OldBalance": "alter Kontostand",
    "NewBalance": "Kontostand",
    "Paid": "bezahlt",
    "SellerProfit": "Gewinn",
    "ProductID": "Produkt ID",
    "ID": "Kunden ID",
    "Image": "Bild",
    "Company": "Unternehmen",
    "NoData": "keine Daten verfügbar",
    "NoServicesAvailable": "keine Dienste verfügbar",
    "Contact": "Kontakt",
    "AccountStatus": "Kontostatus",
    "ErrorData": "Fehler beim Abrufen der Daten",
    "Edit": "bearbeiten",
    "Activate": "aktivieren",
    "Deactivate": "deaktivieren",
    "Suppliers": "Lieferanten",
    "Supplier": "Lieferant",
    "SupplierCreateTag": "Erstellen Sie einen Lieferanten, um Produkte zu kaufen",
    "AddNewSupplier": "Fügen Sie einen neuen Lieferanten hinzu",
    "ViewSuppliers": "Lieferanten anzeigen",
    "ViewSupplier": "Lieferant anzeigen",
    "AddSupplier": "Lieferanten hinzufügen",
    "SupplierCompany": "Lieferant",
    "SupplierName": "Lieferanten Name",
    "FullName": "Vollständiger Name",
    "FullAddress": "Vollständige Adresse",
    "EmailId": "E-Mail Adresse",
    "Mobile": "Mobilnummer",
    "StNr": "Steuernummer",
    "Telephone": "Telefon",
    "UstIdnr": "Ust-IdNr.",
    "City": "Stadt",
    "Country": "Land",
    "PostalCode": "Postleitzahl",
    "Remarks": "Bemerkung",
    "SaveAndPublish": "speichern und veröffentlichen",
    "TotalPins": "Gesamt Pin",
    "CreatedDate": "Erstellungsdatum",
    "BuyingDiscount": "Kaufrabatt",
    "PinSeq": "Pin Reihenfolge",
    "BatchSeq": "Seriennummer Reihenfolge",
    "Delimiter": "Trennzeichen",
    "Discount": "Rabatt",
    "Inventory": "Inventar",
    "InventoryDetails": "Inventardetails",
    "AddInventory": "Inventar hinzufügen",
    "ViewInventory": "Inventar anzeigen",
    "UploadCSVFileOnly": "Laden Sie nur Dateien mit der Erweiterung .csv hoch",
    "AddGiftCardBatch": "Gutscheinkarten-Seriennummer hinzufügen",
    "DivisionDiscount": "Abteilungsrabatt",
    "AssignmentsAndDiscount": "Aufträge und Rabatt",
    "AddCustomer": "Kunden hinzufügen",
    "EditCustomer": "Kunden bearbeiten",
    "AddNewCustomer": "Neuen Kunden hinzufügen",
    "Customer": "Kunde",
    "BalanceTopUp": "Guthaben Aufladen",
    "BalanceTrigger": "Guthaben Auslöser",
    "CreditLimit": "Kreditlimit",
    "CustomerName": "Kundenname",
    "CreditBalance": "Kontostand",
    "CustomerID": "Kunden ID",
    "OrganisationType": "Organisationstyp",
    "CustomerDetails": "Kundendetails",
    "Customers": "Kunden",
    "ViewCustomer": "Kunde anzeigen",
    "ViewCustomers": "Kunden anzeigen",
    "FirstName": "Vorname",
    "LastName": "Nachname",
    "HouseNo": "Hausnummer",
    "Address": "Adresse",
    "Landline": "Festnetz",
    "Username": "Benutzername",
    "Template": "Vorlage",
    "UsernameNotAvailable": "Benutzername nicht verfügbar",
    "Password": "Passwort",
    "ConfirmPassword": "Kennwort bestätigen",
    "SubDomain": "Sub Domain",
    "CustomerType": "Kundentyp",
    "Name": "Name",
    "Reference": "Referenz",
    "ActivatedOn": "Registrierung am",
    "Credit": "Kredit",
    "NewCredit": "Neuer Kredit",
    "OldCredit": "Alte Kredit",
    "TopUp": "Buchung",
    "Balance": "Konto",
    "SoftPins": "SoftPins",
    "EPay": "EPay",
    "Sales": "Umsatz",
    "SalesCount": "Verkaufszahl",
    "Postal": "Postleitzahl",
    "DiscountSlab": "Provisionsstufe",
    "DiscountSlabError": "Bitte Provisionsstufe auswählen",
    "LastPurchase": "Letzter Kauf",
    "PurchasedDate": "Kaufdatum",
    "BeforeBalance": "Guthaben vorher",
    "Value": "Wert",
    "Sold": "Verkauft",
    "UnSold": "Unverkauft",
    "DateTime": "Datum Uhrzeit",
    "AfterBalance": "Guthaben nachher",
    "BeforeCredit": "Vor Kredit",
    "AfterCredit": "Nach Kredit",
    "LastPaidAmount": "Letzter bezahlter Betrag",
    "Filter": "Filter",
    "ClearAllFilters": "Alle Filter löschen",
    "PAD": "Provision für Produktzuordnung",
    "AddCredit": "Kredit hinzufügen",
    "AutoCredit": "Auto-Kredit",
    "CreditBalanceTransaction": "Kredit-/Saldo-Transaktion",
    "Date": "Datum",
    "GrandTotal": "Gesamtsumme",
    "Cash": "Bargeld",
    "PaymentType": "Zahlungsart",
    "TransactionType": "Transaktions-Typ",
    "TransactionMode": "Transaktionsmodus",
    "PaymentMode": "Zahlungsart",
    "StandingCredit": "Dispo Kredit",
    "StandingBalance": "Bleibende Guthaben",
    "ForecastAmountneed": "offener Betrag",
    "CreditRemark": "Anmerkung",
    "Users": "Benutzer",
    "ViewUsers": "Benutzer anzeigen",
    "User": "Benutzer",
    "UserName": "Benutzername",
    "UserId": "Benutzer-Id",
    "AddUser": "Benutzer hinzufügen",
    "AddNewUser": "Neuen Benutzer hinzufügen",
    "JobRole": "Arbeitsrolle",
    "Privileges": "Privilegien",
    "SaveUser": "Benutzer speichern",
    "UpdateUser": "Benutzer aktualisieren",
    "AddNewStaff": "Mitarbeiter hinzufügen",
    "AddNewStaffTag": "Personal mit eingeschränktem Zugriff erstellen",
    "CashCards": "Cash Karten",
    "TotalBill": "Verkaufpreis",
    "Profit": "Gewinn",
    "Print": "drucken",
    "Close": "schließen",
    "PlanId": "Plan Id",
    "PlanName": "Name des Plans",
    "DiscountSlabs": "Provisionsstufen",
    "ProductsDiscount": "Produkte-Provision",
    "ErrorDiscountSlabs": "Fehler beim Abrufen von Provision-Daten",
    "DiscountPlanName": "Name der Provision-Stufe",
    "AddDiscountSlab": "Provision-Stufe hinzufügen",
    "NoDiscountSlabs": "Keine Provision-Daten",
    "ViewDiscountSlabs": "Provisionsdaten anzeigen",
    "DenominationAmount": "Einzelwert",
    "BatchNumber": "Seriennnummer",
    "DiscountAmountError": "Diskontierungswert sollte nicht negativ sein",
    "AmountError": "Bitte füllen Sie den Betrag / Betrag sollte größer als 0 sein",
    "DenominationAmountError": "Bitte Stückelungsbetrag ausfüllen / Betrag sollte größer als 0 sein",
    "DynamicProducts": "Dynamische Produkte",
    "DynamicProductsDiscounts": "Rabatte für dynamische Produkte",
    "SoftApiCard": "SoftApi-Karte",
    "EPayApiId": "EPay Api Id",
    "EPayApiEAN": "EPay EAN",
    "EPayApiName": "EPay Api Name",
    "Submit": "Einreichen",
    "Priority": "Priorität",
    "GiftCards": "Gutscheinkarten",
    "EnterCardNoLabel": "Bitte Kartennummer eingeben",
    "EnterEANLabel": "Bitte EAN eingeben",
    "EnterBachNo": "Bitte Seriennummer eingeben",
    "DirectRecharge": "Direktaufladung",
    "DirectRechargeHistory": "Kontobericht von Direktaufladungen",
    "EnterMobileNo": "Bitte Handynummer eingeben",
    "EnterPanNumber": "Bitte Pan-Nummer eingeben",
    "TransactionID": "Transaktions-ID",
    "Provider": "Anbieter",
    "Status": "Status",
    "TransactionSuccessful": "Transaktion erfolgreich",
    "TransactionFailed": "Transaktion fehlgeschlagen",
    "TransactionPending": "Ausstehende Transaktion",
    "Buy": "Kaufen",
    "Recharge": "Aufladen",
    "InternationalTopUp": "Internationale Aufladung",
    "GetProviders": "Anbieter auswählen",
    "DirectRechargeDiscount": "Direkter Auflade-Rabatt",
    "InternationalTopUpDiscount": "Internationaler TopUp-Rabatt",
    "FlixBusDiscount": "FlixBus-Rabatt",
    "DynamicDiscounts": "Dynamische Rabatte",
    "Save": "Speichern",
    "RechargeSuccessful": "Aufladung erfolgreich",
    "SalesReports": "Verkaufsberichte",
    "PaymentLedger": "Zahlungsverlauf",
    "RechargeSummaryReport": "Aufladungsbericht",
    "DynamicProductsLog": "Protokoll für dynamische Produkte",
    "Download": "Herunterladen",
    "SalesReport": "Verkaufsbericht",
    "StockReport": "Lager-Bericht",
    "StockReportDetails": "Lagerbericht in Details",
    "InventoryLogReport": "Bericht zum Inventarisierungsprotokoll",
    "BatchSummaryReport": "Seriennummer-Zusammenfassungsbericht",
    "Reports": "Berichte",
    "Organisation": "Organisation",
    "CustomerId": "Kunden-Id",
    "InventoryType": "Inventar-Typ",
    "DynamicType": "Dynamischer Typ",
    "ConfigAmount": "Betrag Konfiguration",
    "StockCount": "Bestandszählung",
    "AvelStock": "Avel Stock",
    "SoldCount": "Verkaufte Anzahl",
    "UnSoldCount": "Unverkaufte Anzahl",
    "UnPublishedCount": "Unveröffentlichte Anzahl",
    "SoldPercentage": "Verkaufter Prozentsatz",
    "UnSoldPercentage": "Unverkaufter Anteil in Prozent",
    "UnPublishedPercentage": "Unveröffentlichte Prozentzahl",
    "PinsCount": "Anzahl der Pins",
    "SoldPinsCount": "Anzahl verkaufter Pins",
    "UnSoldPinsCount": "Anzahl unverkaufter Pins",
    "UnPublishedPinsCount": "Anzahl unveröffentlichter Pins",
    "Batch": "Seriennummer",
    "LotNo": "Lot Nr.",
    "SoldPinsPercentage": "Verkaufte Pins Anteil in Prozent",
    "UnSoldPinsPercentage": "Prozentualer Anteil unverkaufter Pins",
    "SoldUnSoldPinsPercentage": "Prozentsatz der verkauften/nicht verkauften Stifte",
    "UnPublishedPinsPercentage": "Prozentualer Anteil unveröffentlichter Pins",
    "Actions": "Aktionen",
    "Invoices": "Rechnungen",
    "SNo": "Seriennummer",
    "FlixBus": "FlixBus",
    "OneWay": "Hinfahrt",
    "RoundTrip": "Hin- und Rückfahrt",
    "Adult": "Erwachsene",
    "For15Years": "seit 15 Jahren",
    "Children": "Kinder",
    "ZeroTo14Years": "0 bis 14 Jahre",
    "Bikes": "Fahrräder",
    "BikeHelp": "E-Bikes sind nicht erlaubt",
    "LoginRequests": "Loginfreigabe",
    "AuthStatus": "Genehmigungsstatus",
    "Approve": "Genehmigt",
    "CallAdmin": "Administrator anrufen",
    "Terminal": "Terminal",
    "ActivateTerminal": "Aktives Terminal",
    "SendTerminalDetails": "Sende Terminaldaten",
    "Domain": "Domain",
    "SearchCustomer": "Suche Kunde",
    "EditSupplier": "Lieferanten Bearbeiten",
    "SupplierEditTag": "Bearbeiten Sie den Lieferanten, um Produkte zu kaufen",
    "PinNumber": "Pin Nummer",
    "Hotline": "Hotline",
    "DateAndTime": "Datum und Uhrzeit",
    "CellCredit": "Handyguthaben",
    "CellCurrency": "Handyguthaben Währung",
    "Serial": "Seriennummer",
    "CardActivatedSuccessfully": "Karte wurde erfolgreich aktiviert",
    "SellingPrice": "Verkaufspreis",
    "BookingNumber": "Buchungsnummer",
    "TicketPrice": "Ticketpreis",
    "Departure": "Abfahrt",
    "Arrival": "Ankunft",
    "Adults": "Erwachsene",
    "Bicycle": "Fahrrad",
    "PassengerInformation": "Passagierinformationen",
    "Description": "Beschreibung",
    "LineDirection": "Linienrichtung",
    "InfoBlocks": "Infoblöcke",
    "Devices": "Geräte",
    "SNO": "Nummer",
    "DeviceTag": "Geräte Etikett",
    "DeviceSerialNo": "Seriennummer",
    "DeviceStatus": "Status",
    "Assigned": "Zugewiesen",
    "AddDevice": "Gerät hinzufügen",
    "UpdateDevice": "Gerät aktualisieren",
    "DeviceLastUpdated": "Letzte Aktualisierung",
    "Staff": "Mitarbeiter",
    "Invoice": "Rechnung",
    "ChangePassword": "Passwort ändern",
    "Flag": "Flagge",
    "OldCreditLimit": "Alte Kreditgrenze",
    "OldBalanceTrigger": "Alte Guthaben Auslöser",
    "OldBalanceTopUp": "Alte Guthaben aufladen",
    "AlternativeMobiles": "Alternative Mobilnummer",
    "AlternativeMobile1": "Alternative Mobilnummer 1",
    "AlternativeMobile2": "Alternative Mobilnummer 2",
    "TotalCredit": "Gesamtkredit",
    "TotalCash": "Gesamtzahlung",
    "TotalSales": "Gesamtumsatz",
    "TotalBalance": "Gesamtguthaben",
    "BuyingPrice": "Einkaufspreis",
    "MobileNo": "Mobilfunknummer",
    "Hello": "Hallo",
    "BackToHome": "zurück zur Startseite",
    "WelcomeBackLoginText": "Willkommen zurück",
    "Login": "Login",
    "OTP": "Zugangcode",
    "DidNotGetOTP": "Zugangcode nicht erhalten?",
    "ClickForAdminApproval": "Klicken Sie hier für Adminbestätigung",
    "Verify": "Verifizierung",
    "ReferenceCode": "Referenznummer",
    "CheckApprovalStatus": "Überprüfe Freigabestatus",
    "Search": "Suche",
    "FromCity": "vom Stadt",
    "ToCity": "zur Stadt",
    "Return": "zurück",
    "Remove": "Entfernen",
    "Seat": "Sitzplatz",
    "Reserve": "reservieren",
    "PassengerDetails": "Passagierinformationen",
    "DOB": "Geburtstag",
    "YourOrder": "Ihre Bestellung",
    "Total": "Gesamt",
    "inclVAT": "(inkl. MwSt.)",
    "EnterVoucher": "Gutschein eintippen",
    "Voucher": "Gutschein",
    "ProceedToPayment": "Fortfahren zur Zahlung",
    "SelectQuantity": "Anzahl wählen",
    "SelectDenomination": "Wert wählen",
    "Region": "Region",
    "AmountReceive": "Betrag erhalten",
    "Trip": "Ausflug",
    "ContactDetails": "Kontaktdaten",
    "FromDate": "vom Datum",
    "ToDate": "bis Datum",
    "SelectUser": "Benutzer auswählen",
    "TotalSaleAmount": "Umsatz",
    "TotalSaleProfit": "Gewinn",
    "ForecastProfit": "VL. Gewinn",
    "TotalSalePins": "Anzahl Verkaufte Pins",
    "Transactions": "Transaktionen",
    "Action": "Aktion",
    "Notes": "Anmerkung",
    "YES": "Ja",
    "NO": "Nein",
    "CreditHistory": "Kredit Verlauf",
    "CreditLedger": "Kreditbuchung",
    "Referer": "Referenz",
    "BalanceType": "Guthaben-Typ",
    "EnableWebSupport": "Aktiviere Web Support",
    "OK": "Ok",
    "AddedCustomerSuccessfully": "Kunde erfolgreich hinzugefügt",
    "Previous": "vorher",
    "Next": "nachher",
    "SelectCustomer": "Kunde auswählen",
    "Preview": "Vorschau",
    "All": "Alle",
    "Changes": "Änderungen",
    "LastActive": "zuletzt Aktiv",
    "UnAssignedDevices": "Nicht zugewiesene Geräte",
    "Assign": "Zuweisen",
    "UnAssign": "Trennen",
    "AssignedDevices": "Zugewiesene Geräte",
    "Active": "Aktiv",
    "SelectDiscountSlab": "Selektiere Rabattliste",
    "UpdateDiscounts": "Provision aktualisieren",
    "SearchProduct": "Suche Produkt",
    "AddBatch": "Seriennummer hinzufügen",
    "Invalid": "Ungültig",
    "pins": "Pins",
    "Valid": "Gültig",
    "NoPinsToImported": "Keine Pins zum importieren",
    "LoadMore": "mehr laden",
    "SoldPins": "Verkaufte Pins",
    "Published": "Veröffentlicht",
    "Unpublished": "Unveröffentlicht",
    "CurrentBalance": "Akutelle Guthaben",
    "Credits": "Kredite",
    "OpeningBalance": "Start Guthaben",
    "ClosingBalance": "End Guthaben",
    "ProductConfig": "Produktkonfiguration",
    "UppercaseLetter": "Großbuchstabe",
    "LowercaseLetter": "Kleinbuchstabe",
    "Number": "Nummer",
    "OneSymbol": "1 Symbol (!, #, $, %, &, @, *, ?)",
    "EightCharacters": "8 Zeichen",
    "AssignProducts": "Produkte zuweisen",
    "Discounts": "Rabatte",
    "Error": "Error",
    "Retry": "Wiederholen",
    "Logout": "Ausloggen",
    "PageNotFound": "Seite nicht gefunden",
    "InternalServerError": "interner Server fehler",
    "NotAuthorized": "Sie sind nicht berechtigt",
    "Settings": "Einstellungen",
    "ShowPrintPopUp": "Druckvorschau anzeigen",
    "MergeReceipts": "Belege zusammenführen",
    "Support": "Support",
    "MobileNumber": "Mobilenummer",
    "EditUser": "Benutzer bearbeiten",
    "EditStaff": "Mitarbeiter bearbeiten",
    "Manager": "Manager",
    "AssignStaff": "Mitarbeiter zuweisen",
    "StaffHistory": "Mitarbeitergeschichte",
    "Operation": "Betrieb",
    "Favourite": "Favoriten",
    "SalePrice": "Verkaufspreis",
    "Reclaim": "Reklamieren",
    "Reclamation": "Reklamation",
    "Reclamations": "Reklamationen",
    "ReclaimRequests": "Reklamationanfrage",
    "ReclamationID": "Reklamation ID",
    "LastUpdated": "zuletzt aktualisiert",
    "Accept": "Aktzeptieren",
    "Reject": "Ablehnung",
    "Rejected": "Ablehnung",
    "IssueCreditNote": "Gutschrift",
    "InProgress": "In Bearbeitung",
    "InReview": "Übersicht",
    "New": "Neu",
    "ReclamationStatus": "Reklamationsstatus",
    "ReclaimMessage": "Reklamation-Nachricht",
    "TransactionDate": "Transaktion Datum",
    "Created": "Erstellt",
    "RejectReason": "Ablehnungsgrund",
    "Reason": "Grund",
    "StatusColor": "Status Farbe",
    "RequestedUser": "Angeforderter Benutzer",
    "ReclaimedAfter": "Gutschriftsanfrage",
    "Favourites": "Favoriten",
    "Clear": "Löschen",
    "CashCard": "Cashkarte",
    "GiftCard": "Gutscheinkarten",
    "Approved": "Genehmigt",
    "Self": "Selbst",
    "OverdueDays": "fällige Tage",
    "YesterdaySales": "gestriger Verkauf",
    "LastCashPaid": "Letzte Barzahlung",
    "EditFavourites": "Favoriten bearbeiten",
    "OverdueAmount": "fälliger Betrag",
    "Overdue": "Überfällig",
    "OverdueProjection": "Überfällige Projektion",
    "Remark": "Bemerkung",
    "UsedIn": "benutzt in",
    "Days": "Tagen",
    "AmountUsedInProjection": "verwendeter Betrag",
    "GenerateInvoice": "Rechnung erstellen",
    "GenerateCreditInvoice": "Kreditrechnung erstellen",
    "GenerateNetInvoice": "Netzrechnung erstellen",
    "Tax": "MwSt.",
    "Generate": "Generieren",
    "InvoiceDate": "Rechnungsdatum",
    "Net": "Netz",
    "TotalBuyingAmount": "Gesamteinkauf",
    "UpdateProductImage": "Produktimage aktualisieren",
    "SoldDate": "Verkaufsdatum",
    "Service": "Bedienung",
    "GenerateInvoices": "Rechnungen erstellen",
    "GrossInvoiceId": "Bruttorechnungs-ID",
    "NetInvoiceId": "Nettorechnungs-ID",
    "Gross": "Rechnung",
    "MyInvoices": "Meine Rechnungen",
    "GeneratedInvoices": "Generierte Rechnungen",
    "InvoiceNo": "Rechnungsnummer",
    "CreditNoteNo": "Gutschriftsnummer",
    "InvoiceValue": "Rechnungswert",
    "CreditNoteValue": "Gutschriftwert",
    "CreditNote": "Gutschrift",
    "Response": "Antwort",
    "CreditNoteId": "Gutschein-ID",
    "GrossDiscount": "Bruttorabatt",
    "NoCustomerForDates": "Kunden für den ausgewählten Zeitraum",
    "SelectTopUpPaymentMode": "Wählen Sie die Auflade-Zahlungsmodi aus",
    "ResellerName": "Verkäufername",
    "APIName": "API Name",
    "LastInvoiceDate": "Letztes Rechnungsdatum",
    "LastInvoiceFromDate": "Letzte Rechnung ab Datum",
    "LastInvoiceToDate": "Letzte Rechnung bis Heute",
    "LastInvoiceValue": "Letzter Rechnungswert",
    "LastGrossValue": "Letzter Bruttowert",
    "LastCreditNote": "Letzte Gutschrift",
    "ApprovedBy": "Genehmigt durch",
    "ApprovedAt": "Genehmigt am",
    "RequestedAt": "Angefordert am",
    "BatchNo": "Seriennummer",
    "AssignedTo": "Zugewiesen am",
    "AssignedAt": "Zugewiesen am",
    "BatchSearch": "Suche Seriennummer",
    "UnAssignedStaff": "Nicht zugewiesenes Personal",
    "AssignedStaff": "Zugewiesenes Personal",
    "DevicesAssignedToYou": "Ihnen zugewiesene Geräte",
    "AssignDevices": "Geräte zuweisen",
    "View": "Ansicht",
    "ResellerProfit": "MARGE",
    "SellingPermission": "Verkaufserlaubnis",
    "ClearAll": "Alles löschen",
    "HRBNr": "HRB Nr.",
    "IBan": "IBAN",
    "BIC": "BIC",
    "Director": "Direktor",
    "EditGiftCard": "Gutscheinkarte bearbeiten",
    "TopUpHistory": "Aufladungsbericht",
    "AddTopUp": "Aufladen",
    "TopUpReport": "Aufladungsbericht",
    "SelectProduct": "Produkt auswählen",
    "SelectPins": "Pins auswählen",
    "EnableSales": "Verkauf aktivieren",
    "TerminalStatus": "Terminalstatus",
    "TerminalActivated": "Terminal aktiviert",
    "TerminalInActive": "Terminal Inaktiv",
    "BuyingDiscounts": "Kaufrabatt",
    "InvoiceHeaderImage": "Rechnungskopfbild",
    "InvoiceFooterImage": "Rechnungsfußbild",
    "SelectImage": "Bild auswählen",
    "ProductDiscountsList": "Produktrabattliste",
    "InventorySearch": "Inventarsuche",
    "BuyingDate": "Kaufdatum",
    "SellingDate": "Verkaufsdatum",
    "SubOrganisation": "Unterorganisation",
    "OR": "Oder",
    "SelectCustomers": "Kunden auswählen",
    "Selected": "Ausgewählt",
    "EpayAPIDiscount": "Epay API Rabatt",
    "APIBuyingDiscount": "API-Kaufrabatt",
    "PinAndBatchSearch": "Pin- und Batch-Suche",
    "CustomerReadOnly": "Kunde schreibgeschützt",
    "CustomerFullPermission": "Volle Erlaubnis des Kunden",
    "AutoCreditHistory": "Automatische Bonitätshistorie",
    "NoCustomers": "Keine Kunden",
    "LoginLogoImage": "Login Logo Bild",
    "InvoiceLogo": "Rechnungslogo",
    "SMSSenderID": "SMS-Absender-ID",
    "MultipleDiscounts": "Mehrere Rabatte",
    "ConfirmMobileNumber": "Handynummer bestätigen",
    "MobileNotMatched": "Handynummer nicht abgestimmt",
    "FillMobileError": "Mobilen Fehler einfügen",
    "PoolName": "Poolname",
    "NoOfProducts": "Anzahl der Produkte",
    "NoOfPins": "Anzahl der Pins",
    "UnSoldPins": "Nicht verkaufte Pins",
    "AddPool": "Pool hinzufügen",
    "AddNewPool": "Neuen Pool hinzufügen",
    "CallingCardsPoolUpdate": "Aktualisierung der Callingkarten",
    "AddPoolInventory": "Poolinventar hinzufügen",
    "CallingCardsPool": "Callingkarten",
    "CallingCards": "Callingkarten",
    "CallingCard": "Callingkarten",
    "PinDownload": "Pin herunterladen",
    "DownloadCSV": "CSV herunterladen",
    "DownloadTXT": "TXT herrunterladen",
    "ShowDetails": "Zeige Detail",
    "HideDetails": "Details ausblenden",
    "UserStatus": "Benutzerstatus",
    "InActive": "Inaktiv",
    "Registrations": "Anmeldungen",
    "Accepted": "Akzeptiert",
    "ShopType": "Shop-Typ",
    "IP": "IP",
    "UpdatedBy": "Aktualisiert von",
    "SetUsername": "Benutzernamen festlegen",
    "VerifyOTP": "OTP überprüfen",
    "SetPassword": "Passwort festlegen",
    "TerminalLimit": "Terminal Limit",
    "Limit": "Grenze",
    "UpdateShopTimings": "Geschäftszeiten",
    "ShopTimings": "Öffnungszeiten",
    "StartTime": "Startzeit",
    "CloseTime": "Schließzeit",
    "RequestedCustomerId": "Angeforderte Kundennummer",
    "Reseller": "Wiederverkäufer",
    "ResellerCustomerId": "Wiederverkäufer-Kundennummer",
    "FirstPurchasedCustomer": "Erster gekaufter Kunde",
    "FirstPurchasedCustomerId": "Erste gekaufte Kundennummer",
    "FirstPurchasedCustomerTime": "Erste gekaufte Kundenzeit",
    "SupplierDiscount": "Lieferantenrabatt",
    "Holidays": "Urlaub",
    "BankName": "Bank Institut",
    "Lunch": "Pause",
    "ResoldToCustomer": "An Kunden weiterverkauft",
    "ResoldToCustomerId": "Weiterverkauft an Kundennummer",
    "ResoldAt": "Weiterverkauft bei",
    "Kredit": "Kredit",
    "Welcome": "Herzlich Willkommen",
    "Info": "Info",
    "DownloadTeamViewer": "TeamViewer herunterladen",
    "GenerateReclamationInvoice": "Reklamationsrechnung erstellen",
    "GeneratedReclamationInvoices": "Generierte Reklamationsrechnungen",
    "ReclamationInvoices": "Reklamationsrechnungen",
    "MyReclamationInvoices": "Meine Reklamationsrechnungen",
    "MultiTopUp": "Mehrfachaufladung",
    "EPayTerminals": "EPay-Terminals",
    "TerminalId": "Terminal-ID",
    "ShopName": "Shop Name",
    "CheckStatus": "Status überprüfen",
    "Check": "Prüfen",
    "POSDeviceAPP": "Druckertreiber herunterladen",
    "enableLoginOTP": "Login-OTP aktivieren",
    "disableLoginOTP": "Login-OTP deaktivieren",
    "disabledLoginOTP": "OTP-Anmeldung deaktiviert",
    "otp": "OTP",
    "DirectCreditNote": "Direktkredit Hinweis",
    "transactionCheck": "Transaktionsprüfung",
    "ZReport": "Z-Bericht",
    "CardName": "Kartenname",
    "PrintDate": "Ausdruckdatum",
    "Employee": "Mitarbeiter",
    "DateOfSale": "Verkaufsdatum",
    "Price": "Preis",
    "confirmRejection": "Sind Sie sicher, dass Sie ablehnen möchten",
    "tripFullyBooked": "Fahrt ist leider ausgebucht",
    "AmountCreditLimitError": "Kreditlimit erreicht",
    "IPAddress": "IP Adresse",
    "DownloadExcel": "XLSX herunterladen",
    "Resend": "Erneut senden",
    "ResendEmail": "E-Mail senden",
    "ResendEmailConfirm": "Möchten Sie wirklich noch einmal E-Mail senden?",
    "PinBatchSearch": "Pin-/Batch-Suche",
    "ViewAll": "Ausklappen",
    "ShowLess": "Einklappen",
    "Sort": "sortieren",
    "SelectPlanType": "Tariftyp auswählen",
    "PreviousReclaim": "Vorherige Rückforderung",
    "SameBatchPinReclaimed": "Dieselbe Charge/Pin wird zurückgefordert",
    "UpdateRemark": "Bemerkung",
    "MaskDiscount": "Maskenrabatt",
    "ShowDiscount": "Rabatt anzeigen",
    "Yes": "Ja",
    "No": "Nein",
    "ShowShopDetailsOnPrint": "Laden Anschrift ausblenden in ausdrück",
    "AccessType": "Zugangsart",
    "LoginRequestedAt": "Anmeldung erbeten unter",
    "Hardware": "Zubehör",
    "HardwareSalesReport": "Zubehör Verkaufsbericht",
    "Purchase": "kaufen",
  },
  "fr": {
    "Products": "Des produits",
    "Product": "Produit",
    "ProductValue": "Valeur du produit",
    "Quantity": "Quantité",
    "Denomination": "Dénomination",
    "ViewProducts": "Voir les produits",
    "ViewProduct": "Voir le produit",
    "AddProducts": "Ajouter des produits",
    "AddProduct": "Ajouter un produit",
    "AddNewProduct": "Ajouter un nouveau produit",
    "Instructions": "Instructions",
    "CustomerCareNumber": "Numéro de service client",
    "ProductType": "type de produit",
    "TotalDenominations": "Dénominations",
    "Details": "Des détails",
    "PinLength": "Longueur de la Pin",
    "Pin": "Pin",
    "SoldBy": "Vendu par",
    "SoldById": "Vendu par pièce d'identité",
    "SoldTime": "Temps de vente",
    "ProductName": "Nom du produit",
    "SaveAddDenominations": "Enregistrer et ajouter des dénominations",
    "AddProductImage": "Ajouter une image de produit",
    "ProductImage": "Image du produit",
    "AddDenomination": "Ajouter une dénomination",
    "Done": "Terminé",
    "Add": "Ajouter",
    "Delete": "Effacer",
    "Update": "Mettre à jour",
    "Cancel": "Annuler",
    "Amount": "Montant",
    "OldBalance": "Ancien solde",
    "NewBalance": "Nouvel solde",
    "Paid": "Payé",
    "SellerProfit": "Bénéfice du vendeur",
    "ProductID": "Identifiant du produit",
    "ID": "identifiant",
    "Image": "Image",
    "Company": "Société",
    "NoData": "Données non disponibles",
    "NoServicesAvailable": "Aucun service disponible",
    "Contact": "Contact",
    "AccountStatus": "Statut du compte",
    "ErrorData": "Erreur lors de l'obtention des données",
    "Edit": "Éditer",
    "Activate": "Activer",
    "Deactivate": "Désactiver",
    "Suppliers": "Fournisseurs",
    "Supplier": "Le fournisseur",
    "SupplierCreateTag": "Créer un fournisseur pour acheter des produits",
    "AddNewSupplier": "Ajouter un nouveau fournisseur",
    "ViewSuppliers": "Voir les fournisseurs",
    "ViewSupplier": "Voir le fournisseur",
    "AddSupplier": "Ajouter un fournisseur",
    "SupplierCompany": "Fournisseur Société",
    "SupplierName": "Nom du fournisseur",
    "FullName": "Nom et prénom",
    "FullAddress": "Adresse complète",
    "EmailId": "E-Mail Adresse",
    "Mobile": "Mobile Telephone",
    "StNr": "TVA No.",
    "Telephone": "Téléphone",
    "UstIdnr": "Ust.Id nr",
    "City": "Ville",
    "Country": "Pays",
    "PostalCode": "code postal",
    "Remarks": "Remarques",
    "SaveAndPublish": "Enregistrer et publier",
    "TotalPins": "Nombre total d' pin",
    "CreatedDate": "Date de création",
    "BuyingDiscount": "Remise d'achat",
    "PinSeq": "Séquence de Pin",
    "BatchSeq": "Séquence de lot",
    "Delimiter": "Délimiteur",
    "Discount": "Remise",
    "Inventory": "Inventaire",
    "InventoryDetails": "Détails de l'inventaire",
    "AddInventory": "Ajouter un inventaire",
    "ViewInventory": "Voir l'inventaire",
    "UploadCSVFileOnly": "Téléchargez uniquement le fichier avec l'extension .csv",
    "AddGiftCardBatch": "Ajouter un lot de cartes-cadeaux",
    "DivisionDiscount": "Remise divisionnaire",
    "AssignmentsAndDiscount": "Missions et remise",
    "AddCustomer": "Ajouter un client",
    "EditCustomer": "Modifier le client",
    "AddNewCustomer": "Ajouter un nouveau client",
    "Customer": "Client",
    "BalanceTopUp": "Recharge de solde",
    "BalanceTrigger": "Déclencheur d'équilibre",
    "CreditLimit": "Limite de crédit",
    "CustomerName": "Nom du client",
    "CreditBalance": "Solde créditeur",
    "CustomerID": "Client ID",
    "OrganisationType": "Type d'organisation",
    "CustomerDetails": "Détails du client",
    "Customers": "Les clients",
    "ViewCustomer": "Afficher le client",
    "ViewCustomers": "Afficher les clients",
    "FirstName": "Prénom",
    "LastName": "Nom de famille",
    "HouseNo": "Numéro de maison",
    "Address": "Adresse",
    "Landline": "Téléphone fixe",
    "Username": "Nom d'utilisateur",
    "Template": "Modèle",
    "UsernameNotAvailable": "Nom d'utilisateur indisponible",
    "Password": "Mot de passe",
    "ConfirmPassword": "Confirmez le mot de passe",
    "SubDomain": "Sous-domaine",
    "CustomerType": "Type de client",
    "Name": "Nom",
    "Reference": "Référence",
    "ActivatedOn": "Activé le",
    "Credit": "Crédit",
    "NewCredit": "Nouveau crédit",
    "OldCredit": "Ancien crédit",
    "TopUp": "Recharger",
    "Balance": "solde",
    "SoftPins": "SoftPins",
    "EPay": "EPay",
    "Sales": "Ventes",
    "SalesCount": "Nombre de ventes",
    "Postal": "Poste",
    "DiscountSlab": "Remise Dalle",
    "DiscountSlabError": "Veuillez sélectionner une dalle de remise",
    "LastPurchase": "Dernier achat",
    "PurchasedDate": "Date d’achat",
    "BeforeBalance": "Avant le solde",
    "Value": "Valeur",
    "Sold": "Vendu",
    "UnSold": "UnSold",
    "DateTime": "Heure de la date",
    "AfterBalance": "Après équilibre",
    "BeforeCredit": "Avant crédit",
    "AfterCredit": "Après le crédit",
    "LastPaidAmount": "Dernier montant payé",
    "Filter": "Filtre",
    "ClearAllFilters": "Effacer tous les filtres",
    "PAD": "Remises sur l'attribution de produits",
    "AddCredit": "Ajouter crédit",
    "AutoCredit": "Crédit auto",
    "CreditBalanceTransaction": "Opération de crédit/solde",
    "Date": "Date",
    "GrandTotal": "Total général",
    "Cash": "Espèces",
    "PaymentType": "Type de paiement",
    "TransactionType": "Type de transaction",
    "TransactionMode": "Mode Transaction",
    "PaymentMode": "Mode de paiement",
    "StandingCredit": "Crédit permanent",
    "StandingBalance": "Équilibre permanent",
    "ForecastAmountneed": "Montant prévu nécessaire",
    "CreditRemark": "Remarque sur le crédit",
    "Users": "Utilisateurs",
    "ViewUsers": "Afficher les utilisateurs",
    "User": "Utilisateur",
    "UserName": "Nom d’utilisateur",
    "UserId": "Code d’utilisateur",
    "AddUser": "Ajouter un utilisateur",
    "AddNewUser": "Ajouter un nouvel utilisateur",
    "JobRole": "Poste",
    "Privileges": "Privilèges",
    "SaveUser": "Enregistrer l’utilisateur",
    "UpdateUser": "Mettre à jour l’utilisateur",
    "AddNewStaff": "Ajouter une nouvelle portée",
    "AddNewStaffTag": "Créer de nouveaux employés ayant un accès limité aux modules",
    "CashCards": "Cartes de paiement",
    "TotalBill": "Total des factures",
    "Profit": "Profit",
    "Print": "Imprimer",
    "Close": "Fermer",
    "PlanId": "Numéro de plan",
    "PlanName": "Nom du plan",
    "DiscountSlabs": "Plaques de réduction",
    "ProductsDiscount": "Rabais sur les produits",
    "ErrorDiscountSlabs": "Erreur de récupération des plaques de réduction",
    "DiscountPlanName": "Nom du plan de rabais",
    "AddDiscountSlab": "Ajouter une plaque de réduction",
    "NoDiscountSlabs": "Aucune plaque de réduction",
    "ViewDiscountSlabs": "Afficher la plaque de réduction",
    "DenominationAmount": "Montant de la dénomination",
    "BatchNumber": "Numéro de lot",
    "DiscountAmountError": "La valeur d’actualisation doit être non négative",
    "AmountError": "Veuillez remplir le montant",
    "DenominationAmountError": "Veuillez indiquer le montant de la valeur nominale ou le montant doit être supérieur à 0.",
    "DynamicProducts": "Produits dynamiques",
    "DynamicProductsDiscounts": "Rabais sur les produits dynamiques",
    "SoftApiCard": "Carte SoftApi",
    "EPayApiId": "EPay Api Id",
    "EPayApiEAN": "EAN EPay",
    "EPayApiName": "Nom de l’API EPay",
    "Submit": "Soumettre",
    "Priority": "Priorité",
    "GiftCards": "Cartes-cadeaux",
    "EnterCardNoLabel": "Veuillez entrer le numéro de la carte",
    "EnterEANLabel": "Veuillez entrer le code EAN",
    "EnterBachNo": "Entrez le numéro de lot.",
    "DirectRecharge": "Recharge directe",
    "DirectRechargeHistory": "Historique de recharge directe",
    "EnterMobileNo": "Veuillez entrer le numéro de téléphone mobile",
    "EnterPanNumber": "Veuillez entrer le numéro de l’écran",
    "TransactionID": "ID de transaction",
    "Provider": "Fournisseur",
    "Status": "État",
    "TransactionSuccessful": "Transaction réussie",
    "TransactionFailed": "Transaction échouée",
    "TransactionPending": "Transaction en attente",
    "Buy": "Acheter",
    "Recharge": "Recharge",
    "InternationalTopUp": "International TopUp",
    "GetProviders": "Obtenir des fournisseurs",
    "DirectRechargeDiscount": "Remise recharge directe",
    "InternationalTopUpDiscount": "Remise de recharge internationale",
    "FlixBusDiscount": "Rabais FlixBus",
    "DynamicDiscounts": "Remises dynamiques",
    "Save": "Enregistrer",
    "RechargeSuccessful": "Recharge réussie",
    "SalesReports": "Rapports sur les ventes",
    "PaymentLedger": "Registre des paiements",
    "RechargeSummaryReport": "Recharge Summary Report",
    "DynamicProductsLog": "DynamicProductsLog",
    "Download": "Télécharger",
    "SalesReport": "Rapport des ventes",
    "StockReport": "Rapport de stock",
    "StockReportDetails": "Stock Report Details",
    "InventoryLogReport": "Inventory Log Report",
    "BatchSummaryReport": "Batch Summary Report",
    "Reports": "Rapports",
    "Organisation": "Organisation",
    "CustomerId": "N ° de client",
    "InventoryType": "Type d'inventaire",
    "DynamicType": "Type Dynamique",
    "ConfigAmount": "Montant Config",
    "StockCount": "Décompte des stocks",
    "AvelStock": "Avel Stock",
    "SoldCount": "Nombre de ventes",
    "UnSoldCount": "Nombre d'invendus",
    "UnPublishedCount": "Nombre non publié",
    "SoldPercentage": "Pourcentage vendu",
    "UnSoldPercentage": "Pourcentage des invendus",
    "UnPublishedPercentage": "Pourcentage non publié",
    "PinsCount": "Nombre de broches",
    "SoldPinsCount": "VenduNombre de broches",
    "UnSoldPinsCount": "Nombre de broches non vendues",
    "UnPublishedPinsCount": "Nombre de broches non publiées",
    "Batch": "Grouper",
    "LotNo": "LotNon",
    "SoldPinsPercentage": "Vendu Épingles Pourcentage",
    "UnSoldPinsPercentage": "Pourcentage d'épingles non vendues",
    "SoldUnSoldPinsPercentage": "VenduNon Vendu Épingles Pourcentage",
    "UnPublishedPinsPercentage": "Pourcentage d'épingles non publiées",
    "Actions": "Actions",
    "Invoices": "Factures",
    "SNo": "S No",
    "FlixBus": "FlixBus",
    "OneWay": "Sens Unique",
    "RoundTrip": "Aller-retour",
    "Adult": "Adulte",
    "For15Years": "Pendant 15 ans",
    "Children": "Enfants",
    "ZeroTo14Years": "ZeroTo14Years",
    "Bikes": "vélos",
    "BikeHelp": "Aide à vélo",
    "LoginRequests": "Demandes de connexion",
    "AuthStatus": "AuthStatus",
    "Approve": "Approuver",
    "CallAdmin": "Administrateur d'appels",
    "Terminal": "Terminal",
    "ActivateTerminal": "Activer Terminal",
    "SendTerminalDetails": "EnvoyerTerminal Détails",
    "Domain": "Domaine",
    "SearchCustomer": "Rechercher un client",
    "EditSupplier": "Modifier le fournisseur",
    "SupplierEditTag": "Fournisseur Modifier Tag",
    "PinNumber": "Code PIN",
    "Hotline": "Hotline",
    "DateAndTime": "Date et l'heure",
    "CellCredit": "Crédit Cellulaire",
    "CellCurrency": "Devise Cellule",
    "Serial": "Série",
    "CardActivatedSuccessfully": "Carte Activée avec succès",
    "SellingPrice": "Prix ​​de vente",
    "BookingNumber": "Numéro de réservation",
    "TicketPrice": "Prix ​​du billet",
    "Departure": "Départ",
    "Arrival": "Arrivée",
    "Adults": "Adultes",
    "Bicycle": "Bicyclette",
    "PassengerInformation": "Informations sur les passagers",
    "Description": "Description",
    "LineDirection": "Direction de ligne",
    "InfoBlocks": "Blocs d’information",
    "Devices": "Dispositifs",
    "SNO": "S.No",
    "DeviceTag": "Étiquette de dispositif",
    "DeviceSerialNo": "No de série",
    "DeviceStatus": "État",
    "Assigned": "Attribué",
    "AddDevice": "Ajouter un dispositif",
    "UpdateDevice": "Mettre à jour l'appareil",
    "DeviceLastUpdated": "Dernière mise à jour",
    "Staff": "Personnel",
    "Invoice": "Facture",
    "ChangePassword": "Modifier le mot de passe",
    "Flag": "Drapeau",
    "OldCreditLimit": "Ancienne limite de crédit",
    "OldBalanceTrigger": "Ancien déclencheur d’équilibre",
    "OldBalanceTopUp": "Old Balance TopUp",
    "AlternativeMobiles": "Autres services mobiles",
    "AlternativeMobile1": "Alternative Mobile 1",
    "AlternativeMobile2": "Alternative Mobile 2",
    "TotalCredit": "Crédit total",
    "TotalCash": "Total des liquidités",
    "TotalSales": "Total des ventes",
    "TotalBalance": "Solde total",
    "BuyingPrice": "Prix d’achat",
    "MobileNo": "Mobile No",
    "Hello": "Bonjour",
    "BackToHome": "Retour à la maison",
    "WelcomeBackLoginText": "Bon retour",
    "Login": "Ouverture de session",
    "OTP": "OTP",
    "DidNotGetOTP": "Vous n’avez pas eu d’OTP ?",
    "ClickForAdminApproval": "Cliquez ici pour obtenir l’approbation de l’administrateur.",
    "Verify": "Vérifier",
    "ReferenceCode": "Code de référence",
    "CheckApprovalStatus": "Vérifier l’état d’approbation",
    "Search": "Recherche",
    "FromCity": "De la ville",
    "ToCity": "À la ville",
    "Return": "Retour",
    "Remove": "Supprimer",
    "Seat": "Siège",
    "Reserve": "Réserve",
    "PassengerDetails": "Renseignements sur les passagers",
    "DOB": "Date de naissance",
    "YourOrder": "Votre commande",
    "Total": "Total",
    "inclVAT": "(TVA incluse)",
    "EnterVoucher": "Entrer le bon",
    "Voucher": "Bon",
    "ProceedToPayment": "Procéder au paiement",
    "SelectQuantity": "Sélectionnez Quantité.",
    "SelectDenomination": "Sélectionnez Dénomination.",
    "Region": "Région",
    "AmountReceive": "Montant reçu",
    "Trip": "Voyage",
    "ContactDetails": "Coordonnées",
    "FromDate": "À partir de la date",
    "ToDate": "À ce jour",
    "SelectUser": "Sélectionner l’utilisateur",
    "TotalSaleAmount": "Montant total de la vente",
    "TotalSaleProfit": "Total des bénéfices de vente",
    "TotalSalePins": "Total des épinglettes de vente",
    "Transactions": "Opérations",
    "Action": "Action",
    "Notes": "Notes",
    "YES": "OUI",
    "NO": "NON",
    "CreditHistory": "Histoire de credit",
    "CreditLedger": "Grand livre de crédit",
    "Referer": "Référent",
    "BalanceType": "Type de solde",
    "EnableWebSupport": "Activer l'assistance Web",
    "OK": "d'accord",
    "AddedCustomerSuccessfully": "Client ajouté avec succès",
    "Previous": "Précédent",
    "Next": "Prochain",
    "SelectCustomer": "Sélectionnez le client",
    "Preview": "Aperçu",
    "All": "Tous",
    "Changes": "Changements",
    "LastActive": "Dernière activité",
    "UnAssignedDevices": "Appareils non signés",
    "Assign": "Attribuer",
    "UnAssign": "UnAssign",
    "AssignedDevices": "Dispositifs assignés",
    "Active": "Actif",
    "SelectDiscountSlab": "Sélectionnez la dalle de remise",
    "UpdateDiscounts": "Mettre à jour les remises",
    "SearchProduct": "Produit de recherche",
    "AddBatch": "Ajouter un lot",
    "Invalid": "Invalide",
    "pins": "épingles",
    "Valid": "Valide",
    "NoPinsToImported": "Aucune épingle à importer",
    "LoadMore": "Charger plus",
    "SoldPins": "Épinglettes vendues",
    "Published": "Publié",
    "Unpublished": "Non publié",
    "CurrentBalance": "Solde courant",
    "Credits": "Crédits",
    "OpeningBalance": "Solde d’ouverture",
    "ClosingBalance": "Solde de clôture",
    "ProductConfig": "Configuration du produit",
    "UppercaseLetter": "Lettre en majuscules",
    "LowercaseLetter": "Lettre en minuscules",
    "Number": "Nombre",
    "OneSymbol": "1 symbole (!, #, $, %, &, @, *, ?)",
    "EightCharacters": "8 caractères",
    "AssignProducts": "Attribuer des produits",
    "Discounts": "Remises",
    "Error": "Erreur",
    "Retry": "Réessayer",
    "Logout": "Déconnexion",
    "PageNotFound": "Page introuvable",
    "InternalServerError": "Erreur du serveur interne",
    "NotAuthorized": "Vous n’êtes pas autorisé",
    "Settings": "Paramètres",
    "ShowPrintPopUp": "Afficher le PopUp d’impression",
    "MergeReceipts": "Fusionner les reçus",
    "Support": "Soutien",
    "MobileNumber": "Numéro de téléphone mobile",
    "EditUser": "Modifier l’utilisateur",
    "EditStaff": "Modifier le personnel",
    "Manager": "Gestionnaire",
    "AssignStaff": "Affecter du personnel",
    "StaffHistory": "Historique du personnel",
    "Operation": "Opération",
    "Favourite": "Favori",
    "SalePrice": "Prix de vente",
    "Reclaim": "Reclaim",
    "Reclamation": "Réclamation",
    "Reclamations": "Réclamations",
    "ReclaimRequests": "Demandes de récupération",
    "ReclamationID": "ID de remise en état",
    "LastUpdated": "Dernière mise à jour",
    "Accept": "Accepter",
    "Reject": "Rejeter",
    "Rejected": "Rejeté",
    "IssueCreditNote": "Émettre une note de crédit",
    "InProgress": "En cours",
    "InReview": "En cours d’examen",
    "New": "Nouveau",
    "ReclamationStatus": "Statut de Réclamation",
    "ReclaimMessage": "Message de récupération",
    "TransactionDate": "Date de l’opération",
    "Created": "Créé",
    "RejectReason": "Motif de rejet",
    "Reason": "Raison",
    "StatusColor": "Couleur d’état",
    "RequestedUser": "Utilisateur demandé",
    "ReclaimedAfter": "Récupéré après",
    "Favourites": "Favoris",
    "Clear": "efface",
    "CashCard": "Carte de paiement",
    "GiftCard": "Carte-cadeau",
    "Approved": "Approuvé",
    "Self": "Auto",
    "OverdueDays": "Jours de retard",
    "YesterdaySales": "Ventes d’hier",
    "LastCashPaid": "Dernier versement en espèces",
    "EditFavourites": "Modifier les favoris",
    "OverdueAmount": "Montant en souffrance",
    "Overdue": "En retard",
    "OverdueProjection": "Projection en souffrance",
    "Remark": "Remarque",
    "UsedIn": "Utilisé dans",
    "Days": "jours",
    "AmountUsedInProjection": "Montant utilisé dans la projection",
    "GenerateInvoice": "Générer une facture",
    "GenerateCreditInvoice": "Générer une facture de crédit",
    "GenerateNetInvoice": "Générer une facture nette",
    "Tax": "Impôt",
    "Generate": "Générer",
    "InvoiceDate": "Date de la facture",
    "Net": "Net",
    "TotalBuyingAmount": "Montant total des achats",
    "UpdateProductImage": "Mettre à jour l’image du produit",
    "SoldDate": "Date de vente",
    "Service": "Service",
    "GenerateInvoices": "Générer des factures",
    "GrossInvoiceId": "ID brut de la facture",
    "NetInvoiceId": "Numéro de facture net",
    "Gross": "Brut",
    "MyInvoices": "Mes factures",
    "GeneratedInvoices": "Factures générées",
    "InvoiceNo": "No de facture",
    "CreditNoteNo": "Note de crédit no",
    "InvoiceValue": "Valeur de la facture",
    "CreditNoteValue": "Valeur des billets de crédit",
    "CreditNote": "Note de crédit",
    "Response": "Réponse",
    "CreditNoteId": "ID de note de crédit",
    "GrossDiscount": "Escompte brut",
    "NoCustomerForDates": "Aucun client pour les dates sélectionnées",
    "SelectTopUpPaymentMode": "Sélectionnez les modes de paiement supplémentaires à donner.",
    "ResellerName": "Nom du revendeur",
    "APIName": "Nom de l’API",
    "LastInvoiceDate": "Date de la dernière facture",
    "LastInvoiceFromDate": "Dernière facture à partir de la date",
    "LastInvoiceToDate": "Dernière facture à ce jour",
    "LastInvoiceValue": "Valeur de la dernière facture",
    "LastGrossValue": "Dernière valeur brute",
    "LastCreditNote": "Dernière note de crédit",
    "ApprovedBy": "Approuvé par",
    "ApprovedAt": "Approuvé au",
    "RequestedAt": "Demandé à",
    "BatchNo": "No du lot",
    "AssignedTo": "Assigné à",
    "AssignedAt": "Affecté à",
    "BatchSearch": "Recherche par lots",
    "UnAssignedStaff": "Personnel non affecté",
    "AssignedStaff": "Personnel affecté",
    "DevicesAssignedToYou": "Dispositifs qui vous sont assignés",
    "AssignDevices": "Attribuer des dispositifs",
    "View": "Affichage",
    "ResellerProfit": "Profit du revendeur",
    "SellingPermission": "Autorisation de vente",
    "ClearAll": "Effacer tout",
    "HRBNr": "HRB Nr",
    "IBan": "IBAN",
    "BIC": "BIC",
    "Director": "Directeur",
    "EditGiftCard": "Modifier la carte-cadeau",
    "TopUpHistory": "Rapport TopUp",
    "AddTopUp": "Ajouter TopUp",
    "TopUpReport": "Rapport TopUp",
    "SelectProduct": "Sélectionner le produit",
    "SelectPins": "Sélectionnez Pins",
    "EnableSales": "Activer les ventes",
    "TerminalStatus": "Statut du terminal",
    "TerminalActivated": "Terminal activé",
    "TerminalInActive": "Terminal inactif",
    "BuyingDiscounts": "Remises d'achat",
    "InvoiceHeaderImage": "Image d’en-tête de facture",
    "InvoiceFooterImage": "Image du pied de page de la facture",
    "SelectImage": "Sélectionner l’image",
    "ProductDiscountsList": "Liste des remises sur les produits",
    "InventorySearch": "Recherche d’inventaire",
    "BuyingDate": "Date d’achat",
    "SellingDate": "Date de vente",
    "SubOrganisation": "Sous-organisation",
    "OR": "Ou",
    "SelectCustomers": "Sélectionner des clients",
    "Selected": "Sélectionné",
    "EpayAPIDiscount": "Epay API discount",
    "APIBuyingDiscount": "Remise d'achat d'API",
    "PinAndBatchSearch": "Pin et recherche de lots",
    "CustomerReadOnly": "Client en lecture seule",
    "CustomerFullPermission": "Autorisation complète du client",
    "AutoCreditHistory": "Historique de crédit automobile",
    "NoCustomers": "Aucun client",
    "LoginLogoImage": "Image du logo de connexion",
    "InvoiceLogo": "Logo de la facture",
    "SMSSenderID": "ID expéditeur SMS",
    "MultipleDiscounts": "Rabais multiples",
    "ConfirmMobileNumber": "Confirmer le numéro de téléphone mobile",
    "MobileNotMatched": "Mobile non apparié",
    "FillMobileError": "Remplir l’erreur mobile",
    "PoolName": "Nom du pool",
    "NoOfProducts": "Nombre de produits",
    "NoOfPins": "No des épingles",
    "UnSoldPins": "Épingles invendues",
    "AddPool": "Ajouter un bassin",
    "AddNewPool": "Ajouter un nouveau bassin",
    "CallingCardsPoolUpdate": "Mise à jour du pool de cartes d’appel",
    "AddPoolInventory": "Ajouter un inventaire de mise en commun",
    "CallingCardsPool": "Calling Cards Pool",
    "CallingCards": "Cartes d’appel",
    "CallingCard": "Cartes d’appel",
    "PinDownload": "Téléchargement de broches",
    "DownloadCSV": "Télécharger CSV",
    "DownloadTXT": "Télécharger TXT",
    "ShowDetails": "Afficher les détails",
    "HideDetails": "Masquer les détails",
    "UserStatus": "Statut de l’utilisateur",
    "InActive": "Inactif",
    "Registrations": "Inscriptions",
    "Accepted": "Accepté",
    "ShopType": "Type d’atelier",
    "IP": "IP",
    "UpdatedBy": "Mis à jour par",
    "SetUsername": "Définir le nom d’utilisateur",
    "VerifyOTP": "Vérifier le PFO",
    "SetPassword": "Définir le mot de passe",
    "TerminalLimit": "Limite terminale",
    "Limit": "Limite",
    "UpdateShopTimings": "Mettre à jour les horaires des ateliers",
    "ShopTimings": "Horaire des ateliers",
    "StartTime": "Heure de début",
    "CloseTime": "Heure de fermeture",
    "RequestedCustomerId": "Identifiant client demandé",
    "Reseller": "Revendeur",
    "ResellerCustomerId": "Identifiant client revendeur",
    "FirstPurchasedCustomer": "Premier client acheté",
    "FirstPurchasedCustomerId": "Numéro d’identification du premier client acheté",
    "FirstPurchasedCustomerTime": "Heure du premier achat par le client",
    "SupplierDiscount": "Remise fournisseur",
    "Holidays": "Fêtes",
    "BankName": "Nom de la banque",
    "Lunch": "Déjeuner",
    "ResoldToCustomer": "Revendu au client",
    "ResoldToCustomerId": "Revendu à CustomerId",
    "ResoldAt": "Revendu à",
    "Kredit": "Kredit",
    "Welcome": "Bienvenue",
    "Info": "Info",
    "DownloadTeamViewer": "Télécharger TeamViewer",
    "GenerateReclamationInvoice": "Générer une facture de remise en état",
    "GeneratedReclamationInvoices": "Factures de remise en état générées",
    "ReclamationInvoices": "Factures de réclamation",
    "MyReclamationInvoices": "Mes factures de réclamation",
    "MultiTopUp": "Recharge multiple",
    "EPayTerminals": "Terminaux EPay",
    "TerminalId": "Terminal ID",
    "ShopName": "Nom du magasin",
    "CheckStatus": "Vérifier l’état",
    "Check": "Vérifier",
    "POSDeviceAPP": "Télécharger les pilotes d’imprimante",
    "enableLoginOTP": "Activer la connexion OTP",
    "disableLoginOTP": "Désactiver la connexion OTP",
    "disabledLoginOTP": "Désactivation de la connexion OTP",
    "otp": "OTP",
    "DirectCreditNote": "DirectCreditNote",
    "transactionCheck": "Vérification des transactions",
    "ZReport": "Z Report",
    "CardName": "Nom de la carte",
    "PrintDate": "Date d’impression",
    "Employee": "Employé",
    "DateOfSale": "Date de la vente",
    "Price": "Prix",
    "confirmRejection": "Êtes-vous sûr de vouloir rejeter",
    "tripFullyBooked": "Ce voyage est complet.",
    "AmountCreditLimitError": "Limite de crédit atteinte",
    "IPAddress": "Adresse IP",
    "DownloadExcel": "Télécharger Excel",
    "Resend": "Renvoyer",
    "ResendEmail": "Renvoyer un courriel",
    "ResendEmailConfirm": "Êtes-vous sûr de vouloir envoyer de nouveau un courriel?",
    "PinBatchSearch": "Pin / Batch Search",
    "ViewAll": "Afficher tout",
    "ShowLess": "Afficher moins",
    "Sort": "Trier",
    "SelectPlanType": "Sélectionnez le type de plan.",
    "PreviousReclaim": "Récupération précédente",
    "SameBatchPinReclaimed": "Même lot/broche récupéré",
    "UpdateRemark": "Mise à jour de la remarque",
    "MaskDiscount": "Remise masque",
    "ShowDiscount": "Afficher la remise",
    "ForecastProfit": "Bénéfice prévisionnel",
    "Purchase": "Achat",
  },
  "nl": {
    "Products": "Producten",
    "Product": "Product",
    "ProductValue": "Productwaarde",
    "Quantity": "Hoeveelheid",
    "Denomination": "denominatie",
    "ViewProducts": "Bekijk de producten",
    "ViewProduct": "Bekijk product",
    "AddProducts": "Producten toevoegen",
    "AddProduct": "Product toevoegen",
    "AddNewProduct": "Nieuw product toevoegen",
    "Instructions": "instructies",
    "CustomerCareNumber": "Klantenservice nummer",
    "ProductType": "product type",
    "TotalDenominations": "denominaties",
    "Details": "Details",
    "PinLength": "Pin lengte",
    "Pin": "Pin",
    "SoldBy": "Verkocht door",
    "SoldById": "Verkocht op ID",
    "SoldTime": "Verkochte tijd",
    "ProductName": "productnaam",
    "SaveAddDenominations": "Denominaties opslaan en toevoegen",
    "AddProductImage": "Productafbeelding toevoegen",
    "ProductImage": "product afbeelding",
    "AddDenomination": "Denominatie toevoegen",
    "Done": "Gedaan",
    "Add": "Toevoegen",
    "Delete": "Verwijderen",
    "Update": "Update",
    "Cancel": "Annuleren",
    "Amount": "Hoeveelheid",
    "OldBalance": "Oude balans",
    "NewBalance": "Nieuw balans",
    "Paid": "Betaald",
    "SellerProfit": "Verkoper Winst",
    "ProductID": "Product-ID",
    "ID": "ID",
    "Image": "Afbeelding",
    "Company": "Bedrijf",
    "NoData": "Gegevens niet beschikbaar",
    "NoServicesAvailable": "Geen diensten beschikbaar",
    "Contact": "Contact",
    "AccountStatus": "Account Status",
    "ErrorData": "Fout bij ophalen van gegevens",
    "Edit": "Bewerking",
    "Activate": "Activeren",
    "Deactivate": "Deactiveren",
    "Suppliers": "leveranciers",
    "Supplier": "Leverancier",
    "SupplierCreateTag": "Leverancier maken om producten te kopen",
    "AddNewSupplier": "Een nieuwe leverancier toevoegen",
    "ViewSuppliers": "Bekijk leveranciers",
    "ViewSupplier": "Bekijk leverancier",
    "AddSupplier": "Leverancier toevoegen",
    "SupplierCompany": "Leverancier Bedrijf",
    "SupplierName": "naam van leverancier",
    "FullName": "Voor-en achternaam",
    "FullAddress": "Volledig adres",
    "EmailId": "E-mail identiteit",
    "Mobile": "mobiel Telephone",
    "StNr": "BTW Nr.",
    "Telephone": "Telefoon",
    "UstIdnr": "Ust.Id nr",
    "City": "Stad",
    "Country": "Land",
    "PostalCode": "Postcode",
    "Remarks": "Opmerkingen",
    "SaveAndPublish": "Opslaan en publiceren",
    "TotalPins": "Totaal aantal pinnen",
    "CreatedDate": "Aanmaakdatum",
    "BuyingDiscount": "Korting kopen",
    "PinSeq": "Speldreeks pin",
    "BatchSeq": "Batchvolgorde",
    "Delimiter": "scheidingsteken",
    "Discount": "Korting",
    "Inventory": "Inventaris",
    "InventoryDetails": "Inventaris Details",
    "AddInventory": "Voorraad toevoegen",
    "ViewInventory": "Inventaris bekijken",
    "UploadCSVFileOnly": "Upload alleen bestanden met de extensie .csv",
    "AddGiftCardBatch": "Voeg cadeaukaartbatch toe",
    "DivisionDiscount": "Divisiekorting",
    "AssignmentsAndDiscount": "Opdrachten en korting",
    "AddCustomer": "Klant toevoegen",
    "EditCustomer": "Klant bewerken",
    "AddNewCustomer": "Nieuwe klant toevoegen",
    "Customer": "Klant",
    "BalanceTopUp": "Saldo opwaarderen",
    "BalanceTrigger": "Balanstrigger",
    "CreditLimit": "Kredietlimiet",
    "CustomerName": "klantnaam",
    "CreditBalance": "Kredietbalans",
    "CustomerID": "Klanten ID",
    "OrganisationType": "Organisatietype",
    "CustomerDetails": "Klant details",
    "Customers": "Klanten",
    "ViewCustomer": "Bekijk klant",
    "ViewCustomers": "Klanten bekijken",
    "FirstName": "Voornaam",
    "LastName": "Achternaam",
    "HouseNo": "Huisnummer",
    "Address": "Adres",
    "Landline": "Vaste telefoon",
    "Username": "gebruikersnaam",
    "Template": "Sjabloon",
    "UsernameNotAvailable": "Gebruikersnaam niet beschikbaar",
    "Password": "Wachtwoord",
    "ConfirmPassword": "bevestig wachtwoord",
    "SubDomain": "Subdomein",
    "CustomerType": "klant type",
    "Name": "Naam",
    "Reference": "Verwijzing",
    "ActivatedOn": "Geactiveerd Aan",
    "Credit": "Credit",
    "NewCredit": "Nieuw tegoed",
    "OldCredit": "Oud krediet",
    "TopUp": "Opwaarderen",
    "Balance": "Evenwicht",
    "SoftPins": "Softpins",
    "EPay": "EPAY",
    "Sales": "Verkoop",
    "SalesCount": "Aantal verkopen",
    "Postal": "Post",
    "DiscountSlab": "Korting slab",
    "DiscountSlabError": "Selecteer een kortingsschaal",
    "LastPurchase": "Laatste aankoop",
    "PurchasedDate": "Aankoopdatum",
    "BeforeBalance": "Vóór saldo",
    "Value": "Waarde",
    "Sold": "Verkocht",
    "UnSold": "Onverkocht",
    "DateTime": "Datum en tijd",
    "AfterBalance": "Na saldo",
    "BeforeCredit": "Vóór krediet",
    "AfterCredit": "Na krediet",
    "LastPaidAmount": "Laatst betaalde bedrag",
    "Filter": "Filteren",
    "ClearAllFilters": "Alle filters wissen",
    "PAD": "Kortingen voor producttoewijzing",
    "AddCredit": "Voeg tegoed toe",
    "AutoCredit": "Automatisch tegoed",
    "CreditBalanceTransaction": "Credit / Saldo transactie",
    "Date": "Datum",
    "GrandTotal": "Eindtotaal",
    "Cash": "Contant geld",
    "PaymentType": "Betalingstype",
    "TransactionType": "Transactietype",
    "TransactionMode": "Transactiemodus",
    "PaymentMode": "Betalingswijze",
    "StandingCredit": "Permanent krediet",
    "StandingBalance": "Staande balans",
    "ForecastAmountneed": "Prognose nodig bedrag",
    "CreditRemark": "Credit opmerking",
    "Users": "Gebruikers",
    "ViewUsers": "Gebruikers weergeven",
    "User": "Gebruiker",
    "UserName": "Gebruikersnaam",
    "UserId": "Gebruikers-ID",
    "AddUser": "Gebruiker toevoege",
    "AddNewUser": "Nieuwe gebruiker toevoegen",
    "JobRole": "Functie",
    "Privileges": "Privileges",
    "SaveUser": "Gebruiker opslaan",
    "UpdateUser": "Gebruiker bijwerken",
    "AddNewStaff": "Voeg een nieuw personeel toe",
    "AddNewStaffTag": "Creëer nieuwe medewerkers met beperkte toegang tot modules",
    "CashCards": "Contant geld kaarten",
    "TotalBill": "Totale factuur",
    "Profit": "Winst",
    "Print": "Afdrukken",
    "Close": "Sluiten",
    "PlanId": "Plan-ID",
    "PlanName": "Naam van plan",
    "DiscountSlabs": "Kortingsplaten",
    "ProductsDiscount": "Korting op producten",
    "ErrorDiscountSlabs": "Fout bij ophalen discontoplaten",
    "DiscountPlanName": "Naam kortingsplan",
    "AddDiscountSlab": "Voeg kortingsschaal toe",
    "NoDiscountSlabs": "Geen kortingsplaten",
    "ViewDiscountSlabs": "Bekijk de kortingsschaal",
    "DenominationAmount": "Coupure-bedrag",
    "BatchNumber": "Batchnummer",
    "DiscountAmountError": "Kortingswaarde moet niet negatief zijn",
    "AmountError": "Vul bedrag in",
    "DenominationAmountError": "Vul het bedrag/bedrag in van de coupure moet groter zijn dan 0",
    "DynamicProducts": "Dynamische producten",
    "DynamicProductsDiscounts": "Dynamische kortingen op producten",
    "SoftApiCard": "SoftApi-kaart",
    "EPayApiId": "EPay API-ID",
    "EPayApiEAN": "EPay EAN",
    "EPayApiName": "EPay API-naam",
    "Submit": "Verzenden",
    "Priority": "Prioriteit",
    "GiftCards": "Cadeaubonnen",
    "EnterCardNoLabel": "Voer kaartnummer in",
    "EnterEANLabel": "Voer EAN in",
    "EnterBachNo": "Voer batchnummer in",
    "DirectRecharge": "Direct opladen",
    "DirectRechargeHistory": "Geschiedenis van direct opladen",
    "EnterMobileNo": "Voer een mobiel nummer in",
    "EnterPanNumber": "Voer het Pan-nummer in",
    "TransactionID": "Transactie-ID",
    "Provider": "Leverancier",
    "Status": "Status",
    "TransactionSuccessful": "Transactie geslaagd",
    "TransactionFailed": "Transactie mislukt",
    "TransactionPending": "Transactie in behandeling",
    "Buy": "Kopen",
    "Recharge": "Opladen",
    "InternationalTopUp": "Internationaal TopUp",
    "GetProviders": "Providers ophalen",
    "DirectRechargeDiscount": "Korting direct opladen",
    "InternationalTopUpDiscount": "Internationale TopUp-korting",
    "FlixBusDiscount": "FlixBus-korting",
    "DynamicDiscounts": "Dynamische kortingen",
    "Save": "Opslaan",
    "RechargeSuccessful": "Opladen voltooid",
    "SalesReports": "Verkoop rapporten",
    "PaymentLedger": "Betalings grootboek",
    "RechargeSummaryReport": "Reladge Summary Report",
    "DynamicProductsLog": "DynamicProductsLog",
    "Download": "Downloaden",
    "SalesReport": "Verkoop rapport",
    "StockReport": "Voorraad rapport",
    "StockReportDetails": "Stock Report Details",
    "InventoryLogReport": "Inventory Log Report",
    "BatchSummaryReport": "Batch Summary Report",
    "Reports": "Rapporten",
    "Organisation": "Organisatie",
    "CustomerId": "Klant-ID",
    "InventoryType": "Inventory Type",
    "DynamicType": "Type dynamo",
    "ConfigAmount": "Config Bedrag",
    "StockCount": "Voorraadtelling",
    "AvelStock": "Avel Stock",
    "SoldCount": "Sold Count",
    "UnSoldCount": "Niet-Sold Count",
    "UnPublishedCount": "Niet-Publicatiehebount",
    "SoldPercentage": "Sold Percentage",
    "UnSoldPercentage": "Onverkocht Percentage",
    "UnPublishedPercentage": "Niet-gepubliceerd Percentage",
    "PinsCount": "Pinnen Tellen",
    "SoldPinsCount": "Verkocht Pins Count",
    "UnSoldPinsCount": "Onverkochte Pins Count",
    "UnPublishedPinsCount": "Niet-gepubliceerd Pins Count",
    "Batch": "Partij",
    "LotNo": "LotNee",
    "SoldPinsPercentage": "Verkocht Pins Percentage",
    "UnSoldPinsPercentage": "Onverkocht Pins Percentage",
    "SoldUnSoldPinsPercentage": "Verkocht Onverkocht Pins Percentage",
    "UnPublishedPinsPercentage": "Niet-gepubliceerd Pins Percentage",
    "Actions": "Acties",
    "Invoices": "facturen",
    "SNo": "S No",
    "FlixBus": "FlixBus",
    "OneWay": "Een manier",
    "RoundTrip": "Rondvaart",
    "Adult": "Volwassen",
    "For15Years": "15 jaar",
    "Children": "Kinderen",
    "ZeroTo14Years": "NulTo14Jaar",
    "Bikes": "Fietsen",
    "BikeHelp": "FietsHelp",
    "LoginRequests": "LoginVerzoeken",
    "AuthStatus": "AuthStatus",
    "Approve": "Goedkeuren",
    "CallAdmin": "BelAdmin",
    "Terminal": "Terminal",
    "ActivateTerminal": "Activeer Terminal",
    "SendTerminalDetails": "SendTermina lDetails",
    "Domain": "Domein",
    "SearchCustomer": "Doorzoek klant",
    "EditSupplier": "Edit Leverancier",
    "SupplierEditTag": "Aanvullende Edit Tag",
    "PinNumber": "Pin Number",
    "Hotline": "Hotline",
    "DateAndTime": "Datum/tijd",
    "CellCredit": "Cell Credit",
    "CellCurrency": "Cell Valuta",
    "Serial": "serieel",
    "CardActivatedSuccessfully": "Card Activated Succesvol",
    "SellingPrice": "Verkoopprijs",
    "BookingNumber": "Boeking nummer",
    "TicketPrice": "Ticket prijs",
    "Departure": "Vertrek",
    "Arrival": "Aankomst",
    "Adults": "volwassenen",
    "Bicycle": "Fiets",
    "PassengerInformation": "Passagiers informatie",
    "Description": "Beschrijving",
    "LineDirection": "Lijnrichting",
    "InfoBlocks": "Informatie blokken",
    "Devices": "Apparaten",
    "SNO": "S.nr.",
    "DeviceTag": "Apparaat label",
    "DeviceSerialNo": "Serienr",
    "DeviceStatus": "Status",
    "Assigned": "Toegewezen",
    "AddDevice": "Apparaat toevoegen",
    "UpdateDevice": "Toestel bijwerken",
    "DeviceLastUpdated": "Laatst bijgewerkt",
    "Staff": "Personeel",
    "Invoice": "Factuur",
    "ChangePassword": "Wachtwoord wijzigen",
    "Flag": "Vlag",
    "OldCreditLimit": "Oude kredietlimiet",
    "OldBalanceTrigger": "Oude balans-trigger",
    "OldBalanceTopUp": "Oud saldo omhoog",
    "AlternativeMobiles": "Alternatieve mobiele telefoons",
    "AlternativeMobile1": "Alternatieve mobiele telefoon 1",
    "AlternativeMobile2": "Alternatieve mobiele telefoon 2",
    "TotalCredit": "Totaal krediet",
    "TotalCash": "Totaal contant",
    "TotalSales": "Totale omzet",
    "TotalBalance": "Totaal saldo",
    "BuyingPrice": "Aankoopprijs",
    "MobileNo": "Mobiel Nee",
    "Hello": "Hallo",
    "BackToHome": "Terug naar Home",
    "WelcomeBackLoginText": "Welkom terug",
    "Login": "Aanmelden",
    "OTP": "OTP",
    "DidNotGetOTP": "Heb je geen OTP?",
    "ClickForAdminApproval": "Klik hier voor goedkeuring door beheerder.",
    "Verify": "Verifiëren",
    "ReferenceCode": "Referentiecode",
    "CheckApprovalStatus": "Controleer de goedkeuringsstatus",
    "Search": "Zoeken",
    "FromCity": "Vanuit de stad",
    "ToCity": "Naar stad",
    "Return": "Terug",
    "Remove": "Verwijder",
    "Seat": "Stoel",
    "Reserve": "Reserveren",
    "PassengerDetails": "Passagiers gegevens",
    "DOB": "Geboortedatum",
    "YourOrder": "Uw bestelling",
    "Total": "Totaal",
    "inclVAT": "(incl. BTW)",
    "EnterVoucher": "Voer voucher in",
    "Voucher": "Voucher",
    "ProceedToPayment": "Ga verder met betalen",
    "SelectQuantity": "Selecteer aantal",
    "SelectDenomination": "Selecteer de rangorde",
    "Region": "Regio",
    "AmountReceive": "Ontvangen bedrag",
    "Trip": "Reis",
    "ContactDetails": "Contact gegevens",
    "FromDate": "Vanaf datum",
    "ToDate": "ot datum",
    "SelectUser": "Selecteer gebruiker",
    "TotalSaleAmount": "Totaal verkoop bedrag",
    "TotalSaleProfit": "Totale verkoop winst",
    "TotalSalePins": "Totaal aantal verkoop punten",
    "Transactions": "Transacties",
    "Action": "Actie",
    "Notes": "Opmerkingen",
    "YES": "JA",
    "NO": "NEE",
    "CreditHistory": "Krediet geschiedenis",
    "CreditLedger": "Credit Grootboek",
    "Referer": "Referentie",
    "BalanceType": "Type saldo",
    "EnableWebSupport": "Web ondersteuning inschakelen",
    "OK": "OK",
    "AddedCustomerSuccessfully": "Klant toegevoegd",
    "Previous": "Vorige",
    "Next": "Volgende",
    "SelectCustomer": "Selecteer klant",
    "Preview": "Voorbeeld",
    "All": "Alles",
    "Changes": "Wijzigingen",
    "LastActive": "Laatst actief",
    "UnAssignedDevices": "Niet-toegewezen apparaten",
    "Assign": "Toewijzen",
    "UnAssign": "Toewijzing opheffen",
    "AssignedDevices": "Toegewezen apparaten",
    "Active": "Actief",
    "SelectDiscountSlab": "Selecteer Discount-slab",
    "UpdateDiscounts": "Kortingen bijwerken",
    "SearchProduct": "Product zoeken",
    "AddBatch": "Batch toevoegen",
    "Invalid": "Ongeldig",
    "pins": "pennen",
    "Valid": "Geldig",
    "NoPinsToImported": "Geen PIN's om te importeren",
    "LoadMore": "Meer laden",
    "SoldPins": "Verkochte pinnen",
    "Published": "Gepubliceerd",
    "Unpublished": "Niet gepubliceerd",
    "CurrentBalance": "Huidig saldo",
    "Credits": "Credits",
    "OpeningBalance": "Beginsaldo",
    "ClosingBalance": "Eindsaldo",
    "ProductConfig": "Product configuratie",
    "UppercaseLetter": "Hoofd letter",
    "LowercaseLetter": "Kleine letter",
    "Number": "Nummer",
    "OneSymbol": "1 symbool (!, #, $, %, &, @, *, ?)",
    "EightCharacters": "8 tekens",
    "AssignProducts": "Wijs producten toe",
    "Discounts": "Kortingen",
    "Error": "Fout",
    "Retry": "Opnieuw proberen",
    "Logout": "Afmelden",
    "PageNotFound": "Pagina niet gevonden",
    "InternalServerError": "Interne serverfout",
    "NotAuthorized": "U bent niet geautoriseerd",
    "Settings": "Instellingen",
    "ShowPrintPopUp": "Pop-up Afdrukken weergeven",
    "MergeReceipts": "Ontvangsten samenvoegen",
    "Support": "Ondersteuning",
    "MobileNumber": "Mobiel nummer",
    "EditUser": "Gebruiker bewerken",
    "EditStaff": "Personeel bewerken",
    "Manager": "Manager",
    "AssignStaff": "Personeel toewijzen",
    "StaffHistory": "Personeels geschiedenis",
    "Operation": "Bediening",
    "Favourite": "Favoriet",
    "SalePrice": "Verkoopprijs",
    "Reclaim": "Terugvorderen",
    "Reclamation": "Terugvordering",
    "Reclamations": "Uitroepen",
    "ReclaimRequests": "Verzoeken om terug te vorderen",
    "ReclamationID": "Terugwinnings-ID",
    "LastUpdated": "Laatst bijgewerkt",
    "Accept": "Accepteren",
    "Reject": "Afwijzen",
    "Rejected": "Afgewezen",
    "IssueCreditNote": "Creditnota uitgeven",
    "InProgress": "In uitvoering",
    "InReview": "Wordt herzien",
    "New": "Nieuw",
    "ReclamationStatus": "Terugwinnings status",
    "ReclaimMessage": "Bericht terughalen",
    "TransactionDate": "Transactie datum",
    "Created": "Gemaakt",
    "RejectReason": "Reden voor afwijzing",
    "Reason": "Reden",
    "StatusColor": "Statuskleur",
    "RequestedUser": "Aangevraagde gebruiker",
    "ReclaimedAfter": "Teruggewonnen na",
    "Favourites": "Favorieten",
    "Clear": "wissen",
    "CashCard": "Cash Card",
    "GiftCard": "Cadeaubon",
    "Approved": "Goedgekeurd",
    "Self": "Zelf",
    "OverdueDays": "Achterstallige dagen",
    "YesterdaySales": "Verkoop gisteren",
    "LastCashPaid": "Laatst contant betaald",
    "EditFavourites": "Favorieten bewerken",
    "OverdueAmount": "Achterstallig bedrag",
    "Overdue": "Te laat",
    "OverdueProjection": "Verwachte projectie",
    "Remark": "Opmerking",
    "UsedIn": "Gebruikt in",
    "Days": "dagen",
    "AmountUsedInProjection": "Bedrag gebruikt in de projectie",
    "GenerateInvoice": "Genereer factuur",
    "GenerateCreditInvoice": "Creditfactuur genereren",
    "GenerateNetInvoice": "Genereer netto factuur",
    "Tax": "Belasting",
    "Generate": "Genereren",
    "InvoiceDate": "Factuurdatum",
    "Net": "Netto",
    "TotalBuyingAmount": "Totaal aankoopbedrag",
    "UpdateProductImage": "Productafbeelding bijwerken",
    "SoldDate": "Verkoopdatum",
    "Service": "Service",
    "GenerateInvoices": "Genereer facturen",
    "GrossInvoiceId": "Bruto factuur-ID",
    "NetInvoiceId": "Netto factuur-ID",
    "Gross": "Bruto",
    "MyInvoices": "Mijn facturen",
    "GeneratedInvoices": "Gegenereerde facturen",
    "InvoiceNo": "Factuurnr",
    "CreditNoteNo": "Credit nota Nee",
    "InvoiceValue": "Factuurwaarde",
    "CreditNoteValue": "Waarde creditnota",
    "CreditNote": "Creditnota",
    "Response": "Reactie",
    "CreditNoteId": "Creditnota-ID",
    "GrossDiscount": "Brutokorting",
    "NoCustomerForDates": "Geen klanten voor geselecteerde datums",
    "SelectTopUpPaymentMode": "Selecteer de betaalwijzen die u wilt aanvullen",
    "ResellerName": "Naam wederverkoper",
    "APIName": "API-naam",
    "LastInvoiceDate": "Laatste factuurdatum",
    "LastInvoiceFromDate": "Laatste factuur vanaf datum",
    "LastInvoiceToDate": "Laatste factuur tot nu toe",
    "LastInvoiceValue": "Waarde laatste factuur",
    "LastGrossValue": "Laatste brutowaarde",
    "LastCreditNote": "Laatste creditnota",
    "ApprovedBy": "Goedgekeurd door",
    "ApprovedAt": "Goedgekeurd bij",
    "RequestedAt": "Aangevraagd bij",
    "BatchNo": "Batch nummer",
    "AssignedTo": "Toegewezen aan",
    "AssignedAt": "Toegewezen op",
    "BatchSearch": "Batch zoeken",
    "UnAssignedStaff": "Niet-toegewezen personeel",
    "AssignedStaff": "Toegewezen personeel",
    "DevicesAssignedToYou": "Apparaten die aan u zijn toegewezen",
    "AssignDevices": "Apparaten toewijzen",
    "View": "Uitzicht",
    "ResellerProfit": "Winst voor wederverkopers",
    "SellingPermission": "Verkoop toestemming",
    "ClearAll": "Alles wissen",
    "HRBNr": "HRB-nr.",
    "IBan": "IBAN",
    "BIC": "BIC",
    "Director": "Directeur",
    "EditGiftCard": "GiftCard bewerken",
    "TopUpHistory": "Rapport aanvullen",
    "AddTopUp": "Voeg TopUp toe",
    "TopUpReport": "Rapport aanvullen",
    "SelectProduct": "Selecteer Product",
    "SelectPins": "Selecteer pins",
    "EnableSales": "Verkoop mogelijk maken",
    "TerminalStatus": "Terminal status",
    "TerminalActivated": "Terminal geactiveerd",
    "TerminalInActive": "Terminal inactief",
    "BuyingDiscounts": "Koop kortingen",
    "InvoiceHeaderImage": "Afbeelding koptekst factuur",
    "InvoiceFooterImage": "Afbeelding voettekst factuur",
    "SelectImage": "Selecteer beeld",
    "ProductDiscountsList": "Lijst met product kortingen",
    "InventorySearch": "Voorraad zoeken",
    "BuyingDate": "Aankoop datum",
    "SellingDate": "Verkoop datum",
    "SubOrganisation": "Sub organisatie",
    "OR": "Of",
    "SelectCustomers": "Selecteer klanten",
    "Selected": "Geselecteerd",
    "EpayAPIDiscount": "Epay API-korting",
    "APIBuyingDiscount": "API-aankoopkorting",
    "PinAndBatchSearch": "PIN en batch zoeken",
    "CustomerReadOnly": "Klant alleen-lezen",
    "CustomerFullPermission": "Volledige toestemming van de klant",
    "AutoCreditHistory": "Automatische kredietgeschiedenis",
    "NoCustomers": "Geen klanten",
    "LoginLogoImage": "Afbeelding van het logo voor aanmelding",
    "InvoiceLogo": "Factuurlogo",
    "SMSSenderID": "SMS-verzender-ID",
    "MultipleDiscounts": "Meerdere kortingen",
    "ConfirmMobileNumber": "Bevestig het mobiele nummer",
    "MobileNotMatched": "Mobiele telefoon komt niet overeen",
    "FillMobileError": "Fout bij vullen mobiel",
    "PoolName": "Poolnaam",
    "NoOfProducts": "Aantal producten",
    "NoOfPins": "Aantal pennen",
    "UnSoldPins": "Onverkochte pinnen",
    "AddPool": "Voeg pool toe",
    "AddNewPool": "Nieuwe pool toevoegen",
    "CallingCardsPoolUpdate": "Poolupdate voor telefoonkaarten",
    "AddPoolInventory": "Voeg voorraad aan de pool toe",
    "CallingCardsPool": "Calling Cards Pool",
    "CallingCards": "Telefoon kaarten",
    "CallingCard": "Telefoon kaarten",
    "PinDownload": "PIN-download",
    "DownloadCSV": "CSV downloaden",
    "DownloadTXT": "Download TXT",
    "ShowDetails": "Details weergeven",
    "HideDetails": "Details verbergen",
    "UserStatus": "Gebruikers status",
    "InActive": "Inactief",
    "Registrations": "Registraties",
    "Accepted": "Geaccepteerd",
    "ShopType": "Type winkel",
    "IP": "IP",
    "UpdatedBy": "Bijgewerkt door",
    "SetUsername": "Gebruikersnaam instellen",
    "VerifyOTP": "Controleer OTP",
    "SetPassword": "Wachtwoord instellen",
    "TerminalLimit": "Eindlimiet",
    "Limit": "Limiet",
    "UpdateShopTimings": "Werk de productietijden bij",
    "ShopTimings": "Productietijden",
    "StartTime": "Begintijd",
    "CloseTime": "Sluitingstijd",
    "RequestedCustomerId": "Aangevraagde klant-ID",
    "Reseller": "Wederverkoper",
    "ResellerCustomerId": "Klant-ID wederverkoper",
    "FirstPurchasedCustomer": "Eerste aangeschafte klant",
    "FirstPurchasedCustomerId": "Eerste aangeschafte klant-ID",
    "FirstPurchasedCustomerTime": "Tijd eerste gekochte klant",
    "SupplierDiscount": "Leveranciers korting",
    "Holidays": "Feest dagen",
    "BankName": "Naam bank",
    "Lunch": "Lunch",
    "ResoldToCustomer": "Doorverkocht aan klant",
    "ResoldToCustomerId": "Opnieuw naar klant-ID verzenden",
    "ResoldAt": "Wederverkoop bij",
    "Kredit": "Kredit",
    "Welcome": "Welkom",
    "Info": "Info",
    "DownloadTeamViewer": "Download TeamViewer",
    "GenerateReclamationInvoice": "Genereer terugvorderingfactuur",
    "GeneratedReclamationInvoices": "Gegenereerde terugwinningsfacturen",
    "ReclamationInvoices": "Facturen voor terugvordering",
    "MyReclamationInvoices": "Mijn facturen voor terugname",
    "MultiTopUp": "Multi TopUp",
    "EPayTerminals": "EPay-aansluitingen",
    "TerminalId": "Terminal-ID",
    "ShopName": "Winkelnaam",
    "CheckStatus": "Controleer de status",
    "Check": "Controleer",
    "POSDeviceAPP": "Printer stuur programma's downloaden",
    "enableLoginOTP": "Schakel OTP voor aanmelding in",
    "disableLoginOTP": "Schakel OTP voor aanmelding uit",
    "disabledLoginOTP": "OTP-aanmelding uitgeschakeld",
    "otp": "OTP",
    "DirectCreditNote": "DirectCreditNote",
    "transactionCheck": "Transactiecheque",
    "ZReport": "Z-rapport",
    "CardName": "Kaartnaam",
    "PrintDate": "Afdruk datum",
    "Employee": "Werknemer",
    "DateOfSale": "Verkoop datum",
    "Price": "Prijs",
    "confirmRejection": "Weet u zeker dat u wilt weigeren",
    "tripFullyBooked": "Deze reis is volgeboekt.",
    "AmountCreditLimitError": "Kredietlimiet bereikt",
    "IPAddress": "IP-adres",
    "DownloadExcel": "Download Excel",
    "Resend": "Verzend opnieuw",
    "ResendEmail": "E-mail opnieuw verzenden",
    "ResendEmailConfirm": "Weet u zeker dat u de e-mail opnieuw wilt verzenden?",
    "PinBatchSearch": "PIN / Batch zoeken",
    "ViewAll": "Alles weergeven",
    "ShowLess": "Minder weergeven",
    "Sort": "Sorteren",
    "SelectPlanType": "Selecteer Plan Type",
    "PreviousReclaim": "Vorige terugvordering",
    "SameBatchPinReclaimed": "Dezelfde batch/pin wordt teruggewonnen",
    "UpdateRemark": "Opmerking bijwerken",
    "MaskDiscount": "Maskerkorting",
    "ShowDiscount": "Toon korting",
    "ForecastProfit": "Prognose winst",
    "Purchase": "Aankoop",
  }
}
