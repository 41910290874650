export default {
  SET_COUNTRIES(state, countries) {
    state.countries = countries
  },
  SET_IR_DISCOUNT(state, discount) {
    state.discount = discount
  },
  SET_REGIONS(state, regions) {
    state.regions = regions
  },
  SET_PROVIDERS(state, providers) {
    state.providers = providers
  },
}
