import state from './moduleFlixBusState'
import mutations from './moduleFlixBusMutations'
import actions from './moduleFlixBusActions'
import getters from './moduleFlixBusGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

