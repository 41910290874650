import axios from '@/axios.js'

export default {
  fetchCustomers({ commit, state }, { filters, page = '0', search = '', limit = '20' }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(filters).forEach((key) => {
        data.append(key, filters[key])
      })
      data.append('page', page)
      data.append('limit', limit)
      if (search != null && search != '') data.append('search_text', search.toLowerCase())

      axios.post('/view_customers/', data)
        .then((response) => {
          commit('SET_CUSTOMERS', response.data.content)
          commit('SET_CUSTOMERS_RAW', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchDownloadCustomers({ commit, state }, { filters, page = '0', search = '' }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(filters).forEach((key) => {
        data.append(key, filters[key])
      })
      data.append('page', page)
      data.append('limit', '20')
      if (search != null && search != '') data.append('search_text', search.toLowerCase())

      axios.post('/view_customers/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchCustomerDetails({ commit, state }, customerId) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('customer_id', customerId)
      axios.post('/customer_details/', data)
        .then((response) => {
          // commit('SET_CUSTOMERS', response.data.content)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateUserStatus({ commit, state }, {customerId, status}) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('customer_id', customerId)
      data.append('is_active', status)
      axios.post('/update_customer_status/', data)
        .then((response) => {
          // commit('SET_CUSTOMERS', response.data.content)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateCustomerMaskDiscount({ commit, state }, {customerId, status}) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('customer_id', customerId)
      data.append('mask_seller_discount', status)
      axios.post('/update_customer_extra_details/', data)
        .then((response) => {
          // commit('SET_CUSTOMERS', response.data.content)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  changeCustomerPassword({ commit, state }, { customerId, password, username }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      // data.append('customer_id', customerId)
      data.append('username', username)
      data.append('new_password', password)
      axios.post('/change_password/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },

  updatePassword({ commit, state }, {  password }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('new_password', password)
      axios.post('/update_password/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },

  activateTerminalId({ commit, state }, { customerId, terminalLimit }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('customer_id', customerId)
      data.append('terminal_limit', terminalLimit)
      axios.post('/activate_terminal_id/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateOtpStatus({ commit, state }, { customerId, otp }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('customer_id', customerId)
      data.append('enable_otp', otp)
      axios.post('/update_customer_otp_type/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addNewCustomer({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      axios.post('/add_customer/', data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateCustomer({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      axios.post('/edit_customer/', data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateCustomerAPIStatus({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      axios.post('/update_customer_api_details/', data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateCustomerExtraAPIStatus({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      axios.post('/update_customer_extra_details/', data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateCustomerRemark({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      axios.post('/update_customer_remarks/', data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateAutoCredit({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      axios.post('/update_auto_credit/', data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // Credits
  fetchCustomerWalletHistory({ commit, state }, { page = '0', payload }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      // if (userId) data.append('customer_id', userId)

      data.append('page', page)

      data.append('limit', '20')

      if(payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }

      axios.post('/view_wallet_history/', data)
        .then((response) => {
          commit('SET_CUSTOMER_WALLET_HISTORY_10', response.data.wallet_history_transactions)
          commit('SET_CUSTOMER_WALLET_HISTORY_10_RAW', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // Credits
  fetchCreditWalletHistory({ commit, state }, { payload, page = '0' }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      data.append('page', page)

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      axios.post('/view_wallet_history/', data)
        .then((response) => {
          commit('SET_CUSTOMER_WALLET_HISTORY_10', response.data.wallet_history_transactions)
          commit('SET_CUSTOMER_WALLET_HISTORY_10_RAW', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchDownloadCreditWalletHistory({ commit, state }, { payload }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      axios.post('/view_wallet_history/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  processCustomerWalletAmount({ commit, state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      axios.post('/process_wallet_history/', data)
        .then((response) => {
          dispatch('auth/fetchUserDetails', null, { root: true })
          if (response.status === 200) {
            resolve(response)
          } else {
            reject(response)
          }
        })
        .catch((error) => {
          dispatch('auth/fetchUserDetails', null, { root: true })
          reject(error)
        })
    })
  },
  processMultiCustomerWalletAmount({ commit, state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      axios.post('/process_top_up_with_multi_customers/', data)
        .then((response) => {
          dispatch('auth/fetchUserDetails', null, { root: true })
          if (response.status === 200) {
            resolve(response)
          } else {
            reject(response)
          }
        })
        .catch((error) => {
          dispatch('auth/fetchUserDetails', null, { root: true })
          reject(error)
        })
    })
  },
  updateDynamicDiscounts({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      if (data) formData.append('update_discounts', data)

      axios.post('/api_discounts/', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateSuperAdminDynamicDiscounts({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })
      axios.post('/view_update_super_admin_discounts/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
}
