import state from './moduleInternationalTopupState'
import mutations from './moduleInternationalTopupMutations'
import actions from './moduleInternationalTopupActions'
import getters from './moduleInternationalTopupGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

