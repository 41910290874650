import axios from '@/axios.js'

export default {
  fetchFavCashCards({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      if (payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }
      data.append('product_type', 'cash card')
      data.append('favorite', '1')

      axios.post('/view_seller_products/', data)
        .then((response) => {
          try {
            commit('SET_FAV_CASH_CARDS', response.data.products)
          } catch (e) {

          }
          resolve(response.data.products)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCashCards({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      if (payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }
      data.append('product_type', 'cash card')

      axios.post('/view_seller_products/', data)
        .then((response) => {
          try {
            localStorage.setItem('t_key', response.data.transaction_key);
            commit('SET_CASH_CARDS', response.data.products)
          } catch (e) {

          }
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCashCardsV2({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      if (payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }
      data.append('product_type', 'cash card')

      axios.post('/view_seller_products_v2/', data)
        .then((response) => {
          try {
            if(response.data.transaction_key) {
              localStorage.setItem('t_key', response.data.transaction_key);
            }
            if(response.data.filters.page == 0) {
              commit('SET_CASH_CARDS', response.data.products)
            } else {
              commit('UPDATE_CASH_CARDS', response.data.products)
            }
          } catch (e) {

          }
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  purchaseCard({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })
      if (localStorage.getItem('t_key')) {
        let key = localStorage.getItem('t_key');
        data.append('transaction_key', key)
        localStorage.removeItem('t_key');
      }
      let pinDownload = localStorage.getItem('pin_download') == 'true'
      if(payload.quantity > 30 || pinDownload) {
        // add_bulk_transaction_v3
        // data.append("request_type", "42");
        axios.post('/add_bulk_transaction_v3/', data)
          .then((response) => {
            dispatch('auth/fetchTransactionKey', null, { root: true })
            dispatch('auth/fetchUserDetails', null, { root: true })
            resolve(response.data)
          })
          .catch((error) => {
            dispatch('auth/fetchTransactionKey', null, { root: true })
            dispatch('auth/fetchUserDetails', null, { root: true })
            reject(error)
          })
      } else {
        axios.post('/add_transaction_v3/', data)
          .then((response) => {
            dispatch('auth/fetchTransactionKey', null, { root: true })
            dispatch('auth/fetchUserDetails', null, { root: true })
            resolve(response.data)
          })
          .catch((error) => {
            dispatch('auth/fetchTransactionKey', null, { root: true })
            dispatch('auth/fetchUserDetails', null, { root: true })
            reject(error)
          })
      }
    })
  },
}
