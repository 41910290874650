import axios from '@/axios.js'

export default {
  fetchProducts({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get('/get_direct_recharge_products_with_dr_and_cc/')
        .then((response) => {
          try {
            localStorage.setItem('t_key', response.data.transaction_key);
            commit('SET_PROVIDERS', response.data.direct_recharge_products)
          } catch (e) {

          }
          resolve(response.data.direct_recharge_products)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  rechargeMobileNo({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      if (localStorage.getItem('t_key')) {
        let key = localStorage.getItem('t_key');
        data.append('transaction_key', key)
        localStorage.removeItem('t_key');
      }

      axios.post('/direct_recharge_with_mobile_with_dr_and_cc/', data)
        .then((response) => {
          dispatch('auth/fetchUserDetails', null, { root: true })
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  checkRechargeTransaction({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      axios.post('/check_direct_recharge_transaction/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchTransactionHistory({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      axios.post('/get_direct_recharge_history/')
        .then((response) => {
          commit('SET_TRANSACTIONS', response.data.direct_recharge_history)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
