export default {
  devices: [],
  devicesRaw: null,
  vPage: 0,
  itemCount: 50,
  totalCount: 0,
  vTotalPages: 0,
}

// 0 - employee
// 1 - branch
// 2 - organisation admin
// 3 - we
