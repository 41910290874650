import axios from '@/axios'
import Vue from 'vue';

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    records: [],
    mappedRecords: [],
    mappedRecordsRaw: null,
    vTotalCount: 0,
    vTotalPages: 0,
    vPage: 0,
  },
  mutations: {
    SET_RECORDS(state, records) {
      state.records = records;
    },
    SET_MAPPED_RECORDS(state, records) {
      state.mappedRecords = records;
    },
    SET_MAPPED_RECORDS_RAW(state, raw) {
      state.mappedRecordsRaw = raw
      state.vTotalCount = Number(raw.filters.total_count)
      state.vPage = Number(raw.filters.page)
      state.vTotalPages = Math.ceil(Number(raw.filters.total_count) / Number(raw.filters.limit))
    },
  },
  actions: {
    uploadBankingCSVFile({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        data.append('file', payload.bankingCsv)
        data.append('delimiter', payload.delimiter)

        axios.post('/bank_module_step_1/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => { reject(error) })
      })
    },
    uploadBankingCSVStep2({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
        // data.append('uid', payload.uid)
        // data.append('confirm', payload.confirm)

        axios.post('/bank_module_step_2/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => { reject(error) })
      })
    },
    mapCustomer({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
        // data.append('bank_record_id', payload.bank_record_id)
        // data.append('customer_id', payload.customer_id)

        axios.post('/update_bank_record/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => { reject(error) })
      })
    },
    deleteMappedCustomer({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
        // data.append('bank_name_map_id', payload.bank_name_map_id)

        axios.delete('/update_delete_bank_name_mapped_customer/', { data })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => { reject(error) })
      })
    },

    celeryMultiTopUP({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()

        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })

        axios.post('/celery_process_top_up_with_multi_customers/', data)
          .then((response) => {
            dispatch('auth/fetchUserDetails', null, { root: true })
            if (response.status === 200) {
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch((error) => {
            dispatch('auth/fetchUserDetails', null, { root: true })
            reject(error)
          })
      })
    },
    fetchBankRecords({ commit, state }, payload = {}) {
      // const data = new FormData()
      // Object.keys(payload).forEach((key) => {
      //   data.append(key, payload[key])
      // })
      return new Promise((resolve, reject) => {
        axios.get('/get_bank_records/', { params: payload })
          .then((response) => {
            commit('SET_RECORDS', response.data.bank_records)
            // commit('SET_REPORT_CUSTOMERS_RAW', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    fetchBankMappedCustomers({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        // const data = new FormData()
        //
        // data.append('page', page)
        // data.append('limit', '20')
        axios.get('/get_bank_name_mapped_customers/', { params: payload })
          .then((response) => {
            commit('SET_MAPPED_RECORDS', response.data.bank_records)
            commit('SET_MAPPED_RECORDS_RAW', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
  },
  getters: {
    records(state) {
      return state.records;
    },
    mappedRecords(state) {
      return state.mappedRecords;
    },
  }
}

