import axios from '@/axios'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    data: [],
    invoice: null,
    vPage: 0,
    itemCount: 0,
    totalCount: 0,
    vTotalPages: 1,
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data
    },
    SET_RAW_DATA(state, data) {
      state.vPage = Number(data.filters.page)
      state.itemCount = Number(data.filters.limit)
      state.totalCount = Number(data.filters.total_count)
      state.vTotalPages = Math.ceil(state.totalCount / state.itemCount)
    },
    SET_SINGLE_INVOICE(state, data) {
      state.invoice = data
    },
  },
  actions: {
    fetchInvoice({ state, commit }, { payload }) {
      commit('SET_SINGLE_INVOICE', null)
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/view_invoices/', data)
          .then((response) => {
            if(!payload.delete) {
              commit('SET_SINGLE_INVOICE', response.data.invoices[0])
            }
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    resentMailInvoice({ state, commit }, { payload }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/view_invoices/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchInvoices({ state, commit }, { payload }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/view_invoices/', data)
          .then((response) => {
            commit('SET_DATA', response.data.invoices)
            commit('SET_RAW_DATA', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchDownloadInvoices({ state, commit }, { payload }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/view_invoices/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchReclamationInvoice({ state, commit }, { payload }) {
      commit('SET_SINGLE_INVOICE', null)
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/view_reclamation_invoices/', data)
          .then((response) => {
            if(!payload.delete) {
              commit('SET_SINGLE_INVOICE', response.data.invoices[0])
            }
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    resentMailReclamationInvoice({ state, commit }, { payload }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/view_reclamation_invoices/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchReclamationInvoices({ state, commit }, { payload }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/view_reclamation_invoices/', data)
          .then((response) => {
            commit('SET_DATA', response.data.invoices)
            commit('SET_RAW_DATA', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchDownloadReclamationInvoices({ state, commit }, { payload }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/view_reclamation_invoices/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    generateInvoice({ state, commit }, { payload }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/get_invoice_report/', data)
          .then((response) => {
            commit('SET_DATA', response.data.invoices)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    generateCustomersInvoices({ state, commit }, { payload }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/generate_invoices_with_customer_ids/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    generateCustomersReclamationInvoices({ state, commit }, { payload }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/generate_reclamation_invoices_with_customer_ids/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getInvoiceCustomers({ state, commit }, { payload }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/get_customers_for_invoice/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getReclamationInvoiceCustomers({ state, commit }, { payload }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/get_customers_for_reclamation_invoice/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

