import axios from '@/axios.js'

export default {
  fetchSellerUsers({ commit, state }, { page = '0' }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      data.append('page', page)
      data.append('limit', '20')
      axios.post('/view_customers/', data)
        .then((response) => {
          commit('SET_USERS', response.data.content)
          commit('SET_USERS_RAW', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  checkCustomerUsername({ commit, state }, username) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('username', username)

      axios.post('/check_customer/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchMangerUsers({ commit, state }, { page = '0' }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      data.append('page', page)
      data.append('is_manager', '0')
      data.append('limit', '20')

      // try {
      //   let j = JSON.parse(localStorage.getItem('userInfo'))
      //   if (j.staff_role === 1) {
      //     data.append('is_manager', '0')
      //   }
      // } catch(e) {}

      axios.post('/view_internal_permission/', data)
        .then((response) => {
          commit('SET_USERS', response.data.staff_accounts)
          commit('SET_USERS_RAW', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchUsers({ commit, state }, { page = '0', dontSendManagerFlag = false }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      data.append('page', page)
      data.append('limit', '20')
      if(!dontSendManagerFlag) {
        try {
          let j = JSON.parse(localStorage.getItem('userInfo'))
          if (j.internal_role === 0) {
            data.append('is_manager', '0')
          }
        } catch (e) {
        }
      }

      axios.post('/view_internal_permission/', data)
        .then((response) => {
          commit('SET_USERS', response.data.staff_accounts)
          commit('SET_USERS_RAW', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchSingleUser({ commit, state }, userId) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('user_id', userId)

      axios.post('/view_internal_permission/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addNewUser({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      axios.post('/add_internal_permission/', data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  addNewSellerUser({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      axios.post('/add_customer/', data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateSellerUser({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      axios.post('/edit_customer/', data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateUser({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      axios.post('/update_internal_permission/', data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchStaffAssign({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      axios.post('/staff_assign/', data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchStaffHistory({ commit, dispatch }, { page = 0, payload }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      data.append('page', page)
      data.append('limit', '20')

      axios.post('/staff_assign_history/', data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
