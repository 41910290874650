export default {
  SET_PRODUCTS(state, products) {
    state.products = products
  },
  SET_SINGLE_PRODUCT(state, product) {
    state.product = product
  },
  UPDATE_PRODUCTS(state, products) {
    state.products.push(...products)
  },
  SET_PRODUCTS_RAW(state, raw) {
    state.vPage = Number(raw.page)
    state.limit = Number(raw.limit)
    state.vTotalPages = Number(raw.total_pages)
    state.productsRaw = raw
  },
  SET_PRODUCTS_FILTER(state, filters) {
    state.productsFilters = filters
  },
  UPDATE_PAGE(state, page) {
    state.vPage = page
  },
}
