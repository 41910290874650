export default {
  SET_DEVICES(state, devices) {
    state.devices = devices
  },
  UPDATE_DEVICES(state, devices) {
    state.devices.push(...devices)
  },
  SET_DEVICES_RAW(state, raw) {
    state.devicesRaw = raw
    if (raw.filters) {
      state.vPage = Number(raw.filters.page)
      state.itemCount = Number(raw.filters.count)
      state.totalCount = Number(raw.filters.total_count)
      state.vTotalPages = Math.ceil(Number(raw.filters.total_count) / Number(raw.filters.limit))
    } else {
      state.vPage = 0
      state.itemCount = 50
      state.totalCount = 0
      state.vTotalPages = 1
    }
  },
}
