import axios from '@/axios'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    categories: [],
    products: [],
  },
  mutations: {
    SET_CATEGORIES(state, data) {
      state.categories = data
      state.products = []
    },
    SET_PRODUCTS(state, data) {
      state.products = data
    },
  },
  actions: {
    fetchHardwareCategories({state, commit}, {payload}) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.get('/hardware_category/', data)
          .then((response) => {
            commit('SET_CATEGORIES', response.data.categories)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateHardwareCategory({state, commit, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.patch(`/hardware_category/update/${payload["id"]}/`, data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createNewHardwareCategory({state, commit, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/hardware_category/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteCategory({state, commit, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.delete(`/hardware_category/delete/${payload["id"]}`)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    fetchHardwareCategoryProducts({state, commit}, payload) {
      return new Promise((resolve, reject) => {

        axios.get('/hardware_product/', { params: payload })
          .then((response) => {
            commit('SET_PRODUCTS', response.data.products)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createNewHardwareProduct({state, commit, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/hardware_product/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteHardwareProduct({state, commit, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.delete(`/hardware_product/delete/${payload["id"]}/`)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateHardwareProduct({state, commit, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.patch(`/hardware_product/update/${payload["id"]}/`, data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

