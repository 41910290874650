<template>
  <div
    v-if="showHover"
    id="hover-support"
    class="absolute transition no-print w-full-content hover:right-0 transition-all z-30 bottom-72 right-content py-5">
    <div class="flex flex-row overflow-hidden items-center">
      <div class="p-3 rounded-tl-lg rounded-bl-lg bg-primary">
        <feather-icon icon="PhoneIcon"
                      svgClasses="h-10 w-10"
                      class="p-1 inline-flex rounded-full" :class="[`text-white`]"></feather-icon>

      </div>
      <div
        v-html="data"
        class="w-data-content text-white text-xl px-4 py-4 rounded-tl-lg rounded-bl-lg bg-primary">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HoverCallComponent",
  data() {
    return {
      showHover: false,
      data: "",
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  watch: {
    '$store.state.AppActiveUser': function (val) {
      this.updateHover()
    },
  },
  methods: {
    updateHover() {
      try {

        const template = localStorage.getItem('template')
        if(template != 'null' && template != 0) {
          let home = JSON.parse(localStorage.getItem("home"))
          if (this.activeUserInfo.account_role == 2 && home.contact_details.support_info) {
            this.data = home.contact_details.support_info;
            this.showHover = true;
          }
        }
      } catch (e) {

      }
    },
  },
  mounted() {
    this.updateHover();
  }
}
</script>

<style lang="scss">
.w-full-content {
  width: 26rem;
}
.w-icon-content {
  width: 3rem;
}
.w-data-content {
  width: 23rem;
}
.right-content {
  right: -21.5rem;
}
#hover-support {
  z-index: 100000;
  a:hover {
    color: white;
    font-weight: bold;
  }
  .text-custom {
    font-size: 14px !important;
    line-height: 28px;
  }
}
</style>
