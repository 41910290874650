// import * as Fingerprint2 from 'fingerprintjs2'
// import FingerprintJS from '@fingerprintjs/fingerprintjs'
// import * as UAParser from 'ua-parser-js'
import axios from '../../../axios/index.js'
// import Fingerprint from './fingerprint'
// import * as fpbrowser_v1 from './fp'
// import FingerprintJS from '@fingerprintjs/fingerprintjs'
import Bowser from "bowser";
import CryptoJS from 'crypto-js'
// import sha256 from 'crypto-js/sha256';
// import hmacSHA512 from 'crypto-js/hmac-sha512';
// import Base64 from 'crypto-js/enc-base64';

export default {
  init() {
    axios.interceptors.response.use((response) => response, (error) => {
      const { response } = error

      return Promise.reject({
        response,
        error,
      })
    })
  },
  async login(username, pwd, token) {


    // // hash the password 
    // const encrpyted_password = CryptoJS.AES.encrypt(this.password, salt).toString();

    // console.log('Encrypted Password', encrpyted_password)

    // // decrypt the password and convert to string
    // const decryptData = CryptoJS.AES.decrypt(
    //   encrpyted_password,
    //   salt
    // ).toString(CryptoJS.enc.Utf8)



    // console.log('Decrypted Data from index', decryptData)

    // Encrypt payload 
    const fingerprint = await _getFingerprint()
    const formData = new FormData()
    // formData.append('username', username)
    // formData.append('password', pwd)
    // formData.append('hash', fingerprint)
    // formData.append('recaptcha_token', token)

    // const userData = [{username: this.username}, {password: this.password}, {token: token}]
    const loginData = [username, pwd, fingerprint, token]

    // Random Salt
    // const salt = "HTfGUxck1CpnxUbx"
    // const iv = "2073979037347210"

    // const salt = CryptoJS.enc.Base64.parse("3kxV6uAt~wh_{pHRaPW'wK6FNb&[!sSL")
    // const iv = CryptoJS.enc.Utf8.parse("1354731903742741")

    // // const payload = loginData
    // // var derived_key = CryptoJS.enc.Base64.parse("LefjQ2pEXmiy/nNZvEJ43i8hJuaAnzbA1Cbn1hOuAgA=")
    // // var iv = CryptoJS.enc.Utf8.parse("1020304050607080");

    // const test = CryptoJS.AES.encrypt(JSON.stringify(loginData), salt, {iv: iv, mode: CryptoJS.mode.CBC}).toString();
    // console.log('Encrypted Base', test)


    const salt = CryptoJS.enc.Base64.parse("LekjB2pEXmiy/nNZvTJ43i8hJuaAnzbA1Cbn1hOuRgA=")
    const iv = CryptoJS.enc.Utf8.parse("4375792357326447");
    const data = CryptoJS.AES.encrypt(JSON.stringify(loginData), salt, {iv: iv, mode: CryptoJS.mode.CBC}).toString()

    // console.log('Generated Hash', data)

    // const encrpyted_payload= CryptoJS.AES.encrypt(JSON.stringify(loginData), salt).toString();

    // console.log('Encrypted Payload from index', encrpyted_payload)

    // console.log( CryptoJS.AES.decrypt("dv6OPtfmyZwDrklSARJYrA==", salt))
    // Decrypt payload

    // const decrypt_payload = CryptoJS.AES.decrypt(
    //   encrpyted_payload,
    //   salt
    // ).toString(CryptoJS.enc.Utf8)


    // const decrypt_payload = CryptoJS.AES.decrypt(
    //   encrpyted_payload,
    //   salt
    // )
    // const decryptedData = JSON.parse(decrypt_payload.toString(CryptoJS.enc.Utf8))


    // console.log('Decrypted Payload from index', decryptedData)


    formData.append('data', data)
    return axios.post('/login/', formData)
  },
  verifyOtp(username, otp) {
    const formData = new FormData()
    formData.append('username', username)
    formData.append('otp', otp)
    return axios.post('/verify/', formData)
  },
  verifyOtpWithRef(refId, ref) {
    const formData = new FormData()
    formData.append('reference_id', refId)
    formData.append('reference', ref)
    return axios.post('/check_login_request_approval/', formData)
  },
}

async function _getFingerprint() {
  // var r = new fpbrowser_v1
  //   , t = new fpbrowser_v1({
  //   canvas: !0
  // })
  //   , n = r.get()
  //   , o = t.get()
  //   , i = n + "" + o
  // console.log("----------------------------------");
  // console.log(i);
  // return i;

  var fingerprint = (function(window, screen, navigator) {

    const browser = Bowser.getParser(navigator.userAgent);

    // console.log(`The current browser name is "${browser.getBrowserName()}"`);
    // console.log(`The current browser name is "${browser.getEngine().name}"`);
    // console.log(`The current browser name is "${browser.getOS().name}"`);
    // console.log(`The current browser name is "${browser.getEngineName()}"`);

    // https://github.com/darkskyapp/string-hash
    function checksum(str) {
      var hash = 5381,
        i = str.length;

      while (i--) hash = (hash * 33) ^ str.charCodeAt(i);

      return hash >>> 0;
    }

    // http://stackoverflow.com/a/4167870/1250044
    function map(arr, fn){
      var i = 0, len = arr.length, ret = [];
      while(i < len){
        ret[i] = fn(arr[i++]);
      }
      return ret;
    }

    // let getCanvasFingerprint = function() {
    //   /** @type {!Element} */
    //   var canvasDrawBG = document.createElement("canvas");
    //   var ctx = canvasDrawBG.getContext("2d");
    //   /** @type {string} */
    //   var tempPathText = "https://www.pjtelesoftgmbh.de/";
    //   /** @type {string} */
    //   ctx.textBaseline = "top";
    //   /** @type {string} */
    //   ctx.font = "12.5px 'Arial'";
    //   /** @type {string} */
    //   ctx.textBaseline = "numeric";
    //   /** @type {string} */
    //   ctx.fillStyle = "#f60";
    //   ctx.fillRect(101, 5, 48, 30);
    //   /** @type {string} */
    //   ctx.fillStyle = "#069";
    //   ctx.fillText(tempPathText, 2, 15);
    //   /** @type {string} */
    //   ctx.fillStyle = "rgba(111, 177, 0.1, 0.7)";
    //   ctx.fillText(tempPathText, 4, 17);
    //   return canvasDrawBG.toDataURL();
    // }

    // console.log([
    //   getCanvasFingerprint(),
    //   browser.getBrowserName(),
    //   browser.getEngine().name,
    //   browser.getEngineName(),
    //   browser.getOS().name,
    //   navigator.vendor,
    //   navigator.platform,
    //   [screen.height, screen.width, screen.colorDepth].join('x'),
    //   new Date().getTimezoneOffset(),
    //   !!window.sessionStorage,
    //   !!window.localStorage
    // ].join('###'))
    return checksum([
      // getCanvasFingerprint(),
      browser.getBrowserName(),
      browser.getEngine(),
      browser.getEngineName(),
      browser.getOS(),
      navigator.vendor,
      navigator.platform,
      [screen.height, screen.width, screen.colorDepth].join('x'),
      !!window.sessionStorage,
      !!window.localStorage
    ].join('###'));

  }(window, screen, navigator));

  return fingerprint;
  // var fp = new Fingerprint({
  //   canvas: true,
  //   ie_activex: true,
  //   screen_resolution: true
  // });
  //
  // var uid = fp.get();
  //
  // return uid;
  //
  // const fpPromise = FingerprintJS.load()
  // let data = new Promise((resolve, reject) => {
  //   async function getHash() {
  //     const fp = await fpPromise
  //     const result = await fp.get()
  //     // console.log(result)
  //     return result.visitorId;
  //   }
  //   if (window.requestIdleCallback) {
  //     requestIdleCallback(async () => resolve(await getHash()))
  //   } else {
  //     console.log('get fp hash @ setTimeout')
  //     setTimeout(async () => resolve(await getHash()), 500)
  //   }
  // })
  //
  // let f = await data;
  // return f
  // console.log("-----------------")
  // console.log(f)

  // return new Promise((resolve, reject) => {
  //   async function getHash() {
  //     const options = {
  //       excludes: {
  //         plugins: true,
  //         localStorage: true,
  //         adBlock: true,
  //         screenResolution: true,
  //         availableScreenResolution: true,
  //         enumerateDevices: true,
  //         pixelRatio: true,
  //         doNotTrack: true,
  //         // preprocessor: (key, value) => {
  //         //   if (key === 'userAgent') {
  //         //     const parser = new UAParser(value)
  //         //     // return customized user agent (without browser version)
  //         //     return `${parser.getOS().name} :: ${parser.getBrowser().name} :: ${parser.getEngine().name}`
  //         //   }
  //         //   return value
  //         // },
  //       },
  //     }
  //
  //     try {
  //       const components = await Fingerprint2.getPromise()
  //       const values = components.map((component) => component.value)
  //       console.log('fingerprint hash components', components)
  //       console.log('fingerprint hash components', values)
  //       console.log('fingerprint hash components', Fingerprint2.x64hash128(values.join(''), 31))
  //
  //       return String(Fingerprint2.x64hash128(values.join(''), 31))
  //     } catch (e) {
  //       reject(e)
  //     }
  //   }
  //
  //   if (window.requestIdleCallback) {
  //     console.log('get fp hash @ requestIdleCallback')
  //     requestIdleCallback(async () => resolve(await getHash()))
  //   } else {
  //     console.log('get fp hash @ setTimeout')
  //     setTimeout(async () => resolve(await getHash()), 500)
  //   }
  // })
}
