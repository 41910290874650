import axios from '@/axios.js'

export default {
  fetchGiftCards({ commit, state }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      data.append('product_type', 'gift card')

      axios.post('/view_seller_products/', data)
        .then((response) => {
          try {
            localStorage.setItem('t_key', response.data.transaction_key);
            commit('SET_GIFT_CARDS', response.data.products)
          } catch (e) {

          }
          resolve(response.data.products)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  purchaseCard({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      if (localStorage.getItem('t_key')) {
        let key = localStorage.getItem('t_key');
        data.append('transaction_key', key)
        localStorage.removeItem('t_key');
      }

      axios.post('/add_transaction_v3/', data)
        .then((response) => {
          dispatch('auth/fetchUserDetails', null, { root: true })
          resolve(response.data)
        })
        .catch((error) => {
          dispatch('auth/fetchUserDetails', null, { root: true })
          reject(error)
        })
    })
  },
}
