import router from '@/router'
import jwt from '../../http/requests/auth/jwt/index.js'

import axios from '../../axios'

export default {
  // JWT
  loginJWT({commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      jwt.login(payload.userDetails.username, payload.userDetails.password, payload.userDetails.token)
        .then((response) => {
          // If there's user data in response
          if (response.data) {
            if (response.data.token) {
              localStorage.setItem('accessToken', response.data.token)
              commit('SET_BEARER', response.data.token)
              dispatch('fetchUserDetails')
              router.push('/loading')
            }
            localStorage.setItem('reference', response.data.reference)
            resolve(response)
          } else {
            reject({message: 'Wrong Username or Password'})
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  verifyOTP({commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      jwt.verifyOtp(payload.userDetails.username, payload.userDetails.otp)
        .then((response) => {
          // If there's user data in response
          if (response.data) {
            // Navigate User to homepage
            // router.push(router.currentRoute.query.to || '/')
            router.push('/loading')

            // Set accessToken
            localStorage.setItem('accessToken', response.data.token)

            // Set bearer token in axios
            commit('SET_BEARER', response.data.token)
            dispatch('fetchUserDetails')
            resolve(response)
          } else {
            reject({message: 'Wrong Email or Password'})
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  verifyOTPWithRef({commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      jwt.verifyOtpWithRef(payload.userDetails.reference_id, payload.userDetails.reference)
        .then((response) => {
          // If there's user data in response
          if (response.data) {
            // Navigate User to homepage
            // router.push(router.currentRoute.query.to || '/')
            router.push('/loading')

            // Set accessToken
            localStorage.setItem('accessToken', response.data.token)

            // Set bearer token in axios
            commit('SET_BEARER', response.data.token)
            dispatch('fetchUserDetails')
            resolve(response)
          } else {
            reject({message: 'Please contact Admin for manual approval'})
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchUserDetails({commit, dispatch}) {
    return new Promise((resolve, reject) => {
      if (!localStorage.getItem('accessToken')) {
        return reject({})
      }
      commit('SET_BEARER', localStorage.getItem('accessToken'))
      axios.post('/auth_check/')
        .then((response) => {
          localStorage.setItem('userInfo', JSON.stringify(response.data))
          commit('UPDATE_USER_INFO', response.data, {root: true})
          localStorage.setItem('organisation', response.data.organisation_name)
          const add = `${response.data.address.address}. ${response.data.address.house_no}, \n${response.data.address.postal},${response.data.address.city}`
          localStorage.setItem('address', add)
          localStorage.setItem('role', response.data.account_role)
          localStorage.setItem('irole', response.data.internal_role)
          localStorage.setItem('template', response.data.template)
          // if (process.env.NODE_ENV === 'development') {
          //  localStorage.setItem('template', '3')
          // }
          localStorage.setItem('enable_web_view', response.data.enable_web_view)
          localStorage.setItem('flag', response.data.flag)

          localStorage.setItem('show_print_pop', response.data.show_print_popup === 1 ? 'true' : 'false')
          localStorage.setItem('merge_receipts', response.data.merge_receipts === 1 ? 'true' : 'false')
          localStorage.setItem('pin_download', response.data.download_csv === 1 ? 'true' : 'false')

          // TODO: Support
          // if(response.data.account_role != 0) {
          //   let userData = response.data
          //   window.fcWidget.init({
          //     token: "7a5cba48-0b0b-4789-af2e-72f33cad43c4",
          //     host: "https://wchat.freshchat.com",
          //     externalId: userData.customer_id,
          //     restoreId: userData.restore_id,
          //     firstName: userData.first_name,
          //     lastName: userData.last_name,
          //     tags: [
          //       userData.parent_organisation_id.toString()
          //     ],
          //   });
          //   window.fcWidget.user.get(function(resp) {
          //     var status = resp && resp.status,
          //       data = resp && resp.data;
          //     if (status !== 200) {
          //       window.fcWidget.user.setProperties({
          //         firstName: userData.first_name,
          //         lastName: userData.last_name,
          //       });
          //       window.fcWidget.on('user:created', function(resp) {
          //         var status = resp && resp.status,
          //           data = resp && resp.data;
          //         if (status === 200) {
          //           if (data.restoreId) {
          //             // Update restoreId in your database
          //             dispatch('updateRestorationId', data.restoreId )
          //           }
          //         }
          //       });
          //     }
          //   });
          // }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchTransactionKey({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/get_transaction_session_key/')
        .then((response) => {
          try {
            localStorage.setItem('t_key', response.data.transaction_key);
          } catch (e) {

          }
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateUserSettings({commit}, payload) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      if (payload) {
        Object.keys(payload).forEach((key) => {
          formData.append(key, payload[key])
        })
      }
      axios.post('/update_settings/', formData)
        .then((response) => {
          localStorage.setItem('show_print_pop', response.data.show_print_popup === 1 ? 'true' : 'false')
          localStorage.setItem('merge_receipts', response.data.merge_receipts === 1 ? 'true' : 'false')
          localStorage.setItem('pin_download', response.data.download_csv === 1 ? 'true' : 'false')
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateShopTimings({commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      if (payload) {
        Object.keys(payload).forEach((key) => {
          formData.append(key, payload[key])
        })
      }
      axios.post('/update_shop_open_timings/', formData)
        .then((response) => {
          dispatch('auth/fetchUserDetails', null, {root: true})
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateRestorationId({commit}, id) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('support_id', id)
      axios.post('/update_support_id/', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchOrgDetails({commit}) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      let host = window.location.host.split(':')[0]
      if (window.location.host.indexOf('www.') != -1) {
        host = host.replace('www.', '')
      }
      host = host.replace('.local', '.de')
      // const host = window.location.host.split(".")[0]
      // const host = "pjtelesoftgmbh"
      formData.append('organisation_name', host)
      axios.post('/p_check/', formData)
        .then((response) => {
          localStorage.setItem('home', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  ,
  fetchUserDynamicBalances({commit}) {
    return new Promise((resolve, reject) => {
      commit('SET_BEARER', localStorage.getItem('accessToken'))
      axios.get('/dynamic_balance/')
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  ,
  logoutUser({commit}) {
    commit('SET_BEARER', localStorage.getItem('accessToken'))
    return new Promise((resolve, reject) => {
      axios.post('/logout/')
        .then((response) => {
          localStorage.clear()
          commit('SET_BEARER', '')
          localStorage.clear()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  ,
}
