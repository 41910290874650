import state from './moduleGiftCardsState'
import mutations from './moduleGiftCardsMutations'
import actions from './moduleGiftCardsActions'
import getters from './moduleGiftCardsGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

