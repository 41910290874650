// axios
import axios from 'axios'
import serialize from "serialize-javascript";
import store from "@/store/store";
import router from "@/router";

// const baseURL = "https://devepins.ey.r.appspot.com"
// const baseURL = 'https://e-aufladen.de/api'
let baseURL = `${window.location.protocol}//${window.location.host}/api`
export let baseHost = `${window.location.protocol}//${window.location.host}`

if (process.env.NODE_ENV === 'development') {
  let host = window.location.host
  // host = host.split('.')[0] + '.de'

  host = host.split('.')[0] + '.de'
  if(window.location.host.indexOf('vps.ovh') === -1) {
    baseURL = `${window.location.protocol}//${host}/api`
  } else {
    baseURL = `http://vps-d5b18cef.vps.ovh.net/api`
  }
  if(window.location.host.indexOf('sandbox-api') != -1) {
    baseURL = `https://sandbox-api.pjtelesoftgmbh.de/api`
  }
  if(window.location.host.indexOf('dev.') != -1) {
    baseURL = `https://dev.pjtelesoftgmbh.de/api`
  }
  if(window.location.host.indexOf('extern.') != -1) {
    baseURL = `https://extern.pjtelesoftgmbh.de/api`
  }
  if(window.location.host.indexOf('staging.') != -1) {
    baseURL = `https://staging.pjtelesoftgmbh.de/api`
  }
  if(window.location.host.indexOf('51.') !== -1) {
    let whost = window.location.host.replace(".local", "");
    whost = whost.split(":")[0]
    baseURL = `${window.location.protocol}//${whost}/api`
  }
  // baseURL = "http://localhost:8000/api";
  // baseURL = "https://pjtelesoftgmbh.de/api";
  // baseURL = 'https://pjtelesoftgmbh.de/api'
}

// const baseURL = `${window.location.protocol}//${window.location.host}/api`
export default axios.create({
  baseURL,
  // You can add your headers here
})
