/*= ========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

export default {
  products: [],
  product: null,
  vPage: 0,
  limit: 50,
  vTotalPages: 0,
  productsRaw: null,
  productsFilters: [],
}

// 0 - employee
// 1 - branch
// 2 - organisation admin
// 3 - we
