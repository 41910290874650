export default {
  SET_GIFT_CARDS(state, giftCards) {
    state.giftCards = giftCards
  },
  UPDATE_GIFT_CARDS(state, giftCards) {
    state.giftCards = [...state.giftCards, giftCards]
  },
  SET_GIFT_CARDS_RAW(state, raw) {
    state.giftCardsRaw = raw

    state.vPage = Number(raw.filters.page)
    state.itemCount = Number(raw.filters.limit)
    state.totalCount = Number(raw.filters.total_count)
    state.vTotalPages = Math.ceil(state.totalCount / state.itemCount)
  },
  SET_GIFT_CARD_DETAILS(state, giftCards) {
    state.giftCardDetails = giftCards
  },
  SET_GIFT_CARD_DETAILS_RAW(state, raw) {
    state.giftCardDetailsRaw = raw
    state.vPage = Number(raw.filters.page)
    state.itemCount = Number(raw.filters.limit)
    state.totalCount = Number(raw.filters.total_count)
    state.vTotalPages = Math.ceil(state.totalCount / state.itemCount)
  },
}
