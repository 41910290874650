/*= ========================================================================================
  File Name: globalComponents.js
  Description: Here you can register components globally
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */


import Vue from 'vue'
import vSelect from 'vue-select'
import VueLocalStorage from 'vue-localstorage'
import VxTooltip from './layouts/components/vx-tooltip/VxTooltip.vue'
import VxCard from './components/vx-card/VxCard.vue'
import VxList from './components/vx-list/VxList.vue'
import VxSlider from './components/vx-slider/VxSlider.vue'
import VxBreadcrumb from './layouts/components/VxBreadcrumb.vue'
import VxBreadcrumbModified from './layouts/components/VxBreadcrumbModified.vue'
import FeatherIcon from './components/FeatherIcon.vue'
import VxInputGroup from './components/vx-input-group/VxInputGroup.vue'
import BreadcrumbBase from './layouts/components/BreadcrumbBase'
import VsTableModified from './layouts/components/custom/VsTableModified'
import VsPaginationModified from './layouts/components/custom/VsPaginationModified'
import VsNewPagination from "@/components/VsNewPagination";


// v-select component

Vue.component(VxTooltip.name, VxTooltip)
Vue.component(VxCard.name, VxCard)
Vue.component(VxList.name, VxList)
Vue.component(VxSlider.name, VxSlider)
Vue.component(VxBreadcrumb.name, VxBreadcrumb)
Vue.component(VxBreadcrumbModified.name, VxBreadcrumbModified)
Vue.component(BreadcrumbBase.name, BreadcrumbBase)
Vue.component(VsTableModified.name, VsTableModified)
Vue.component(VsPaginationModified.name, VsPaginationModified)
Vue.component(VsNewPagination.name, VsNewPagination)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(VxInputGroup.name, VxInputGroup)

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: (createElement) => createElement('feather-icon', {
      props: {
        icon: 'XIcon',
        svgClasses: 'w-4 h-4 mt-1',
      },
    }),
  },
  OpenIndicator: {
    render: (createElement) => createElement('feather-icon', {
      props: {
        icon: 'ChevronDownIcon',
        svgClasses: 'w-5 h-5',
      },
    }),
  },
})

Vue.component(vSelect)

Vue.use(VueLocalStorage)


// function getInputElement(element) {
//   if (element.tagName.toLocaleUpperCase() === 'INPUT') {
//     return element;
//   }
//
//   const inputElements = element.getElementsByTagName('input');
//   if (inputElements.length === 0) {
//     throw new Error('[vue-directive-mask]: v-mask directive requires input element');
//   }
//
//   return inputElements[0];
// }
//
// function onInputListener(event) {
//   event.target.value = event.target.value.replace(".", ",")
// }
//
// Vue.directive('input-mask', {
//   bind(element, binding) {
//     const inputElement = getInputElement(element);
//     inputElement.addEventListener('keydown', onInputListener);
//   },
//   unbinf(element, binding) {
//     const inputElement = getInputElement(element);
//     inputElement.removeEventListener('keydown', onInputListener);
//   }
// });
