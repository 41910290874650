import axios from '@/axios.js'

export default {
  fetchDevices({ commit, state }, { page = '0', payload }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      data.append('page', page)
      data.append('limit', '20')

      if (payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }

      axios.post('/view_devices/', data)
        .then((response) => {
          if (response.data.message_type == 1) {
            commit('SET_DEVICES', response.data.devices)
            commit('SET_DEVICES_RAW', response.data)
          } else {
            reject(error)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // assigned_devices_list

  fetchResellerDevices({ commit, state }, { payload }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      if (payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }
      axios.post('/view_assigned_devices/', data)
        .then((response) => {
          if (response.data.message_type == 1) {
            commit('SET_DEVICES', response.data.assigned_devices_list)
            commit('SET_DEVICES_RAW', response.data)
            resolve(response.data)
          } else {
            reject(error)
          }
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchCustomerDevices({ commit, state }, { payload }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      if (payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }

      axios.post('/view_assigned_devices/', data)
        .then((response) => {
          if (response.data.message_type == 1) {
            resolve(response.data)
          } else {
            reject(error)
          }
        })
        .catch((error) => { reject(error) })
    })
  },
  assignDevicesCustomer({ commit, state }, { payload }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      if (payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }

      axios.post('/add_update_delete_assigned_devices/', data)
        .then((response) => {
          if (response.data.message_type == 1) {
            resolve(response.data)
          } else {
            reject(error)
          }
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteAssignedDevices({ commit, state }, { payload }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      if (payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }

      axios.post('/delete_assigned_devices/', data)
        .then((response) => {
          if (response.data.message_type == 1) {
            resolve(response.data)
          } else {
            reject(error)
          }
        })
        .catch((error) => { reject(error) })
    })
  },
  addUpdateDevices({ commit, state }, { payload }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      if (payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }

      axios.post('/add_update_delete_devices/', data)
        .then((response) => {
          if (response.data.message_type == 1) {
            commit('SET_DEVICES', response.data.devices)
          } else {
            reject(error)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
