import axios from '@/axios'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    reclaimRequests: [],
    reclaimFilters: {},
    reclaimRequestsRaw: null,
    vPage: 0,
    itemCount: 0,
    totalCount: 0,
    vTotalPages: 1,
  },
  mutations: {
    SET_RECLAIM_REQUESTS(state, data) {
      state.reclaimRequests = data
    },
    SET_RECLAIM_REQUESTS_RAW(state, data) {
      state.reclaimRequestsRaw = data
      state.reclaimFilters = data.types_of_filters
      state.vPage = Number(data.filters.page)
      state.itemCount = Number(data.filters.limit)
      state.totalCount = Number(data.filters.total_count)
      state.vTotalPages = Math.ceil(state.totalCount / state.itemCount)
    },
  },
  actions: {
    reclaimPinsNow({ commit, state }, { payload }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()

        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/post_reclam_pins/', data)
          .then((response) => {
            if (response.data.message_type == 1) {
              resolve(response.data)
            } else {
              reject(response)
            }
          })
          .catch((error) => { reject(error) })
      })
    },
    fetchReclaimRequests({ state, commit }, { payload }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }
        data.append('limit', '20')

        axios.post('/view_update_reclam_pins/', data)
          .then((response) => {
            commit('SET_RECLAIM_REQUESTS', response.data.reclamation_list)
            commit('SET_RECLAIM_REQUESTS_RAW', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchSingleReclaim({ state, commit }, reclamationId) {
      return new Promise((resolve, reject) => {
        const data = new FormData()

        data.append("reclamation_id", reclamationId)

        axios.post('/reclam_pin_details/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

