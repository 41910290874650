import axios from '@/axios'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    creditLogs: [],
    creditLedger: [],
  },
  mutations: {
    SET_CREDIT_LOGS(state, data) {
      state.creditLogs = data
    },
    SET_CREDIT_LEDGER(state, data) {
      state.creditLedger = data
    },
  },
  actions: {
    fetchAutoCreditLog({ state, commit }, { payload }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/view_credit_logs/', data)
          .then((response) => {
            commit('SET_CREDIT_LOGS', response.data.credit_logs_list)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchAutoCreditLedger({ state, commit }, { payload }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/view_auto_credit_ledger/', data)
          .then((response) => {
            commit('SET_CREDIT_LEDGER', response.data.auto_credit_ledger_list)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

