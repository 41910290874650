import state from './moduleDynamicProductsState'
import mutations from './moduleDynamicProductsMutations'
import actions from './moduleDynamicProductsActions'
import getters from './moduleDynamicProductsGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

