import axios from '@/axios.js'

export default {
  updatePageNumber({ commit }, page) {
    commit('UPDATE_PAGE', page)
  },
  fetchSingleSupplier({ commit, state }, supplierId) {
    commit('SET_SINGLE_SUPPLIER', null)
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('view_type', '1')
      data.append('supplier_id', supplierId.toString())
      data.append('page', '0')
      data.append('total_items', '10')
      axios.post('/view_suppliers/', data)
        .then((response) => {
          try {
            commit('SET_SINGLE_SUPPLIER', response.data.content[0])
          } catch (e) {

          }
          resolve(response.data.content[0])
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchSuppliers({ commit, state }, {
    page = '0', filter, search, totalItems = '20',
  }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('view_type', '0')
      data.append('page', page)
      if (filter) {
        Object.keys(filter).forEach((key) => {
          data.append(key, filter[key])
        })
      }

      if (search != null && search != '') data.append('search_text', search.toLowerCase())
      data.append('limit', totalItems.toString())
      axios.post('/view_suppliers/', data)
        .then((response) => {
          // if (isMore) commit('UPDATE_SUPPLIERS', response.data.content)
          // else
          commit('SET_SUPPLIERS', response.data.content)
          commit('SET_SUPPLIERS_FILTER', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addNewSupplier({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      data.append('full_name', payload.fullName)
      data.append('address', payload.fullAddress)
      data.append('email', payload.email)
      data.append('company', payload.company)
      data.append('mobile', payload.mobile)
      data.append('telephone', payload.telephone)
      data.append('ustidnr', payload.ustidnr)
      data.append('city', payload.city)
      data.append('country', payload.country)
      data.append('postalcode', payload.postalCode)
      data.append('remarks', payload.remarks)
      data.append('stnr', payload.stNr)
      data.append('status', payload.status)
      if (payload.supplier_id) data.append('supplier_id', payload.supplier_id)

      axios.post(payload.supplier_id ? '/edit_supplier/' : '/add_supplier/', data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deactivateSupplier({ commit, dispatch }, { supplierId, status }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      data.append('status', status)
      data.append('supplier_id', supplierId)

      axios.post('/edit_supplier/', data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
