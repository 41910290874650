import axios from '@/axios'
import Vue from 'vue';

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    categories: [],
    products: [],
    cart: [],
    orders: [],
    vPage: 0,
    itemCount: 20,
    vTotalPages: 0,
  },
  mutations: {
    SET_CATEGORIES(state, data) {
      state.categories = data
    },
    SET_PRODUCTS(state, data) {
      state.products = data
    },
    SET_ORDERS(state, data) {
      state.orders = data
    },
    SET_ORDERS_RAW(state, raw) {
      if (raw.filters) {
        state.vPage = Number(raw.filters.page)
        let limit = Number(raw.filters.limit)
        state.totalCount = Number(raw.filters.count)
        state.vTotalPages = Math.ceil(Number(raw.filters.count) / limit)
      }
    },
    SET_CART_PRODUCT(state, data) {
      let dCart = state.cart;
      state.cart = [];
      console.log(dCart);
      for(let i = 0; i < dCart.length; i++) {
        if(dCart[i].id == data.id) {
          dCart[i].quantity += data.quantity
          Vue.set(state, 'cart', [...dCart]);
          return
        }
      }
      dCart.push(data)
      Vue.set(state, 'cart', [...dCart]);
    },
    CLEAR_CART(state, data) {
      Vue.set(state, 'cart', []);
    },
  },
  actions: {
    fetchHardwareCategories({state, commit}, {payload}) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.get('/hardware_category/', data)
          .then((response) => {
            commit('SET_CATEGORIES', response.data.categories)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchHardwareCategoryProducts({state, commit}, payload) {
      return new Promise((resolve, reject) => {

        axios.get('/hardware_product/', {params: payload})
          .then((response) => {
            commit('SET_PRODUCTS', response.data.products)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchHardwareOrdersList({state, commit}, payload) {
      return new Promise((resolve, reject) => {

        axios.get('/hardware_orders/', {params: { page: payload.page, ...payload.filter }})
          .then((response) => {
            commit('SET_ORDERS', response.data.orders_list)
            commit('SET_ORDERS_RAW', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createHardwareOrder({state, commit}, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }
        axios.post('/hardware_orders/add/', data)
          .then((response) => {
            commit('CLEAR_CART', [])
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  }
}

