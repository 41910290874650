<template>
  <div id="app" :id="customIds" :style="customStyle" :class="vueAppClasses">
    <router-view @setAppClasses="setAppClasses"/>

    <hover-call-component></hover-call-component>
  </div>

</template>

<script>
import themeConfig from '@/../themeConfig.js'
import jwt from '@/http/requests/auth/jwt/index.js'
import router from '@/router'
import HoverCallComponent from "@/views/components/HoverCallComponent";
export default {
  components: {HoverCallComponent}
  ,
  data() {
    return {
      customIds: [],
      vueAppClasses: [],
      customStyle: {},
    }
  },
  watch: {
    '$store.state.theme': function (val) {
      this.toggleClassInBody(val)
      this.checkUpdateTheme()
    },
    '$store.state.AppActiveUser': function (val) {
      this.checkUpdateTheme()
    },
    '$vs.rtl': function (val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr')
    },
  },
  methods: {
    toggleClassInBody(className) {
      if (className === 'dark') {
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
        document.body.classList.add('theme-dark')
      } else if (className === 'semi-dark') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
        document.body.classList.add('theme-semi-dark')
      } else {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    handleWindowResize() {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

      // Set --vh property
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    },
    handleScroll() {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
    },
    checkOrganisation() {
      console.log("checkOrganisation");
      this.$store.dispatch('auth/fetchOrgDetails')
        .then((data) => {
          this.balancesError = false
          this.fetchingBalances = false
          if (data.message_type == 1) {

          } else {
            this.$router.push({path: '/pages/error-404'})
          }
        })
        .catch((error) => {
          console.log(error)
          this.$router.push({path: '/pages/error-404'})
        })
    },

    checkUpdateTheme() {

      // TODO: Set Template here
      if (localStorage.getItem('role') == 2) {
        if (localStorage.getItem('template') == 'null' || localStorage.getItem('template') == 4) {
          this.$store.commit('UPDATE_MAIN_LAYOUT_TYPE', 'horizontal')
          this.$store.dispatch('updateTheme', 'light')
          this.$vs.theme({primary: '#2f1c6d'})
        }
        if (localStorage.getItem('template') == 'null' || localStorage.getItem('template') == 3) {
          this.$store.commit('UPDATE_MAIN_LAYOUT_TYPE', 'horizontal')
          this.$store.dispatch('updateTheme', 'light')
          this.$vs.theme({primary: '#17252a'})
        } else if (localStorage.getItem('template') == 'null' || localStorage.getItem('template') == 0) {
          this.$store.commit('UPDATE_MAIN_LAYOUT_TYPE', 'horizontal')
          this.$store.dispatch('updateTheme', 'light')
          this.$vs.theme({primary: '#00a1f1'})
        } else if (localStorage.getItem('template') == 'null' || localStorage.getItem('template') == 5) {
          this.$store.commit('UPDATE_MAIN_LAYOUT_TYPE', 'horizontal')
          this.$store.dispatch('updateTheme', 'light')
          this.$vs.theme({primary: '#085396'})
          this.customIds = [
            "template5"
          ]
          this.customStyle = {
            // 'background-color': 'rgba(59, 130, 246, 0.1)'
            'background-color': '#dce0eb'
          }
        } else if (localStorage.getItem('template') == 'null' || localStorage.getItem('template') == 1) {
          this.$store.commit('UPDATE_MAIN_LAYOUT_TYPE', 'vertical')
          this.$store.dispatch('updateTheme', 'dark')
          this.$vs.theme({primary: '#00a1f1'})
        } else if (localStorage.getItem('template') == 'null' || localStorage.getItem('template') == 2) {
          this.$store.commit('UPDATE_MAIN_LAYOUT_TYPE', 'horizontal')
          this.$store.dispatch('updateTheme', 'light')
          this.$vs.theme({primary: '#7cbb00'})
        }
      }
    }

  },
  mounted() {
    this.toggleClassInBody(themeConfig.theme)
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    const recaptcha = this.$recaptchaInstance

    if (localStorage.getItem('accessToken')) {
      this.$store.dispatch('auth/fetchUserDetails')
    }
    this.checkOrganisation()
    this.checkUpdateTheme()

    window.addEventListener('storage',  (event) => {
      if (event.key === 'accessToken' && !event.newValue) {
        this.$router.push({path: '/'})
      }
    });
  },
  async created() {
    // jwt
    jwt.init()

    const dir = this.$vs.rtl ? 'rtl' : 'ltr'
    document.documentElement.setAttribute('dir', dir)

    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
}

</script>
<style lang="scss">

.vx-card {
  box-shadow: 0px 4px 25px 0px #1f1f1f14 !important;
}

.vs-con-table .vs-table--thead th .vs-table-text {
  justify-content: center !important;
  place-content: center;
}

.vs-con-table .vs-table tr {
  text-align: center;
  place-content: center;
}

td.td.vs-table--td.whitespace-no-wrap.vx-row {
  place-content: center;
}

.custom-table {
  .product-image {
    /*max-width: 200px;*/
    max-height: 40px;
    object-fit: cover;
  }

  .supplier-image {
    /*max-width: 200px;*/
    max-height: 40px;
    object-fit: cover;
  }

  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .customer-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .supplier-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}


.status-image > svg {
  width: 100px;
  height: 100px;
}

.status-card > h4 {
  margin-top: 24px;
}

.origin {
  width: 100px;
  height: 100px;
  position: relative;
  -webkit-animation: spin 6s cubic-bezier(0.8, 0, 0.2, 1) infinite;
  animation: spin 6s cubic-bezier(0.8, 0, 0.2, 1) infinite;
}

.origin > svg {
  width: 100px;
  height: 100px;
}

@-webkit-keyframes spin {
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
  .router-content {
    margin-top: 0px !important;
  }
}
</style>
