import state from './moduleCashCardsState'
import mutations from './moduleCashCardsMutations'
import actions from './moduleCashCardsActions'
import getters from './moduleCashCardsGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

