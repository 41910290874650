export default {
  SET_SUPPLIERS(state, suppliers) {
    state.suppliers = suppliers
  },
  SET_SINGLE_SUPPLIER(state, supplier) {
    state.supplier = supplier
  },
  SET_SUPPLIERS_FILTER(state, data) {
    state.vPage = data.page
    state.limit = Number(data.limit)
    state.vTotalPages = Math.ceil(Number(data.total_count) / Number(data.limit))
    state.suppliersFilters = data.filters
  },
  UPDATE_SUPPLIERS(state, suppliers) {
    state.suppliers.push(...suppliers)
  },
}
