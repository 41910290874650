import axios from '@/axios'
import Vue from 'vue';

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    customers: [],
    customerDetails: [],
    salesData: [],
    customer: null,
    customersRaw: null,
    customerDetailsRaw: null,
    vPage: 0,
    vLimit: 0,
    vTotalCount: 0,
    vTotalPages: 0,
    vPage2: 0,
    vLimit2: 0,
    vTotalCount2: 0,
    vTotalPages2: 0,
    customerFilters: [],
    salesFilters: {}
  },
  mutations: {
    SET_REPORT_CUSTOMERS(state, customers) {
      state.customers = customers
    },
    SET_REPORT_CUSTOMER_DETAILS(state, customerDetails) {
      state.customerDetails = customerDetails
    },
    SET_REPORT_CUSTOMER_DETAILS_RAW(state, raw) {
      state.customerDetailsRaw = raw
      state.vTotalCount2 = Number(raw.filters.total_count)
      state.vPage2 = Number(raw.filters.page)
      state.vTotalPages2 = Math.ceil(Number(raw.filters.total_count) / Number(raw.filters.limit))
      // state.salesFilters = raw.type_of_filters
    },
    SET_REPORT_CUSTOMERS_RAW(state, raw) {
      state.customersRaw = raw
      state.vTotalCount = Number(raw.filters.total_count)
      state.vPage = Number(raw.filters.page)
      state.vTotalPages = Math.ceil(Number(raw.filters.total_count) / Number(raw.filters.limit))
      // state.salesFilters = raw.type_of_filters
    },
    RESET_CUSTOMER_SALES_DATA(state) {
      let dCustomers = state.customers;
      state.customers = [];
      for(let i = 0; i < dCustomers.length; i++) {
        dCustomers[i] = {
          ...dCustomers[i],
          total_sale_amount: null,
          total_buying_amount: null,
          total_sale_amount_2: null,
          total_buying_amount_2: null,
          difference: null,
          difference_percentage: null
        }
      }
      Vue.set(state, 'customers', [...dCustomers]);
    },
    UPDATE_CUSTOMER_SALES_DATA(state, data) {
      let dCustomers = state.customers;
      state.customers = [];
      for(let i = 0; i < dCustomers.length; i++) {
          if(dCustomers[i].product_configuration_id == data.filters.product_configuration_id &&
            dCustomers[i].super_admin_discount == data.filters.super_admin_discount) {

            let buyingAmount = data.total_buying_amount;
            if(localStorage.getItem("role") == '0') {
              buyingAmount =  data.total_buying_price;
            }
            dCustomers[i] = {
              ...dCustomers[i],
              total_sale_amount: data.total_sale_amount,
              total_buying_amount: buyingAmount,
              total_profit: data.total_profit,
              total_quantity: data.total_quantity,
            }
          }
      }
      Vue.set(state, 'customers', [...dCustomers]);
    },
    UPDATE_CUSTOMER_DETAILS_SALES_DATA(state, data) {
      let dCustomers = state.customerDetails;
      state.customerDetails = [];
      for(let i = 0; i < dCustomers.length; i++) {
          if(dCustomers[i].product_configuration_id == data.filters.product_configuration_id &&
            dCustomers[i].super_admin_discount == data.filters.super_admin_discount &&
            dCustomers[i].customer_id == data.filters.customer_id) {

            let buyingAmount = data.total_buying_amount;
            if(localStorage.getItem("role") == '0') {
              buyingAmount =  data.total_buying_price;
            }
            dCustomers[i] = {
              ...dCustomers[i],
              total_sale_amount: data.total_sale_amount,
              total_buying_amount: buyingAmount,
              total_profit: data.total_profit,
              total_quantity: data.total_quantity,
            }
          }
      }
      Vue.set(state, 'customerDetails', [...dCustomers]);
    },
  },
  actions: {
    resetCustomersSalesData({commit}) {
      commit('RESET_CUSTOMER_SALES_DATA')
    },
    fetchReportCustomers({ commit, state }, { filters, page = '0', search = '', limit = '10' }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()

        Object.keys(filters).forEach((key) => {
          data.append(key, filters[key])
        })
        data.append('page', page)
        data.append('limit', limit)
        if (search != null && search != '') data.append('search_text', search.toLowerCase())

        axios.post('/get_sales_summary_report_with_customer_and_products_list/', data)
          .then((response) => {
            commit('SET_REPORT_CUSTOMERS', response.data.customers_with_products)
            commit('SET_REPORT_CUSTOMERS_RAW', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    fetchSalesSummaryDetailCustomer({ commit, state }, { filters, page = '0', search = '', limit = '10' }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()

        Object.keys(filters).forEach((key) => {
          data.append(key, filters[key])
        })
        data.append('page', page)
        data.append('limit', limit)
        if (search != null && search != '') data.append('search_text', search.toLowerCase())

        axios.post('/get_sales_summary_report_details/', data)
          .then((response) => {
            commit('SET_REPORT_CUSTOMER_DETAILS', response.data.customers_with_products)
            commit('SET_REPORT_CUSTOMER_DETAILS_RAW', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    getSalesSummaryDataWithCustomers({ commit, state, context }, { filters, cancelToken, type }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()

        if (filters) {
          Object.keys(filters).forEach((key) => {
            data.append(key, filters[key])
          })
        }

        axios.post('/get_sales_report_counters/', data)
          .then((response) => {

            // type = 2 for Prduct Detail Customer
            if(type == 2) {
              commit('UPDATE_CUSTOMER_DETAILS_SALES_DATA', response.data)
            } else {
              commit('UPDATE_CUSTOMER_SALES_DATA', response.data)
            }
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
  },
  getters: {
    customers(state) {
      return state.customers;
    },
  }
}

