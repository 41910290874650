import Vue from "vue";

export default {
  SET_CASH_CARDS(state, cashCards) {
    state.cashCards = cashCards
    // console.log('cards status:', state.cashCards)
  },
  UPDATE_CASH_CARDS(state, cashCards) {
    Vue.set(state, 'cashCards', [...state.cashCards, ...cashCards]);
  },
  SET_FAV_CASH_CARDS(state, cashCards) {
    state.favCashCards = cashCards
    state.dummyFavCashCards = cashCards
  },
  ARRANGE_FAV_CASH_CARDS(state, {oldIndex, newIndex}) {
    Array.prototype.insert = function ( index, item ) {
      this.splice( index, 0, item );
    };
    let cards = state.favCashCards;
    // const b = cards[oldIndex];
    const b = cards.splice(oldIndex, 1)[0];
    // cards[oldIndex] = cards[newIndex];
    // cards[newIndex] = b;
    cards.insert(newIndex, b)
    // if(oldIndex > newIndex) {
    //   cards =
    // } else {
    //   cards = cards.splice(oldIndex, 1);
    // }
    // console.log(cards)
    state.favCashCards = []
    state.favCashCards = cards
  },
  RESET_FAV_CASH_CARDS(state) {
    state.favCashCards = []
    state.favCashCards = state.dummyFavCashCards
  }
}
