import Vue from 'vue'
import Vuesax from 'vuesax'
import VueClipboard from 'vue-clipboard2'
import VueTour from 'vue-tour'
import VeeValidate from 'vee-validate'
import { VueHammer } from 'vue2-hammer'
import DatePicker from 'vue2-datepicker'
import VueFormWizard from 'vue-form-wizard/src'

// Vuesax Component Framework
import 'material-icons/iconfont/material-icons.css' // Material Icons
import 'vuesax/dist/vuesax.css'

// FontAwesomeIcons

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core'

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import App from './App.vue'

// axios
import axios from './axios.js'

// Theme Configurations
import '../themeConfig.js'

// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import './assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// i18n
import i18n from './i18n/i18n'


// Vuexy Admin Filters
import './filters/filters'


// Clipboard


// Tour


// VeeValidate


// Vuejs - Vue wrapper for hammerjs


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(faWhatsapp)
// Vuesax
Vue.use(Vuesax)
Vue.prototype.$http = axios
Vue.use(VueClipboard)
Vue.use(VueTour)
require('vue-tour/dist/vue-tour.css')

Vue.use(VeeValidate)
Vue.use(VueHammer)
Vue.use(DatePicker)

Vue.use(VueFormWizard)


// Feather font icon
require('./assets/css/iconfont.css')


// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';


Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')



var addEvent = function (el, type, fn) {
  if (el.addEventListener)
    el.addEventListener(type, fn, false);
  else
    el.attachEvent('on'+type, fn);
};

var extend = function(obj,ext){
  for(var key in ext)
    if(ext.hasOwnProperty(key))
      obj[key] = ext[key];
  return obj;
};

window.fitText = function (el, kompressor, options) {

  var settings = extend({
    'minFontSize' : -1/0,
    'maxFontSize' : 1/0
  },options);

  var fit = function (el) {
    try {
      var compressor = (el.innerText.trim().length / 16) || 1;

      if(el.innerText.trim().length < 12) {
        return
      }
      if(el.clientHeight > 20) {
        return;
      }

      var resizer = function () {

        if(el.clientWidth > 0) {
          el.style.fontSize = Math.max(Math.min(el.clientWidth / (compressor*10), parseFloat(settings.maxFontSize)), parseFloat(settings.minFontSize)) + 'px';
        } else {
          let fakeWidth = el.innerText.trim().length * 10
          el.style.fontSize = Math.max(Math.min(fakeWidth / (compressor*10)
            ,parseFloat(settings.maxFontSize)), parseFloat(settings.minFontSize)) + 'px';
        }
      };
    } catch (e) {}

    // Call once to set.
    resizer();

    // Bind events
    // If you have any js library which support Events, replace this part
    // and remove addEvent function (or use original jQuery version)
    addEvent(window, 'resize', resizer);
    addEvent(window, 'orientationchange', resizer);
  };

  try {
    if (el.length)
      for(var i=0; i<el.length; i++)
        fit(el[i]);
    else
      fit(el);
  } catch (e) {

  }

  // return set of elements
  return el;
};
