export default {
  sales: [],
  salesCounters: {},
  salesRecordDetails: {},
  typeOfFilters: {},
  productsFilter: {},
  customerFilter: {},
  filter: {},
  vPage: 0,
  itemCount: 20,
  totalCount: 0,
  vTotalPages: 0,
  salesRaw: null,
  // Stock Report
  stock: [],
  inventoryHistory: [],
  invoices: null,
}

// 0 - employee
// 1 - branch
// 2 - organisation admin
// 3 - we
