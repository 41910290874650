export default {
  SET_DYNAMIC_PRODUCTS(state, dynamicProducts) {
    state.dynamicProducts = dynamicProducts
  },
  SET_DYNAMIC_PRODUCTS_RAW(state, raw) {
    state.dynamicProductsRaw = raw
    state.vTotalCount = Number(raw.filters.total_count)
    state.vPage = Number(raw.filters.page)
    state.limit = Number(raw.filters.limit)
    state.vTotalPages = Math.ceil(state.vTotalCount / state.limit)
  },
  UPDATE_DYNAMIC_PRODUCTS(state, dynamicProducts) {
    state.dynamicProducts.push(...dynamicProducts)
  },
  SET_SOFTAPI_CARDS(state, softApiCards) {
    state.softApiCards = softApiCards
  },
}
