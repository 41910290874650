import axios from '@/axios'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    loginRequests: [],
    loginFilters: {},
    vPage: 0,
    itemCount: 0,
    totalCount: 0,
    vTotalPages: 1,
  },
  mutations: {
    SET_LOGIN_REQUESTS(state, data) {
      state.loginRequests = data
    },
    SET_LOGIN_REQUESTS_RAW(state, data) {
      state.loginFilters = data.types_of_filters

      state.vPage = Number(data.filters.page)
      state.itemCount = Number(data.filters.limit)
      state.totalCount = Number(data.filters.total)
      state.vTotalPages = Math.ceil(state.totalCount / state.itemCount)
    },
  },
  actions: {
    approveRequest({ state }, { payload }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/update_login_requests/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchLoginRequests({ state, commit }, { payload }) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/view_login_requests/', data)
          .then((response) => {
            commit('SET_LOGIN_REQUESTS', response.data.login_requests_list)
            commit('SET_LOGIN_REQUESTS_RAW', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

