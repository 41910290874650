import axios from '@/axios'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    terminals: [],
    vPage: 0,
    itemCount: 0,
    totalCount: 0,
    vTotalPages: 1,
  },
  mutations: {
    SET_TERMINALS(state, data) {
      state.terminals = data
    },

    SET_TERMINALS_RAW(state, data) {
      state.vPage = Number(data.filters.page)
      state.itemCount = Number(data.filters.limit)
      state.totalCount = Number(data.filters.total_count)
      state.vTotalPages = Math.ceil(state.totalCount / state.itemCount)
    },
  },
  actions: {
    fetchEPayTerminalsList({state, commit}, {payload}) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/list_sellers_with_terminal_ids/', data)
          .then((response) => {
            if(response.data.message_type === 1) {
              commit('SET_TERMINALS', response.data.organisations)
              commit('SET_TERMINALS_RAW', response.data)
              resolve(response.data)
            } else {
              reject(response.data);
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    downloadEPayTerminalsList({state, commit}, {payload}) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/list_sellers_with_terminal_ids/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

