import axios from '@/axios.js'

export default {
  fetchAllPADProducts({ commit, state }, { userId, productType }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      if (userId) data.append('customer_id', userId)
      if (productType) data.append('product_type', productType)

      axios.post('/view_pad/', data)
        .then((response) => {
          commit('SET_PRODUCTS_DATA', response.data.products)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchData({ commit, state }, { userId, productType }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      if (userId) data.append('customer_id', userId)
      if (productType) data.append('product_type', productType)

      axios.post('/view_pad/', data)
        .then((response) => {
          commit('SET_PRODUCTS_DATA', response.data.unassigned_product_configurations)
          commit('SET_PRODUCTS_ASSIGNED_DATA', response.data.assigned_product_configurations)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  operationOnPADMultiCustomers({ commit, dispatch }, { userIds, padList, discount_plan_id }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('customer_ids', userIds)
      data.append('pad_list', padList)
      if (discount_plan_id) data.append('discount_plan_id', discount_plan_id)

      axios.post('/add_update_delete_disable_pad/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  operationOnPAD({ commit, dispatch }, { userId, padList, discount_plan_id }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('customer_id', userId)
      data.append('pad_list', padList)
      if (discount_plan_id) data.append('discount_plan_id', discount_plan_id)

      axios.post('/add_update_delete_disable_pad/', data)
        .then((response) => {
          commit('SET_PRODUCTS_DATA', response.data.unassigned_product_configurations)
          commit('SET_PRODUCTS_ASSIGNED_DATA', response.data.assigned_product_configurations)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchDiscountSlabs({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.post('/view_discount_plan/')
        .then((response) => {
          commit('SET_DISCOUNT_SLABS', response.data.discount_plans)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchDiscountSlabProducts({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })
      axios.post('/view_discount_plan/', data)
        .then((response) => {
          // commit('SET_DISCOUNT_SLABS', response.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateDiscountSlabProducts({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })
      axios.post('/update_configuration_slab/', data)
        .then((response) => {
          // commit('SET_DISCOUNT_SLABS', response.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addDiscountSlabs({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })
      axios.post('/add_discount_plan/', data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
