export default {
  SET_USERS(state, users) {
    state.users = users
  },
  UPDATE_USERS(state, sales) {
    state.users.push(...sales)
  },
  SET_USERS_RAW(state, raw) {
    if (raw.filter) {
      state.vPage = Number(raw.filter.page)
      state.itemCount = Number(raw.filter.count)
      state.totalCount = Number(raw.filter.total_count)
      state.vTotalPages = Math.ceil(state.totalCount / state.itemCount)
    } else {
      state.vPage = 0
      state.itemCount = 20
      state.totalCount = 0
      state.vTotalPages = 1
    }
    state.usersRaw = raw
  },
  SET_CUSTOMER_WALLET_HISTORY_10(state, history) {
    state.walletHistory = history
  },
}
