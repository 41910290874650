import axios from '@/axios.js'

export default {
  fetchInventory({ commit, state }, { isMore = false, totalItems = 10 }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('view_type', '0')
      if (isMore) data.append('page', (state.vPage + 1).toString())
      else data.append('page', state.vPage.toString())
      // if(filter) {
      //   data.set('page', '0');
      //   Object.keys(filter).forEach(key => {
      //     data.append(key, filter[key]);
      //   })
      // }
      // if(search) data.append('search', search.toLowerCase());
      data.append('total_items', totalItems ? totalItems.toString() : '10')
      axios.post('/inventory_list/', data)
        .then((response) => {
          if (isMore) commit('UPDATE_INVENTORY', response.data.content)
          else commit('SET_INVENTORY', response.data.content)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  uploadCSVFile({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('csv_file', payload.inventoryCsv)
      data.append('delimiter', payload.delimiter)

      axios.post('/upload_file/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  uploadStepTwo({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('configuration_id', payload.configuration)
      data.append('pin_sequence', payload.pinSeq)
      data.append('batch_sequence', payload.batchSeq)
      data.append('import_batch', payload.batchNo)
      data.append('supplier_id', payload.supplierId)
      data.append('buying_discount', payload.discount)

      axios.post('/background_task_step_two/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  downloadInvalidPins({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('import_batch', payload.import_batch)

      axios.post('/failed_pins_list/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  cancelInventoryUpload({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('import_batch', payload.import_batch)
      data.append('cancel', 1)
      // data.append('supplier_id', "0")
      // data.append('buying_discount', "0")

      axios.post('/upload_step_three/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  approveInventoryUpload({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('import_batch', payload.import_batch)
      data.append('supplier_id', payload.supplierId)
      data.append('buying_discount', payload.discount)

      axios.post('/upload_step_three/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchSupplierDiscount({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('product_configuration_id', payload.configuration)
      data.append('supplier_id', payload.supplierId)

      axios.post('/previous_supplier_discount/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  uploadStepThree({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('supplier_id', payload.supplierId)
      data.append('buying_discount', payload.discount)
      data.append('import_batch', payload.batchNo)

      axios.post('/upload_step_three/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
}
