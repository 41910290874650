import serialize from 'serialize-javascript'
import xss from 'xss'
import axios from '../../axios'
import router from '../../router'
import store from '../../store/store'

function logout() {
  localStorage.removeItem('accessToken')
  localStorage.clear()
  router.push('/login')
}

// Add a request interceptor
axios.interceptors.request.use((config) => {
  if (localStorage.getItem('accessToken') != null
    && localStorage.getItem('accessToken') !== undefined
    && localStorage.getItem('accessToken') !== '') {
    config.headers = {}
    if (config.url.indexOf('p_check') == -1) {
      config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
      try {
        config.headers['customer'] = `${JSON.parse(localStorage.getItem('userInfo')).customer_id}`
        config.headers['session'] = `${JSON.parse(localStorage.getItem('userInfo')).session_id}`
      } catch (e) {
        config.headers['customer'] = 'arjun';
        config.headers['session'] = 'arjun-session';
      }
      try {
        // let time = Number(localStorage.getItem('active')) + 10000
        let time = Number(localStorage.getItem('active')) + 10800000
        if(time > 10800000) {
          let cTime = (new Date()).getTime()
          if (cTime > time) {
            logout()
            config.headers.Authorization = ``
            return config
          } else {
            localStorage.setItem('active', (new Date()).getTime().toString())
            return config
          }

        } else {
          localStorage.setItem('active', (new Date()).getTime().toString())
        }
      } catch (e) {

      }
    }
    else {
      delete config.headers.Authorization
    }
  }
  return config
})
function reviver(key, value) {
  // Filtering out properties
  if (typeof value == 'string') {
    return xss(value)
  }
  return value
}

axios.interceptors.response.use((response) => {
  // const serializedString = serialize(response.data, { isJSON: true })
  // response.data = JSON.parse(serializedString, reviver)

  if (response.data.hasOwnProperty('account_status') && response.data.account_status == 0) {
    localStorage.removeItem('accessToken')
    localStorage.clear()
    store.commit('UPDATE_MAIN_LAYOUT_TYPE', 'horizontal')
    store.dispatch('updateTheme', 'light')
    router.push('/login').then(() => {
      window.location.reload()
      return Promise.reject({
        response,
      })
    }).catch(() => {})
  }

  if (response.data.hasOwnProperty('message_type') && response.data.message_type == 0) {
    return Promise.reject({
      response,
    })
  }
  let time = Date.now()
  let fTime = localStorage.getItem('auth_check_fetch') || 0
  if(localStorage.getItem('role') == 2) {
    if (time - fTime > 15000) {
      if (response.config.url.indexOf('p_check') == -1 && response.config.url.indexOf('auth_check') == -1 && response.config.headers.Authorization != undefined) {
        store.dispatch('auth/fetchUserDetails', null, {root: true})
        localStorage.setItem('auth_check_fetch', Date.now())
      }
    }
  }

  return response
}, (error) => {
  const { response } = error
  // if (status === 401) {
  // if (response && response.status === 401) {
  //   logout()
  //   router.push('/login')
  // }
  if(response.status == 401) {
    try {
      if(response.config.url.indexOf('login') == -1 && response.config.url.indexOf('verify') == -1) {
        response.data['message'] = "Session beendet. Bitte erneuert Einloggen"
      }
    } catch (e) {

    }
  }

  if (response.data.hasOwnProperty('account_status') && response.data.account_status == 0) {
    localStorage.removeItem('accessToken')
    localStorage.clear()
    store.commit('UPDATE_MAIN_LAYOUT_TYPE', 'horizontal')
    store.dispatch('updateTheme', 'light')
    router.push('/login').then(() => {
      window.location.reload()
    }).catch(() => {})
  }
  return Promise.reject({
    response,
    error,
  })
})

export default axios
