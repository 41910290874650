import axios from '@/axios.js'
import { baseHost } from '@/axios.js'
import countryCodes from '@/intl/codes.js'


export default {
  clearStateData({ commit, state }) {
    commit('SET_PROVIDERS', [])
  },
  fetchCountries({ commit, state }) {
    return new Promise((resolve, reject) => {

      commit('SET_COUNTRIES', countryCodes)
      resolve(countryCodes)
    })
  },
  // Mobile Operators
  fetchRegions({ commit, state }, mobile) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      data.append('configuration', '1')
      data.append('mobile', mobile)
      data.append('per_page', '100')
      data.append('page', '1')

      axios.post('/dt_one/', data)
        .then((response) => {
          try {
            commit('SET_REGIONS', response.data)
          } catch (e) {
            console.log(e)
          }
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchProviders({ commit, state }, { countryCode, region }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      data.append('configuration', '3')
      data.append('country_code', countryCode)
      data.append('region_code', region)

      axios.post('/get_international_top_up/', data)
        .then((response) => {
          try {
            commit('SET_PROVIDERS', response.data.providers_list)
          } catch (e) {
            console.log('Error is:', e)
          }
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  mobileLookUp({ commit, state }, mobileNo) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      data.append('configuration', '1')
      data.append('mobile', mobileNo)
      data.append('per_page', '100')
      data.append('page', '1')

      axios.post('/dt_one/', data)
        .then((response) => {
          commit('SET_PROVIDERS', response.data.response)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchProducts({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      data.append('configuration', '2')
      data.append('country_iso_code', payload.country_code)
      data.append('operator_id', payload.provider_code)
      data.append('per_page', '100')
      data.append('page', '1')
      if(payload.plan_type) {
        data.append('plan_type', payload.plan_type)
      }

      axios.post('/dt_one/', data)
        .then((response) => {
          localStorage.setItem('t_key', response.data.transaction_key)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  checkStatus({ commit, state }, transactionId) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      data.append('configuration', '41')
      data.append('transaction_id', transactionId)

      axios.post(`/dt_one/`, data)
        .then((response) => {
          console.log(data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // createConfirmRejectRecharge({ commit, dispatch }, payload) {
  //   return new Promise((resolve, reject) => {
  //     const data = new FormData()

  //     Object.keys(payload).forEach((key) => {
  //       data.append(key, payload[key])
  //     })

  //     if (payload['configuration'] && payload['configuration'] == '6' && localStorage.getItem('t_key')) {
  //       let key = localStorage.getItem('t_key');
  //       data.append('transaction_key', key)
  //       localStorage.removeItem('t_key');
  //     }

  //     axios.post('/dt_one/', data)
  //       .then((response) => {

  //         resolve(response.data)
  //       })
  //       .catch((error) => {
  //         reject(error)
  //       })
  //   })
  // },

  // Arjun's Optimised Code
  createConfirmRejectRecharge({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      const { configuration } = payload
      console.log('configuration', configuration)
      // Add input validation to ensure that the `configuration` property is present in the payload
      if (!configuration) {
        throw new Error('configuration is required')
      }

      // Use `Object.entries` instead of `Object.keys` to get both the key and value at once
      Object.entries(payload).forEach(([key, value]) => {
        console.log(`key: ${key}, value: ${value}`)
        data.append(key, value)
      })
      // Object.keys(payload).forEach((key) => {
      //   data.append(key, payload[key])
      // })
      // data.append('product_id', payload.product_id)
      // data.append('mobile_number', payload.mobile_number)
      // data.append('configuration', payload.configuration)
      console.log('Values are:', [...data.entries()])
      // Use a ternary operator instead of an if statement
      configuration === '6' && localStorage.getItem('t_key') ? data.append('transaction_key', localStorage.getItem('t_key')) && localStorage.removeItem('t_key') : null

      // Use `const` instead of `let` for the `key` variable
      const key = localStorage.getItem('t_key')
      configuration === '6' && key ? data.append('transaction_key', key) && localStorage.removeItem('t_key') : null

      // Refactored the `axios` call to use the `catch` block to handle errors more gracefully

      axios.post('/dt_one/', data)
        .then((response) => {
          console.log('Response:', [...data.entries()])
          resolve(response.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        }).finally(() => {
          // Perform any necessary cleanup tasks here, such as closing loading indicators or resetting form inputs
          console.log('Request complete')
        })
    })
  },


  rechargeMobileNo({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      axios.post('/international_top_up_transaction/', data)
        .then((response) => {
          dispatch('auth/fetchUserDetails', null, { root: true })
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
