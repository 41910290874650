export default {
  RESET(state) {
    state.customers = []
    state.customer = null
    state.customersRaw = null
    state.vPage = 0
    state.vTotalCount = 0
    state.vTotalPages = 0
    state.customerFilters = []
    state.walletHistory = []
    state.walletHistoryRaw = null
  },
  SET_CUSTOMERS(state, customers) {
    state.customers = customers
  },
  UPDATE_CUSTOMERS(state, customers) {
    state.customers.push(...customers)
  },
  SET_CUSTOMERS_RAW(state, raw) {
    state.customersRaw = raw
    state.vTotalCount = Number(raw.filters.total_count)
    state.vPage = Number(raw.filters.page)
    state.vTotalPages = Math.ceil(Number(raw.filters.total_count) / Number(raw.filters.limit))
  },
  SET_CUSTOMER_WALLET_HISTORY_10(state, history) {
    state.walletHistory = history
  },
  UPDATE_CUSTOMER_WALLET_HISTORY_10(state, history) {
    state.walletHistory.push(...history)
  },
  SET_CUSTOMER_WALLET_HISTORY_10_RAW(state, raw) {
    state.walletHistoryRaw = raw
    state.vTotalCount = Number(raw.filters.total_count)
    state.vPage = Number(raw.filters.page)
    state.vLimit = Number(raw.filters.limit)
    state.vTotalPages = Math.ceil(state.vTotalCount / state.vLimit)
  },
}
