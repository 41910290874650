export default {
  SET_PROVIDERS(state, providers) {
    state.providers = providers
  },
  SET_PRODUCTS(state, products) {
    state.products = products
  },
  SET_TRANSACTIONS(state, transactions) {
    state.transactions = transactions
  },
}
