import axios from '@/axios.js'

export default {
  updatePageNumber({ commit }, page) {
    commit('UPDATE_PAGE', page)
  },
  fetchSalesReports({ commit, state }, { page = '0', filter }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      data.append('page', page)
      data.append('limit', '20')
      if (filter) {
        Object.keys(filter).forEach((key) => {
          data.append(key, filter[key])
        })
      }

      axios.post('/get_sales_report_list/', data)
        .then((response) => {
          commit('SET_SALES', response.data.sells_report)
          commit('SET_SALES_RAW', response.data)
          if (page == '0') commit('SET_TYPES_FILTER', response.data.type_of_filters)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchSalesReportCounters({ commit, state }, { page = '0', filter }) {

    commit('SET_SALES_COUNTERS', {})
    return new Promise((resolve, reject) => {
      const data = new FormData()

      if (filter) {
        Object.keys(filter).forEach((key) => {
          data.append(key, filter[key])
        })
      }

      axios.post('/get_sales_report_counters/', data)
        .then((response) => {
          commit('SET_SALES_COUNTERS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchSalesReportSessions({ commit, state }, filter) {

    commit('SET_SALES_RECORD_DETAILS', {});
    return new Promise((resolve, reject) => {
      axios.get('/get_sales_record_details/', { params: filter })
        .then((response) => {
          commit('SET_SALES_RECORD_DETAILS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchDynamicProductsLog({ commit, state }, { page = '0', filter }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      data.append('page', page)
      data.append('limit', '20')
      if (filter) {
        Object.keys(filter).forEach((key) => {
          data.append(key, filter[key])
        })
      }

      axios.post('/view_dynamic_product_logs/', data)
        .then((response) => {
          commit('SET_SALES', response.data.dynamic_product_log_list)
          commit('SET_SALES_RAW', response.data)
          if (page == '0') commit('SET_TYPES_FILTER', response.data.types_of_filters)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchPaymentLedger({ commit, state, dispatch }, { page = '0', filter }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      data.append('page', page)
      data.append('limit', '20')
      if (filter) {
        Object.keys(filter).forEach((key) => {
          data.append(key, filter[key])
        })
      }

      if (localStorage.getItem('irole') == 1) {
        data.append('user_id', JSON.parse(localStorage.getItem('userInfo')).user_id)
      }

      axios.post('/view_ledger/', data)
        .then((response) => {
          commit('SET_SALES', response.data.ledger_list)
          commit('SET_SALES_RAW', response.data)
          dispatch('auth/fetchUserDetails', null, { root: true })
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchCustomerPaymentLedger({ commit, state }, { page = '0', filter }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      data.append('page', page)
      data.append('limit', '20')
      if (filter) {
        Object.keys(filter).forEach((key) => {
          data.append(key, filter[key])
        })
      }

      if (localStorage.getItem('irole') == 1) {
        data.append('user_id', JSON.parse(localStorage.getItem('userInfo')).user_id)
      }

      axios.post('/view_customer_ledger/', data)
        .then((response) => {
          commit('SET_SALES', response.data.ledger_list)
          commit('SET_SALES_RAW', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  searchCustomers({ commit, state }, { filter }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      if (filter) {
        Object.keys(filter).forEach((key) => {
          data.append(key, filter[key])
        })
      }

      axios.post('/search_customer/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchDownloadSalesReports({ commit, state }, { isMore = false, filter }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(filter).forEach((key) => {
        data.append(key, filter[key])
      })

      axios.post('/get_sales_report_list/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchDownloadLink({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      Object.keys(payload).forEach((key) => {
        data.append(key, payload[key])
      })

      axios.post('/get_sales_report_download_url/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchStockReports({ commit, state }, { payload, isInitial = false, page = '0' }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      if (payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }

      data.append('page', page)
      data.append('limit', '20')

      axios.post('/get_stock_report/', data)
        .then((response) => {
          if (response.data.message_type == 1) {
            commit('SET_STOCK', response.data.stock_report)
            commit('SET_SALES_RAW', response.data)
            resolve(response.data)
          } else {
            reject(response)
          }
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchProductFilters({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get('/get_product_filters/')
        .then((response) => {
          if (response.data.message_type == 1) {
            resolve(response.data)
          } else {
            reject(response)
          }
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchBatchSoldPins({ commit, state }, { payload }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      if (payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }
      axios.post('/get_sold_pins_by_batch/', data)
        .then((response) => {
          if (response.data.message_type == 1) {
            resolve(response.data)
          } else {
            reject(response)
          }
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchInventoryHistoryReports({ commit, state }, { payload, page = '0' }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      if (payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }

      data.append('page', page)
      data.append('limit', '20')

      axios.post('/get_inventory_history_report/', data)
        .then((response) => {
          if (response.data.message_type == 1) {
            commit('SET_INVENTORY', response.data.import_batches_report)
            commit('SET_SALES_RAW', response.data)
            resolve(response.data)
          } else {
            reject(response)
          }
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchInvoices({ commit, state }, { payload }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      if (payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }

      axios.post('/get_invoice_report/', data)
        .then((response) => {
          if (response.data.message_type == 1) {
            commit('SET_INVOICES', response.data)
            resolve(response.data)
          } else {
            reject(response)
          }
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchZReport({ commit, state }, { filter }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      if (filter) {
        Object.keys(filter).forEach((key) => {
          data.append(key, filter[key])
        })
      }

      axios.post('/z_report/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
}
