import state from './moduleReportsState'
import mutations from './moduleReportsMutations'
import actions from './moduleReportsActions'
import getters from './moduleReportsGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

