import axios from '@/axios'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    callingCardsPool: [],
  },
  mutations: {
    SET_CARDS_POOL(state, data) {
      state.callingCardsPool = data
    },
  },
  actions: {
    fetchCallingCardPools({state, commit}, {payload}) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/view_update_pool/', data)
          .then((response) => {
            commit('SET_CARDS_POOL', response.data.list_pools)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchSingleCallingCardPool({state, commit}, {payload}) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/view_update_pool/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addCallingCardPool({state, commit}, {payload}) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/add_pool/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // Add Inventory
    uploadCSVFile({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        data.append('csv_file', payload.inventoryCsv)
        data.append('delimiter', payload.delimiter)

        axios.post('/pool_upload_file/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => { reject(error) })
      })
    },
    uploadStepTwo({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        data.append('pool_id', payload.poolId)
        data.append('pin_sequence', payload.pinSeq)
        data.append('batch_sequence', payload.batchSeq)
        data.append('import_batch', payload.batchNo)

        axios.post('/pool_upload_file_step_two/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => { reject(error) })
      })
    },
    fetchSupplierDiscount({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        data.append('pool_id', payload.poolId)
        data.append('supplier_id', payload.supplierId)

        axios.post('/get_previous_supplier_pool_discount/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => { reject(error) })
      })
    },
    uploadStepThree({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        data.append('supplier_id', payload.supplierId)
        data.append('buying_discount', payload.discount)
        data.append('import_batch', payload.batchNo)

        axios.post('/pool_upload_file_step_three/', data)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => { reject(error) })
      })
    },
  },
}

