import state from './moduleDevicesState'
import mutations from './moduleDevicesMutations'
import actions from './moduleDevicesActions'
import getters from './moduleDevicesGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

