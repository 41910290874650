<template>
  <div
    class="router-header flex flex-wrap items-center mb-6 mx-6 vx-row no-print">
    <div
      class="content-area__heading breadcrumb-title"
      :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : breadcrumb}">
      <h2 class="mb-1">{{  $t(title) | title}}</h2>
    </div>
    <!-- BREADCRUMB -->
    <vx-breadcrumb-modified class="ml-4 md:block hidden" v-if="breadcrumb" :breadcrumb="breadcrumb"/>

    <slot name="left-end"></slot>
    <vs-spacer></vs-spacer>
    <slot name="right-end"></slot>
  </div>
</template>

<script>
export default {
  name: 'BreadcrumbBase',
  props: {
    title: {
      type: String,
    },
    breadcrumb: {
      type: Array,
    },
  },
}
</script>

<style scoped>

</style>
