import state from './moduleSuppliersState'
import mutations from './moduleSuppliersMutations'
import actions from './moduleSuppliersActions'
import getters from './moduleSuppliersGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

