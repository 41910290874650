<template>
  <div>
    <vs-col
      class="vs-pagination--mb"
      vs-type="flex"
      vs-justify="flex-end"
      vs-align="center" >
      <div
        :style="stylePagination"
        :class="[`vs-pagination-${color}`]"
        class="con-vs-pagination">
        <nav class="vs-pagination--nav">
          <ul class="vs-pagination--ul">
            <li
              v-for="(page, index) in pages"
              :key="index"
              :class="{'is-current': page == current}"
              class="item-pagination vs-pagination--li"
              @click="goTo(page)">
              <span>
                {{ page }}
              </span>

              <div class="effect"></div>
            </li>
          </ul>
        </nav>
      </div>
    </vs-col>
  </div>
</template>
<script>
import _color from '../../../assets/utils/color.js'

export default {
  name: 'VsPaginationModified',
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    total: {
      type: Number,
      required: true,
    },
    sizeArray: {
      type: Number,
      required: false,
    },
    maxItems: {
      type: [Number, String],
      required: false,
    },
    value: {
      type: [Number, String],
      required: true,
      default: 1,
    },
    max: {
      type: Number,
      default: 9,
    },
    goto: {
      type: Boolean,
    },
    type: {
      type: String,
    },
    prevIcon: {
      type: String,
    },
    nextIcon: {
      type: String,
    },
    iconPack: {
      type: String,
      default: 'material-icons',
    },
  },
  data: () => ({
    pages: [],
    current: -1,
    go: 0,
    prevRange: '',
    nextRange: '',
    hoverBtn1: false,
    minRows: 0,
    maxRows: 0,
    indexRows: 0,
    alpha: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  }),
  computed: {
    defaultNextIcon() {
      if (this.$vs.rtl) return 'chevron_left'
      return 'chevron_right'
    },
    defaultPrevIcon() {
      if (this.$vs.rtl) return 'chevron_right'
      return 'chevron_left'
    },
    stylePagination() {
      let style = {}
      if (!_color.isColor(this.color)) {
        style = {
          '--vs-color-pagination': _color.getColor(this.color),
          '--vs-color-pagination-alpha': _color.getColor(this.color, 0.5),
        }
      }
      return style
    },
    styleDescription() {
      return {
        'cursor': 'pointer',
      }
    },
  },
  watch: {
    current(val) {
      this.getPages()
      this.calculateMinMax(val)
      this.$emit('input', this.current)
      this.$emit('change', this.current)
    },
    total() {
      this.getPages()
    },
    max() {
      this.getPages()
    },
    value(val) {
      // val = this.alpha.indexOf(val);
      // const pageNum = val < 1 ? 1 : (val <= this.total ? val : this.total)
      this.goTo(val)
    },
  },
  async mounted() {
    this.current = this.go = this.value
    await this.calculateMinMax(this.current)
    this.getPages()
  },
  methods: {
    async changeRowMaxItems(index) {
      this.indexRows = index
      await this.$emit('changeMaxItems', index)
      await this.calculateMinMax(this.current)
      this.current = 1
    },
    async calculateMinMax(val) {
      this.maxRows = ((val * this.maxItems) <= this.sizeArray) ? val * this.maxItems : this.sizeArray
      this.minRows = ((val * this.maxItems) <= this.sizeArray) ? (this.maxRows - this.maxItems) + 1 : ((this.current - 1) * this.maxItems) + 1
    },
    isEllipsis(page) {
      return page === '...'
    },
    goTo(page) {
      if (page === '...') {
        return
      }
      if (typeof page.target === 'undefined') {
        this.current = page
      } else {
        // const value  = parseInt(, 10)
        const value = this.alpha.indexOf(page.target.value)
        this.go = (
          value < 1 ? 1 : (value <= this.total ? value : this.total)
        )
        this.current = this.go
      }
    },
    getPages() {
      if (this.total <= this.max) {
        const pages = this.setPages(1, this.total)
        this.pages = pages
      }
      // const even     = this.max % 2 === 0 ? 1 : 0
      // if(this.total < 6) {
      //   this.prevRange = Math.floor(this.max / (this.max/2))
      // } else {
      //   this.prevRange = Math.floor(this.max / 2)
      // }
      // this.nextRange = this.total - this.prevRange + 1 + even
      // if (this.current >= this.prevRange && this.current <= this.nextRange) {
      //   const start = this.current - this.prevRange + 2
      //   const end   = this.current + this.prevRange - 2 - even
      //   this.pages = [this.alpha[0], '...', ...this.setPages(start, end), '...', this.alpha[this.total-1]]
      // } else if (this.total < 6) {
      //   this.pages = [
      //     ...this.setPages(1, this.total)
      //   ]
      // } else {
      //   this.pages = [
      //     ...this.setPages(1, this.prevRange),
      //     '...',
      //     ...this.setPages(this.nextRange, this.total)
      //   ]
      // }
    },
    setPages(start, end) {
      const setPages = []
      for (start > 0 ? start : 1; start <= end; start++) {
        setPages.push(this.alpha[start - 1])
      }
      return setPages
    },
  },
}
</script>
<style scoped>
  .vs-pagination--ul {
    margin-top: 0.5rem;
    padding: 5px;
  }
  li.item-pagination.vs-pagination--li {
    width: 20px;
    height: 20px;
    padding: 10px;
    font-weight: 600;
  }
</style>
