import axios from '@/axios'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    products: {},
  },
  mutations: {
    SET_PRODUCTS(state, data) {
      state.products = data
    },
  },
  actions: {
    fetchProductDiscountsList({state, commit}, {payload}) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/view_assigned_products/', data)
          .then((response) => {
            commit('SET_PRODUCTS', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  }
}

