import state from './moduleDirectRechargeState'
import mutations from './moduleDirectRechargeMutations'
import actions from './moduleDirectRechargeActions'
import getters from './moduleDirectRechargeGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

