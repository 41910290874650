import axios from '@/axios.js'

export default {
  fetchDynamicProductConfigs({ commit, state }, { data, page = '0', search = '' }) {
    return new Promise((resolve, reject) => {
      const fData = new FormData()
      if (data) fData.append('product_type', data)
      fData.append('product_configuration_list', JSON.stringify([]))

      fData.append('page', page)
      fData.append('limit', '20')
      if (search != null && search != '') fData.append('search_text', search.toLowerCase())

      axios.post('/dynamic_products/', fData)
        .then((response) => {
          try {
            commit('SET_DYNAMIC_PRODUCTS', response.data.product_configurations)
            commit('SET_DYNAMIC_PRODUCTS_RAW', response.data)
          } catch (e) {

          }
          resolve(response.data.products)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateDynamicProductConfigs({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      const fData = new FormData()
      fData.append('product_configuration_list', JSON.stringify(data))

      axios.post('/dynamic_products/', fData)
        .then((response) => {
          try {
            commit('SET_DYNAMIC_PRODUCTS', response.data.product_configurations)
          } catch (e) {

          }
          resolve(response.data.products)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchSoftApiCards({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      axios.post('/get_softapi_products/')
        .then((response) => {
          commit('SET_SOFTAPI_CARDS', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
