import axios from '@/axios'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    callingCards: [],
    favCallingCards: [],
    dummyFavCallingCards: [],
  },
  mutations: {
    SET_CASH_CARDS(state, cashCards) {
      state.callingCards = cashCards
    },
    SET_FAV_CASH_CARDS(state, cashCards) {
      state.favCallingCards = cashCards
      state.dummyFavCallingCards = cashCards
    },
    ARRANGE_FAV_CASH_CARDS(state, {oldIndex, newIndex}) {
      Array.prototype.insert = function ( index, item ) {
        this.splice( index, 0, item );
      };
      let cards = state.favCallingCards;
      // const b = cards[oldIndex];
      const b = cards.splice(oldIndex, 1)[0];
      // cards[oldIndex] = cards[newIndex];
      // cards[newIndex] = b;
      cards.insert(newIndex, b)
      // if(oldIndex > newIndex) {
      //   cards =
      // } else {
      //   cards = cards.splice(oldIndex, 1);
      // }
      console.log(cards)
      state.favCallingCards = []
      state.favCallingCards = cards
    },
    RESET_FAV_CASH_CARDS(state) {
      state.favCallingCards = []
      state.favCallingCards = state.dummyFavCallingCards
    }
  },
  actions: {
    fetchFavCallingCards({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()

        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }
        data.append('product_type', 'calling card')
        data.append('favorite', '1')

        axios.post('/view_seller_products/', data)
          .then((response) => {
            try {
              commit('SET_FAV_CASH_CARDS', response.data.products)
            } catch (e) {

            }
            resolve(response.data.products)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchCallingCards({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()

        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }
        data.append('product_type', 'calling card')

        axios.post('/view_seller_products/', data)
          .then((response) => {
            try {
              localStorage.setItem('t_key', response.data.transaction_key);
              commit('SET_CASH_CARDS', response.data.products)
            } catch (e) {

            }
            resolve(response.data.products)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    purchaseCard({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()

        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })

        if (localStorage.getItem('t_key')) {
          let key = localStorage.getItem('t_key');
          data.append('transaction_key', key)
          localStorage.removeItem('t_key');
        }

        axios.post('/add_transaction_v3/', data)
          .then((response) => {
            dispatch('auth/fetchUserDetails', null, { root: true })
            resolve(response.data)
          })
          .catch((error) => {
            dispatch('auth/fetchUserDetails', null, { root: true })
            reject(error)
          })
      })
    },
  }
}

