import state from './modulePadState'
import mutations from './modulePadMutations'
import actions from './modulePadActions'
import getters from './modulePadGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

