export default {
  giftCards: [],
  giftCardDetails: [],
  giftCardDetailsRaw: null,
  giftCardsRaw: null,
  vPage: 0,
  itemCount: 20,
  totalCount: 0,
  vTotalPages: 0,
}
