import axios from '@/axios.js'

export default {
  triggerFlixBusEndpoint({ commit, state, dispatch }, { payload }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      if (payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }

      axios.post('/flixbus_api/', data)
        .then((response) => {
          resolve(response.data)
          try {
            if (payload.configuration == 13) {
              dispatch('auth/fetchUserDetails', null, { root: true })
            }
          } catch (e) {

          }
        })
        .catch((error) => { reject(error) })
    })
  },
  searchCities({ commit, state }, searchText) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      data.append('city', searchText)

      axios.post('/get_flixbus_cities/', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
}
