import axios from '@/axios.js'

export default {
  updatePageNumber({ commit }, page) {
    commit('UPDATE_PAGE', page)
  },
  fetchSingleProduct({ commit, state }, productId) {
    commit('SET_SINGLE_PRODUCT', null)
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('view_type', '1')
      data.append('product_id', productId.toString())
      data.append('page', '0')
      data.append('total_items', '10')
      axios.post('/view_products/', data)
        .then((response) => {
          try {
            commit('SET_SINGLE_PRODUCT', response.data.content[0])
          } catch (e) {

          }
          resolve(response.data.content[0])
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchProducts({ commit, state }, {
    page = '0', filter, search, totalItems = '20',
  }) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('view_type', '0')
      data.append('page', page)
      if (filter) {
        Object.keys(filter).forEach((key) => {
          data.append(key, filter[key])
        })
      }
      if (search) data.append('search_text', search.toLowerCase())
      data.append('total_items', totalItems.toString())
      axios.post('/view_products/', data)
        .then((response) => {
          // if(isMore) commit('UPDATE_PRODUCTS', response.data.content)
          // else
          commit('SET_PRODUCTS', response.data.content)
          commit('SET_PRODUCTS_RAW', response.data)
          commit('SET_PRODUCTS_FILTER', response.data.filters)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addNewProduct({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('product_name', payload.productName)
      data.append('product_type', payload.productType)
      data.append('pin_length', payload.pinLength)
      data.append('customer_care', payload.customerCareNumber)
      data.append('is_configuration', '0')
      data.append('instructions', payload.instructions)
      data.append('product_image', payload.productImage)

      axios.post('/add_product/', data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateProductImage({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('product_id', payload.productId)
      data.append('product_image', payload.productImage)

      axios.post('/update_product_image/', data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateProductData({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()

      if (payload) {
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })
      }
      axios.post('/update_product_details/', data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  addNewDenomination({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('is_configuration', '1')
      data.append('product_id', payload.productId)
      data.append('value', payload.denominationAmount)
      data.append('info', payload.description)

      axios.post('/add_product/', data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
