import axios from '@/axios'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    result: {},
  },
  mutations: {
    SET_RESULT(state, data) {
      state.result = data
    },
  },
  actions: {
    searchPinBatch({state, commit}, {payload}) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        if (payload) {
          Object.keys(payload).forEach((key) => {
            data.append(key, payload[key])
          })
        }

        axios.post('/inventory_batch_and_pin_search/', data)
          .then((response) => {
            commit('SET_RESULT', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  }
}

