export default {
  SET_PRODUCTS_DATA(state, products) {
    state.products = products
  },
  SET_PRODUCTS_ASSIGNED_DATA(state, products) {
    state.productsAssigned = products
  },
  SET_DISCOUNT_SLABS(state, slabs) {
    state.discountSlabs = slabs
  },
  UPDATE_INVENTORY(state, inventory) {
    state.inventory.push(...inventory)
  },
}
