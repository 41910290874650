export default {
  RESET(state) {
    state.sales = []
    state.salesCounters = {}
    state.typeOfFilters = {}
    state.productsFilter = {}
    state.customerFilter = {}
    state.filter = {}
    state.vPage = 0
    state.itemCount = 20
    state.totalCount = 0
    state.vTotalPages = 0
    state.salesRaw = null
    // Stock Report
    state.stock = []
    state.inventoryHistory = []
    state.invoices = null
  },
  SET_SALES(state, sales) {
    state.sales = sales
  },
  SET_SALES_COUNTERS(state, sales) {
    state.salesCounters = sales
  },
  SET_SALES_RECORD_DETAILS(state, sales) {
    state.salesRecordDetails = sales
  },
  UPDATE_SALES(state, sales) {
    state.sales.push(...sales)
  },
  SET_SALES_RAW(state, raw) {
    if (raw.filter) {
      state.vPage = Number(raw.filter.page)
      state.itemCount = Number(raw.filter.limit)
      state.totalCount = Number(raw.filter.total_count)
      state.vTotalPages = Math.ceil(Number(raw.filter.total_count) / state.itemCount)
    } else if (raw.filters) {
      state.vPage = Number(raw.filters.page)
      state.itemCount = Number(raw.filters.limit)
      state.totalCount = Number(raw.filters.total_count)
      state.vTotalPages = Math.ceil(Number(raw.filters.total_count) / state.itemCount)
    } else {
      state.vPage = 0
      state.itemCount = 50
      state.totalCount = 0
      state.vTotalPages = 1
    }
    state.salesRaw = raw
  },
  SET_TYPES_FILTER(state, filters) {
    delete filters.products
    delete filters.customers
    state.typeOfFilters = filters
  },
  UPDATE_PAGE(state, page) {
    state.vPage = page
  },
  SET_STOCK(state, stock) {
    state.stock = stock
  },
  UPDATE_STOCK(state, stock) {
    state.stock.push(...stock)
  },
  SET_INVENTORY(state, inventoryHistory) {
    state.inventoryHistory = inventoryHistory
  },
  UPDATE_INVENTORY(state, inventoryHistory) {
    state.inventoryHistory.push(...inventoryHistory)
  },
  SET_INVOICES(state, invoices) {
    state.invoices = invoices
  },
}
