import Vue from 'vue'

Vue.filter('capitalize', (value) => {
  if (!value) return ''
  value = value.toString()
  const arr = value.split(' ')
  const capitalized_array = []
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(' ')
})

Vue.filter('title', (value, replacer = '_') => {
  if (!value) return ''
  value = value.toString()

  const arr = value.split(replacer)
  const capitalized_array = []
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(' ')
})

Vue.filter('uppercase', (value) => value.toUpperCase())
Vue.filter('truncate', (value, limit) => {
  if (value) {
    if (value.length > limit) {
      return `${value.substring(0, limit)}..`
    }
    return value.substring(0, limit)
  }
  return ''
})

Vue.filter('prepareForIntl', (value) => {
  let v = value.replace(' ', '')
  v = v.replace('-', '')
  return v;
})

Vue.filter('tailing', (value, tail) => value + tail)

Vue.filter('time', (value, is24HrFormat = false) => {
  if (value) {
    const date = new Date(Date.parse(value))
    let hours = date.getHours()
    const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
    if (!is24HrFormat) {
      const time = hours > 12 ? 'AM' : 'PM'
      hours = hours % 12 || 12
      return `${hours}:${min} ${time}`
    }
    return `${hours}:${min}`
  }
})

Vue.filter('date', (value, fullDate = false) => {
  value = String(value)
  const date = value.slice(8, 10).trim()
  const month = value.slice(4, 7).trim()
  const year = value.slice(11, 15)

  if (!fullDate) return `${date} ${month}`
  return `${date} ${month} ${year}`
})

Vue.filter('month', (val, showYear = true) => {
  val = String(val)

  const regx = /\w+\s(\w+)\s\d+\s(\d+)./
  if (!showYear) {
    return regx.exec(val)[1]
  }
  return `${regx.exec(val)[1]} ${regx.exec(val)[2]}`
})

Vue.filter('dateFormat', (value) => {
  if (!value) {
    return 'NA'
  }
  try {
    const options1 = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'UTC'
    }
    const date = new Date(value)
    return date.toLocaleString('de', { timeZone: 'Europe/Berlin' })
    // const dateTimeFormat = new Intl.DateTimeFormat('de', options1)
    // return dateTimeFormat.format(date)
  } catch (e) {
    return 'NA'
  }
})
Vue.filter('dateTimeNoSecFormat', (value) => {
  if (!value) {
    return 'NA'
  }
  try {
    const options1 = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'UTC'
    }
    const date = new Date(value)
    const d = date.toLocaleString('de', { timeZone: 'Europe/Berlin' })
    const str2 = d.slice(0, -3) + '';
    return str2;
    // const dateTimeFormat = new Intl.DateTimeFormat('de', options1)
    // return dateTimeFormat.format(date)
  } catch (e) {
    return 'NA'
  }
})
Vue.filter('onlyDateFormat', (value) => {
  if (!value) {
    return 'NA'
  }
  try {
    const options1 = {
      year: 'numeric', month: '2-digit', day: '2-digit',
      timeZone: 'UTC'
    }
    const date = new Date(value)
    return date.toLocaleDateString('de')
    const dateTimeFormat = new Intl.DateTimeFormat('de', options1)
    return dateTimeFormat.format(date)
  } catch (e) {
    return 'NA'
  }
})

function toFixed(num, fixed) {
  const re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
}

Vue.filter('germanNumberFormat', (value) => {

  if (value == null || value == undefined || isNaN(value)) return '-'
  // const num = toFixed(value, 2);

  const num = value
  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    maximumFractionDigits: 2,
    currency: 'EUR',
  })
  return formatter.format(num)
})
Vue.filter('roundNumber', (value) => {
  return parseFloat(value.toString()).toFixed(2);
})
Vue.filter('germanNumberFormat4', (value) => {
  if (value == null || value == undefined || isNaN(value)) return '-'
  const num = toFixed(value, 2);
  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    currency: 'EUR',
  })
  return formatter.format(num)
})
Vue.filter('justNumberFormatGerman', (value) => {
  if (value == null || value == undefined || isNaN(value)) return '-'
  const num = toFixed(value, 2);
  const formatter = new Intl.NumberFormat('de-DE')
  return formatter.format(num)
})

Vue.filter('generalNumberFormat', (value, currency = '') => {
  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    maximumFractionDigits: 4,
    currency: currency,
  })
  return formatter.format(value)
})

Vue.filter('csv', (value) => value.join(', '))

Vue.filter('filter_tags', (value) => value.replace(/<\/?[^>]+(>|$)/g, ''))

Vue.filter('k_formatter', (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num))
